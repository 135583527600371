import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TextField } from "react95";

import { getApi } from "../helpers/getApi";
import { Account } from "../services/Api";
import { getToken, hasToken } from "../services/tokenStorage";

interface Npc {
  name: string;
  position: { x: number; y: number; z: number };
  triggerPhrases: string[];
  distance?: number;
}

interface Vector2d {
  x: number;
  y: number;
}

const towns = [
  {
    "name": "Thais",
    "x": 32369,
    "y": 32215
  },
  {
    "name": "Carlin",
    "x": 32341,
    "y": 31789
  },
  {
    "name": "Ab'Dendriel",
    "x": 32661,
    "y": 31687
  },
  {
    "name": "Rookgaard",
    "x": 32097,
    "y": 32207
  },
  {
    "name": "Fibula",
    "x": 32176,
    "y": 32437
  },
  {
    "name": "Kazordoon",
    "x": 32632,
    "y": 31916
  },
  {
    "name": "Senja",
    "x": 32125,
    "y": 31667
  },
  {
    "name": "Folda",
    "x": 32046,
    "y": 31582
  },
  {
    "name": "Vega",
    "x": 32027,
    "y": 31692
  },
  {
    "name": "Havoc",
    "x": 32783,
    "y": 32243
  },
  {
    "name": "Orc",
    "x": 32901,
    "y": 31771
  },
  {
    "name": "Minocity",
    "x": 32404,
    "y": 32124
  },
  {
    "name": "Minoroom",
    "x": 32139,
    "y": 32109
  },
  {
    "name": "Desert",
    "x": 32653,
    "y": 32117
  },
  {
    "name": "Swamp",
    "x": 32724,
    "y": 31976
  },
  {
    "name": "Home",
    "x": 32316,
    "y": 31942
  },
  {
    "name": "Mists",
    "x": 32854,
    "y": 32333
  },
  {
    "name": "FibulaDungeon",
    "x": 32189,
    "y": 32426
  },
  {
    "name": "DragonIsle",
    "x": 32781,
    "y": 31603
  },
  {
    "name": "HellsGate",
    "x": 32675,
    "y": 31648
  },
  {
    "name": "Necropolis",
    "x": 32786,
    "y": 31683
  },
  {
    "name": "Trollcaves",
    "x": 32493,
    "y": 32259
  },
  {
    "name": "Elvenbane",
    "x": 32590,
    "y": 31657
  },
  {
    "name": "Fieldofglory",
    "x": 32430,
    "y": 31671
  },
  {
    "name": "Hills",
    "x": 32553,
    "y": 31827
  },
  {
    "name": "Sternum",
    "x": 32463,
    "y": 32077
  },
  {
    "name": "Northport",
    "x": 32486,
    "y": 31610
  },
  {
    "name": "Greenshore",
    "x": 32273,
    "y": 32053
  },
  {
    "name": "Edron",
    "x": 33191,
    "y": 31818
  },
  {
    "name": "Stonehome",
    "x": 33319,
    "y": 31766
  },
  {
    "name": "Camp",
    "x": 32655,
    "y": 32208
  },
  {
    "name": "Cormaya",
    "x": 33302,
    "y": 31970
  },
  {
    "name": "Darashia",
    "x": 33224,
    "y": 32428
  },
  {
    "name": "Drefia",
    "x": 32996,
    "y": 32417
  },
  {
    "name": "Venore",
    "x": 32955,
    "y": 32076
  },
  {
    "name": "Ghostship",
    "x": 33325,
    "y": 32173
  },
  {
    "name": "VenoreDragons",
    "x": 32793,
    "y": 32155
  },
  {
    "name": "Shadowthorn",
    "x": 33086,
    "y": 32157
  },
  {
    "name": "Amazons",
    "x": 32839,
    "y": 31925
  },
  {
    "name": "KingsIsle",
    "x": 32174,
    "y": 31940
  },
  {
    "name": "Ghostlands",
    "x": 32223,
    "y": 31831
  },
  {
    "name": "Ankrahmun",
    "x": 33162,
    "y": 32802
  },
  {
    "name": "Oasis",
    "x": 33132,
    "y": 32661
  },
  {
    "name": "Marid",
    "x": 33103,
    "y": 32539
  },
  {
    "name": "Efreet",
    "x": 33053,
    "y": 32622
  },
  {
    "name": "PortHope",
    "x": 32623,
    "y": 32753
  },
  {
    "name": "Banuta",
    "x": 32812,
    "y": 32559
  },
  {
    "name": "Chor",
    "x": 32956,
    "y": 32843
  },
  {
    "name": "Trapwood",
    "x": 32709,
    "y": 32901
  },
  {
    "name": "Eremo",
    "x": 33323,
    "y": 31883
  }
];

const distance = (a: Vector2d, b: Vector2d): number => {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
};

const getClosestTown = (pos: Vector2d): string => {
  let closest = "none";
  let mindist = 99999.0;
  for(const town of towns){
    const dist = distance(town, pos);
    if(dist < mindist){
      mindist = dist;
      closest = town.name;
    }
  }
  return closest;
};

type TextFieldEvent = { target: { value: string } };
export default function NpcPage(): JSX.Element {
  const [npcs, setNpcs] = useState<Npc[]>([]);
  const [me, setMe] = useState<Account | null>(null);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [phraseFilter, setPhraseFilter] = useState<string>("");

  const getData = async (): Promise<void> => {
    const npcsResponse = await fetch("/npcs.json");
    const npcs = (await npcsResponse.json()) as Npc[];
    setNpcs(npcs);
    if (hasToken()) {
      const meData = await getApi().getMe(getToken());
      setMe(meData);
      const onlinePlayer = meData.players.find((a) => a.online);
      if (onlinePlayer) {
        for (const npc of npcs) {
          npc.distance = distance(npc.position, { x: onlinePlayer.posx, y: onlinePlayer.posy });
        }
        npcs.sort((a, b) => (a.distance as number) - (b.distance as number));
      }
    }
  };

  useEffect(() => {
    void getData();
  }, []);

  const onlineCharName = hasToken()
    ? (me ? me.players.find((a) => a.online)?.name : undefined) ?? "No character online found"
    : "Not logged in";

  const amountRegex = /[% ><1]{3,}/g;

  return (
    <>
      <h4>Sorted by distance to your online character {onlineCharName}</h4>
      <TextField placeholder="Filter by name" onChange={(e: TextFieldEvent) => setNameFilter(e.target.value)} />
      <TextField placeholder="Filter by phrases" onChange={(e: TextFieldEvent) => setPhraseFilter(e.target.value)} />
      {npcs
        .filter((npc) => {
          npc.triggerPhrases.sort();
          let pass = true;
          if (nameFilter) {
            pass &&= npc.name.toLowerCase().includes(nameFilter.toLowerCase());
          }
          if (phraseFilter) {
            pass &&= npc.triggerPhrases.join("|").toLowerCase().includes(phraseFilter.toLowerCase());
          }
          return pass;
        })
        .map((npc) => {
          return (
            <div key={npc.name}>
              <h1>{npc.name}</h1>
              <h3>near {getClosestTown(npc.position)}</h3>
              {
                npc.distance
                ? <h4>{npc.distance.toString() + " steps away"}</h4>
                : ""
              }
              <br />
              {[...new Set(npc.triggerPhrases)].map((a: string) => (
                <>
                  {a.replace(amountRegex, " ? ")} <br />
                </>
              ))}
              <hr />
            </div>
          );
        })}
    </>
  );
}
