import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, Divider, List, ListItem, TextField } from "react95";
import { useNavigate } from "react-router-dom";

import { getApi } from "../helpers/getApi";
import { saveToken } from "../services/tokenStorage";

type TextFieldEvent = { target: { value: string } };
export default function LoginPage(props: { setLoggedIn: (v: boolean) => void }): JSX.Element {
  const [login, setLogin] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  const submitForm = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    try {
      const token = await getApi().getToken(login, password);
      setErrors([]);
      saveToken(token);
      props.setLoggedIn(true);
      navigate("/");
    } catch (formErrors) {
      setErrors(formErrors as string[]);
    }
  };

  const renderErrors = (): JSX.Element[] => {
    return errors.map((a, i) => <div key={i}>{a}</div>);
  };

  return (
    <div>
      <form>
        <List inline shadow={false} fullWidth={true} style={{ margin: 0, boxShadow: 0 }}>
          <ListItem>
            <TextField placeholder="Your login" onChange={(e: TextFieldEvent) => setLogin(parseInt(e.target.value))} />
            <TextField
              placeholder="Your password"
              type="password"
              onChange={(e: TextFieldEvent) => setPassword(e.target.value)}
            />
          </ListItem>
          <Divider orientation="vertical" size="43px" />
          <ListItem>
            <Button onClick={submitForm}>Sign in</Button>
          </ListItem>
        </List>
      </form>
      <div>{renderErrors()}</div>
    </div>
  );
}
