import React, { useState } from "react";
import {
  Button,
  Fieldset,
  Radio,
  TextField, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "react95";
import { useNavigate } from "react-router-dom";

import { getApi } from "../helpers/getApi";
import { suggestName } from "../services/suggestName";
import { getToken } from "../services/tokenStorage";

type TextFieldEvent = { target: { value: string } };
export default function NewCharacterPage(): JSX.Element {
  const [name, setName] = useState<string>("");
  const [sex, setSex] = useState<number>(0);
  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  const submitForm = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    const credentials = { name, sex };
    try {
      const player = await getApi().createPlayer(getToken(), credentials);
      console.log(player);
      setErrors([]);
      navigate("/page/account");
    } catch (formErrors) {
      setErrors(formErrors as string[]);
    }
  };

  const renderErrors = (): JSX.Element[] => {
    return errors.map((a, i) => <div key={i}>{a}</div>);
  };

  return (
    <div>
      <TextField placeholder="Name" value={name} onChange={(e: TextFieldEvent) => setName(e.target.value)} />
      <br />
      <Button onClick={() => setName(suggestName())}>Suggest name</Button>
      <br />
      <br />
      <Fieldset label="Sex">
        <Radio checked={sex === 0} onChange={() => setSex(0)} value={0} label="Female" name="sex" />
        <br />
        <Radio checked={sex === 1} onChange={() => setSex(1)} value={1} label="Male" name="sex" />
      </Fieldset>
      <br />
      <Button onClick={submitForm}>Create</Button>
      <div>{renderErrors()}</div>
    </div>
  );
}
