import { useEffect, useState } from "react";
import {
  Button,
  Toolbar,
  Window,
  WindowContent,
  WindowHeader, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "react95";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
// pick a theme of your choice
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import original from "react95/dist/themes/original";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import "./App.css";
import AccountPage from "./components/AccountPage";
import DownloadPage from "./components/DownloadPage";
import LoginPage from "./components/LoginPage";
import ManualPage from "./components/ManualPage";
import MapPage from "./components/MapPage";
import NewCharacterPage from "./components/NewCharacterPage";
import NpcPage from "./components/NpcPage";
import RegisterPage from "./components/RegisterPage";
import { hasToken, removeToken } from "./services/tokenStorage";

// eslint-disable-next-line @typescript-eslint/naming-convention
const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'Arial';
    font-smooth: never;
  }
`;
export default function App(): JSX.Element {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState<boolean>(hasToken());
  useEffect(() => {
    setLoggedIn(hasToken());
  }, []);

  return (
    <div className="page">
      <GlobalStyles />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <ThemeProvider theme={original}>
        <Window className="window" shadow={false}>
          <WindowHeader className="window-header">
            <span>Account manager.exe</span>
          </WindowHeader>
          <Toolbar style={{ width: "100%", display: "block" }}>
            <Link to="/">
              <Button variant="menu" size="sm">
                Map
              </Button>
            </Link>
            <Link to="/page/manual">
              <Button variant="menu" size="sm">
                Manual
              </Button>
            </Link>
            <Link to="/page/npcs">
              <Button variant="menu" size="sm">
                NPCs
              </Button>
            </Link>
            <Link to="/page/download">
              <Button variant="menu" size="sm">
                Download
              </Button>
            </Link>
            {!loggedIn && (
              <Link to="/page/login">
                <Button variant="menu" size="sm">
                  Login
                </Button>
              </Link>
            )}
            {!loggedIn && (
              <Link to="/page/register">
                <Button variant="menu" size="sm">
                  Register
                </Button>
              </Link>
            )}
            {loggedIn && (
              <Link to="/page/account">
                <Button variant="menu" size="sm">
                  Account
                </Button>
              </Link>
            )}
            {loggedIn && (
              <Button
                variant="menu"
                size="sm"
                onClick={() => {
                  removeToken();
                  setLoggedIn(false);
                  navigate("/");
                }}
              >
                Log out
              </Button>
            )}
          </Toolbar>
          <WindowContent class="windowContent">
            {/*<img src="/worst-logo.png" className="logo" style={{width:"100%"}}></img>*/}
            <Routes>
              <Route path="/" element={<MapPage />} />
              <Route path="page/register" element={<RegisterPage setLoggedIn={setLoggedIn} />} />
              <Route path="page/create-character" element={<NewCharacterPage />} />
              <Route path="page/account" element={<AccountPage />} />
              <Route path="page/login" element={<LoginPage setLoggedIn={setLoggedIn} />} />
              <Route path="page/manual" element={<ManualPage />} />
              <Route path="page/npcs" element={<NpcPage />} />
              <Route path="page/download" element={<DownloadPage />} />
            </Routes>
          </WindowContent>
        </Window>
      </ThemeProvider>
    </div>
  );
}
