export const saveToken = (token: string): void => {
  localStorage.setItem("AuthToken", token);
};

export const getToken = (): string => {
  return localStorage.getItem("AuthToken") ?? "";
};

export const removeToken = (): void => {
  localStorage.removeItem("AuthToken");
};

export const hasToken = (): boolean => !!localStorage.getItem("AuthToken");
