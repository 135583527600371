import { Api } from "../services/Api";
import { getConfig } from "./getConfig";

let api: Api | null = null;

export const getApi = (): Api => api as unknown as Api;

export const setUpApi = (): void => {
  api = new Api(getConfig().apiUrl);
};
