// import React, { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { Button } from "react95";
// import { getApi } from "../helpers/getApi";
import { getConfig } from "../helpers/getConfig";

// import { Api, PlayerPosition } from "../services/Api";

export default function MapPage(): JSX.Element {
  // const [positions, setPositions] = useState<PlayerPosition[]>([]);
  // const [currentLevel, setCurrentLevel] = useState<number>(7);
  //
  // const updatePositions = async (): Promise<void> => {
  //   setPositions(await getApi().getAllPlayers());
  // };
  //
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     void updatePositions();
  //   }, 5000);
  //   void updatePositions();
  //   return () => clearInterval(interval);
  // }, []);
  //
  // // x - 31872 to 33407
  // // y - 31488 to 33023
  // const renderPositions = (): JSX.Element[] => {
  //   console.log(positions);
  //   return positions
  //     .filter((a) => a.z === currentLevel)
  //     .map((a) => {
  //       const x = a.x - 31872;
  //       const y = a.y - 31488;
  //       // let zText = `this floor (${a.z})`;
  //       // if(a.z > currentLevel) {
  //       //     zText = `below ${a.z - currentLevel} floors (${a.z})`;
  //       // }
  //       // if(a.z < currentLevel) {
  //       //     zText = `above ${currentLevel - a.z} floors (${a.z})`;
  //       // }
  //       return (
  //         <div
  //           key={a.name}
  //           className="playerOnMap"
  //           style={{
  //             top: ((y * 100.0) / 1535).toString() + "%",
  //             left: ((x * 100.0) / 1535).toString() + "%",
  //           }}
  //         >{`${a.name}`}</div>
  //       );
  //     });
  // };

  return (
    <>
      {/*<Button variant="dark" onClick={() => setCurrentLevel(Math.min(15, currentLevel + 1))}>*/}
      {/*  Go down*/}
      {/*</Button>*/}
      {/*<Button variant="dark" onClick={() => setCurrentLevel(Math.max(0, currentLevel - 1))}>*/}
      {/*  Go up*/}
      {/*</Button>*/}
      {/*<br />*/}
      {/*<br />*/}
      <div className="mapCanvas">
        {/*<img className="mapCanvasBg" src={`minimap/map_${currentLevel}.png`} alt="" />*/}
        {/*{renderPositions()}*/}
        <div className="resp-container">
          <iframe id="resp-iframe" width="100%" height="600" src={getConfig().bigMapUrl} />
        </div>
      </div>
    </>
  );
}
