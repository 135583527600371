import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, Divider, List, ListItem, TextField } from "react95";
import { useNavigate } from "react-router-dom";

import { getApi } from "../helpers/getApi";
import { saveToken } from "../services/tokenStorage";

type TextFieldEvent = { target: { value: string } };
export default function RegisterPage(props: { setLoggedIn: (v: boolean) => void }): JSX.Element {
  const [login, setLogin] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");

  const navigate = useNavigate();

  const submitForm = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    const dto = { login, password };
    try {
      const res = await getApi().register(dto);
      console.log(res);
      setErrors([]);
      setMessage("Done! Logging in");
      const token = await getApi().getToken(login, password);
      setErrors([]);
      saveToken(token);
      props.setLoggedIn(true);
      navigate("/");
    } catch (formErrors) {
      setErrors(formErrors as string[]);
    }
  };

  const renderErrors = (): JSX.Element[] => {
    return errors.map((a, i) => <div key={i}>{a}</div>);
  };

  return (
    <div>
      <List inline shadow={false} fullWidth={true} style={{ margin: 0, boxShadow: 0 }}>
        <ListItem>
          <TextField placeholder="New login" onChange={(e: TextFieldEvent) => setLogin(parseInt(e.target.value))} />
          <TextField placeholder="New password" onChange={(e: TextFieldEvent) => setPassword(e.target.value)} />
        </ListItem>
        <Divider orientation="vertical" size="43px" />
        <ListItem>
          <Button onClick={submitForm}>Register</Button>
        </ListItem>
      </List>
      <div>{renderErrors()}</div>
      <div>{message}</div>
    </div>
  );
}
