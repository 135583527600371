export interface PlayerPosition {
  name: string;
  x: number;
  y: number;
  z: number;
  online: boolean;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface Player {
  id: number;
  online: boolean;
  name: string;
  group_id: number;
  account_id: number;
  level: number;
  vocation: number;
  health: number;
  healthmax: number;
  experience: string;
  lookbody: number;
  lookfeet: number;
  lookhead: number;
  looklegs: number;
  looktype: number;
  maglevel: number;
  mana: number;
  manamax: number;
  manaspent: number;
  soul: number;
  town_id: number;
  posx: number;
  posy: number;
  posz: number;
  conditions: string;
  cap: number;
  sex: number;
  lastlogin: string;
  lastip: number;
  save: boolean;
  skull: boolean;
  skulltime: number;
  lastlogout: string;
  blessings: number;
  onlinetime: number;
  deletion: string;
  balance: string;
  skill_fist: number;
  skill_fist_tries: string;
  skill_club: number;
  skill_club_tries: string;
  skill_sword: number;
  skill_sword_tries: string;
  skill_axe: number;
  skill_axe_tries: string;
  skill_dist: number;
  skill_dist_tries: string;
  skill_shielding: number;
  skill_shielding_tries: string;
  skill_fishing: number;
  skill_fishing_tries: string;
  deleted: boolean;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface Account {
  id: number;
  type: number;
  players: Player[];
}

export interface Credentials {
  login: number;
  password: string;
}

export interface CreateAccountDto {
  login: number;
  password: string;
}

export interface CreatePlayerDto {
  name: string;
  sex: number;
}

export class Api {
  public constructor(private readonly apiBaseUrl: string) {}

  public async getAllPlayers(): Promise<PlayerPosition[]> {
    const res = await fetch(this.apiBaseUrl + "players-positions");
    return (await res.json()) as PlayerPosition[];
  }

  public async getMe(token: string): Promise<Account> {
    const res = await fetch(this.apiBaseUrl + "me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (res.ok) {
      return (await res.json()) as Account;
    } else {
      throw await res.json();
    }
  }

  public async getToken(account: number, password: string): Promise<string> {
    const res = await fetch(this.apiBaseUrl + "token", {
      method: "POST",
      body: JSON.stringify({ account, password }),
      headers: {
        "Content-type": "application/json",
      },
    });
    if (res.ok) {
      return ((await res.json()) as { token: string }).token;
    } else {
      throw await res.json();
    }
  }

  public async register(dto: CreateAccountDto): Promise<Account> {
    const res = await fetch(this.apiBaseUrl + "register", {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-type": "application/json",
      },
    });
    if (res.ok) {
      return (await res.json()) as Account;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      throw (await res.json()).errors.map(
        (a: { constraints: Record<string, string> }) => `${Object.values(a.constraints).join("")}`
      );
    }
  }

  public async createPlayer(token: string, dto: CreatePlayerDto): Promise<Player> {
    const res = await fetch(this.apiBaseUrl + "create-player", {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (res.ok) {
      return (await res.json()) as Player;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      throw (await res.json()).errors.map(
        (a: { constraints: Record<string, string> }) => `${Object.values(a.constraints).join("")}`
      );
    }
  }
}
