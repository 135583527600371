const syllabes = [
  "ing",
  "ment",
  "ger",
  "light",
  "age",
  "er",
  "or",
  "low",
  "ob",
  "ba",
  "a",
  "tions",
  "ni",
  "of",
  "but",
  "ly",
  "ble",
  "par",
  "pos",
  "cit",
  "ed",
  "der",
  "son",
  "tain",
  "cle",
  "i",
  "ma",
  "tle",
  "den",
  "co",
  "es",
  "na",
  "day",
  "ings",
  "cov",
  "re",
  "si",
  "ny",
  "mag",
  "da",
  "tion",
  "un",
  "pen",
  "ments",
  "dif",
  "in",
  "at",
  "pre",
  "set",
  "ence",
  "e",
  "dis",
  "tive",
  "some",
  "ern",
  "con",
  "ca",
  "car",
  "sub",
  "eve",
  "y",
  "cal",
  "ci",
  "sur",
  "hap",
  "ter",
  "man",
  "mo",
  "ters",
  "ies",
  "ex",
  "ap",
  "on",
  "tu",
  "ket",
  "al",
  "po",
  "ous",
  "af",
  "lec",
  "de",
  "sion",
  "pi",
  "au",
  "main",
  "com",
  "vi",
  "se",
  "cy",
  "mar",
  "o",
  "el",
  "ten",
  "fa",
  "mis",
  "di",
  "est",
  "tor",
  "im",
  "my",
  "en",
  "la",
  "ver",
  "li",
  "nal",
  "an",
  "lar",
  "ber",
  "lo",
  "ness",
  "ty",
  "pa",
  "can",
  "men",
  "ning",
  "ry",
  "ture",
  "dy",
  "min",
  "n’t",
  "u",
  "for",
  "et",
  "mon",
  "nu",
  "ti",
  "is",
  "it",
  "op",
  "oc",
  "ri",
  "mer",
  "mu",
  "out",
  "pres",
  "be",
  "pe",
  "no",
  "rec",
  "sup",
  "per",
  "ra",
  "ple",
  "ro",
  "te",
  "to",
  "so",
  "cu",
  "sen",
  "ted",
  "pro",
  "ta",
  "fac",
  "side",
  "tem",
  "ac",
  "as",
  "fer",
  "tal",
  "tin",
  "ad",
  "col",
  "gen",
  "tic",
  "tri",
  "ar",
  "fi",
  "ic",
  "ties",
  "tro",
  "ers",
  "ful",
  "land",
  "ward",
  "up",
  "va",
  "cir",
  "tra",
  "lead",
  "tract",
  "ven",
  "cor",
  "tures",
  "lect",
  "tray",
  "vis",
  "coun",
  "val",
  "lent",
  "us",
  "am",
  "cus",
  "var",
  "less",
  "vel",
  "bor",
  "dan",
  "vid",
  "lin",
  "west",
  "by",
  "dle",
  "wil",
  "mal",
  "where",
  "cat",
  "ef",
  "win",
  "mi",
  "writ",
  "cent",
  "end",
  "won",
  "mil",
  "ev",
  "ent",
  "work",
  "moth",
  "gan",
  "ered",
  "act",
  "near",
  "gle",
  "fin",
  "ag",
  "nel",
  "head",
  "form",
  "air",
  "net",
  "high",
  "go",
  "als",
  "new",
  "il",
  "har",
  "bat",
  "one",
  "lu",
  "ish",
  "bi",
  "point",
  "me",
  "lands",
  "cate",
  "prac",
  "nore",
  "let",
  "cen",
  "ral",
  "part",
  "long",
  "char",
  "rect",
  "por",
  "mat",
  "come",
  "ried",
  "read",
  "meas",
  "cul",
  "round",
  "rep",
  "mem",
  "ders",
  "row",
  "su",
  "mul",
  "east",
  "sa",
  "tend",
  "ner",
  "fect",
  "sand",
  "ther",
  "play",
  "fish",
  "self",
  "ton",
  "ples",
  "fix",
  "sent",
  "try",
  "ply",
  "gi",
  "ship",
  "um",
  "port",
  "grand",
  "sim",
  "uer",
  "press",
  "great",
  "sions",
  "way",
  "sat",
  "heav",
  "sis",
  "ate",
  "sec",
  "ho",
  "sons",
  "bet",
  "ser",
  "hunt",
  "stand",
  "bles",
  "south",
  "ion",
  "sug",
  "bod",
  "sun",
  "its",
  "tel",
  "cap",
  "the",
  "jo",
  "tom",
  "cial",
  "ting",
  "lat",
  "tors",
];
const getRandomSyllabe = (): string => syllabes[Math.floor(Math.random() * syllabes.length)];

const joiners = ["of", "the", "from", "von", "his", "her", "el"];
const getRandomJoiner = (): string => joiners[Math.floor(Math.random() * joiners.length)];

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const suggestNameInternal = (): string => {
  const withJoiner = Math.random() > 0.5;
  const firstNameSyllabesCount = Math.floor(Math.random() * 3) + 1;
  const lastNameSyllabesCount = Math.floor(Math.random() * 6) + 2;
  let firstName = "";
  let lastName = "";
  for (let i = 0; i < firstNameSyllabesCount; i++) {
    firstName += getRandomSyllabe();
  }
  for (let i = 0; i < lastNameSyllabesCount; i++) {
    lastName += getRandomSyllabe();
  }

  if (withJoiner) {
    return capitalizeFirstLetter(firstName) + " " + getRandomJoiner() + " " + capitalizeFirstLetter(lastName);
  } else {
    return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
  }
};

export const suggestName = (): string => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const name = suggestNameInternal();
    if (name.length <= 29) {
      return name;
    }
  }
};
