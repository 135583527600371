/* eslint-disable react/no-unescaped-entities */

export default function ManualPage(): JSX.Element {
  return (
    <div className="manualContainer">
      <h3>1 Introduction</h3>
      <img src="/manual/introduction.gif" width="300" height="200" />
      <h3>1.1 What is Tibia?</h3>
      Tibia is a fantasy massive multiplayer online role playing game (MMORPG). Take the role of a valorous adventurer
      and team up with people from all over the world! Find hidden treasures and explore a huge realm of magic and
      mystery! Wield legendary weapons and powerful spells to fight the fearsome monsters that roam the land! Whatever
      you do, it will make a difference because the world of Tibia is like a never-ending epic tale to which each and
      every single player contributes. Join Tibia today and do your part to build a legend. With a bit of cunning and
      luck you can make your character a true hero of the land, a hero whose fame will spread and live forever in the
      magic realm of Tibia.
      <br />
      <br />
      <br />
      <h3>1.2 Why Play Tibia?</h3>
      <ul>
        <li>
          <b>Tibia is Free!</b>
          <br />
          Unlike most other online games, Tibia is absolutely free! No hidden costs, no limited trial phase - once you
          have signed up you can go on playing forever if you like, and all you will ever need to pay for is your
          internet provider. Of course, players can choose to upgrade the game by buying Premium Accounts which will
          give them access to a number of premium features that make Tibia even more fun. However, buying Premium
          Accounts is strictly voluntary, and you will find that free account players are always just as welcome to
          Tibia as premium players.
        </li>
        <li>
          <b>Fast and Easy Access!</b>
          <br />
          Getting Tibia is easy! All you need is a computer, a connection to the internet and an email address! With a
          size of no more than 6 MB, downloading the client program you need to connect to the game servers of Tibia
          takes a matter of minutes, even if your connection is slow. What's more, the system requirements are low, so
          you won't need the latest equipment to play. All in all it will take you no more than five minutes to get into
          the game. Give it a try today! You will be surprised how quickly you will find your own character exploring
          the exciting world of Tibia!
        </li>
        <li>
          <b>Huge Game World</b>
          <br />
          The land of Tibia is a huge realm filled with adventure and unknown dangers. Scores of dungeons and mysterious
          places are waiting to be explored, and legions of deadly creatures are lurking in the wilderness. You will
          meet with many challenges and take up tricky quests, but your efforts will be rewarded well, for there are
          hundreds of unique items you can find along the way. And there is more to come! The world of Tibia is
          continually expanding, so don't be surprised to run into something completely new just when you thought you
          had seen it all!
        </li>
        <li>
          <b>Character Customisation</b>
          <br />
          Make your heroes true individuals! Characters can be customised in many different ways: Choose one of four
          exciting character vocations, each with its individual strengths and weaknesses. Select one of several
          different skins to individualise your characters' looks. Make your characters learn dozens of unique spells,
          equip them with the finest weapons and armour, train their skills ... the possibilities to develop and to
          improve characters are endless! Who will you be?
        </li>
        <li>
          <b>Massive International Community</b>
          <br />
          No MMORPG can do without a good community. With more than 100000 active players, Tibia is one of the largest
          games of its kind! No matter when you are online, you will always find yourself amidst hundreds of characters
          that are played by real people such as yourself, characters with whom you can trade, team up or even fight.
          Our groundbreaking chat feature will allow you to talk comfortably to many different people at a time. Make
          friends with people from all over the world with Tibia!
        </li>
      </ul>
      And these features are but a few. In fact, Tibia has much more to offer! For further details please see the
      feature page.
      <br />
      <br />
      <br />
      <h3>1.3 The Aim of the Game</h3>
      Many single-player games have a final, overriding aim. Once you have reached that aim all you can do is start
      again or play another game. However, Tibia is different. There are many aspects that will appeal to different
      kinds of people, and you can choose to play Tibia just the way you like. You enjoy meeting people? There are
      thousands of players online at any time, and you can make new friends every day. You prefer adventure and
      exploration? In the world of Tibia there are countless places and items to discover. Or is it building up your
      character that really keeps you going? From collecting equipment to training skills, Tibia characters can be
      improved in a multitude of different ways. But whatever your personal preferences are, you will find there is one
      general characteristic to Tibia: It is simply impossible to take it to a limit. You will find there are always new
      people to meet, new places to explore and new heights to which you can take your character! No matter how long you
      have played you will always find the fascination remains!
      <br />
      <br />
      <br />
      <h3>1.4 What Do You Need to Play Tibia?</h3>
      In order to run Tibia you need three things: A computer, a working internet connection and an email address to
      register your account to. The system requirements to run Tibia are low. The client program you need to have
      installed on your computer to connect to the game server runs on all Windows 95/98/ME/XP/2000 systems that have
      DirectX version 5.0 or higher installed. The required hard disk space is also low - a mere 15 MB will be
      sufficient. However, a CPU of 500 MHz or better as well as a 3D accelerated video graphics adapter (e.g. cards
      with ATI Radeon or NVIDIA GeForce chipsets) are recommended to ensure optimal performance. As to the required
      connection speed, any internet connection of 28.8k or faster will work.
      <br />
      Note that it is also possible to play Tibia on a Linux system. To do so your host is required to have Tcl/Tk 8.3
      installed.
      <br />
      <br />
      <br />
      <h3>1.5 Two Things to Keep in Mind When Playing Tibia</h3>
      Since this may be your first experience with a massive multiplayer online role playing game, we feel it is
      necessary to direct your attention to some topics every player should be aware of.
      <ul>
        <li>
          <b>Rules</b>
          <br />
          Where there are people there must also be rules. With thousands of players online at any point of time,
          MMORPGs such as Tibia need special codes of conduct that determine what is allowed and what isn't. In Tibia
          these rules are fittingly called the Tibia Rules. CipSoft expects all players to know and to follow the rules
          at all times. Players who violate our rules may be punished in a number of ways.
        </li>
        <li>
          <b>Security</b>
          <br />
          Improving characters and collecting rare items are important aspects of the game. Unfortunately, successful
          playing will not only attract admiration but also envy, and some people may actually try to steal from you.
          Always keep in mind that your character represents a real value, so keep a wary eye on it. Thankfully, you can
          easily protect your account from hacking if you observe some simple guidelines. Make sure to read our security
          hints carefully!
        </li>
      </ul>
      <br />
      <br />
      <h3>2 Setting Up the Game</h3>
      <h3>2.1 Creating an Account</h3>
      If you are new to Tibia, you must create an account before you can start playing. A Tibia account is like a
      personal register that will help you organise your activities in Tibia. Relevant information such as payments for
      Premium Accounts, punishments for rule violations, or your list of characters will be recorded in your account.
      You can check the status of your account anytime by having a look at your account page.
      <br />
      Since accounts represent players in the game each account must be registered to an individual email address. It is
      possible for players to have more than one account, but CipSoft asks all players to hold no more than one account
      at a time.
      <br />
      For further information please see the manual section on accounts.
      <br />
      <br />
      <b>2.1.1 Signing Up</b>
      <br />
      To create your Tibia account please sign up. On doing so you will be asked to do three things:
      <ol>
        <li>
          <b>Acknowledge the Tibia Licence Agreement</b>
          <br />
          First, our licence agreement will be displayed. The licence agreement is a list of conditions which determine
          the game service supplied by CipSoft. Please read it carefully, and if you consent to the licence agreement
          please click on "I agree" to continue.
        </li>
        <br />
        <li>
          <b>Accept the Tibia Rules</b>
          <br />
          Secondly, you will be shown the Tibia Rules, a set of special rules that are designed to regulate the
          behaviour of players in the game. You should read them very carefully because all our players are expected to
          know the Tibia Rules well and to follow them at all times. If you agree to observe the Tibia Rules, please
          click on "Accept" to continue.
        </li>
        <br />
        <li>
          <b>Submit Your Email Address</b>
          <br />
          Finally, you will be asked to enter a valid email address to which you want your new account to be registered.
          You can only register one account to one email address at a time, so make sure the address is not registered
          to an account already.
          <br />
          <b>Note:</b> The email address is the key to your account. If you should ever lose access to your account, you
          can always get it back as long as you have access to the registered email address. If have lost access to the
          email address, and if your account is not personalised, you will not be able to get your account back. For
          this reason you should pay attention to your email account and use a secure email password that is different
          from any other password you are using elsewhere. If you have a free email address, make sure to use it now and
          then because free email addresses are often removed by the mail providers if they are not accessed regularly.
          For further information on how to protect your account please see our security hints.
        </li>
        <br />
      </ol>
      <b>2.1.2 Account Number and Password</b>
      <br />
      Once you have submitted your email address your account will be created. You will now receive your account number
      and password. The account number is displayed on the following page. Take a piece of paper and write it down
      carefully. You should keep your account number in a safe place to make sure you do not lose it.
      <br />
      Your password, on the other hand, will not be displayed immediately. Instead, it will be sent to the registered
      email address, so you must look it up there. Unlike account numbers, passwords can be changed later on by clicking
      on the corresponding button on the account page. However, if you change it, you should make sure your new password
      is secure. For hints on how to create secure passwords please see our security hints. For further information
      please see the manual section on accounts.
      <br />
      <br />
      <br />
      <h3>2.2 Downloading and Installing the Client</h3>
      Once you have created your account you should download the client. The client is the program you must have
      installed on your computer in order to play Tibia. It is relatively small, since most of the actual game is
      handled by central computers usually referred to as "game servers".
      <br />
      <br />
      <b>2.2.1 Download</b>
      <br />
      On the download page you can select the client of your choice. Tibia is currently available both for Linux and for
      Microsoft Windows. However, the large majority of Tibia players are using Windows systems, so the instructions
      given in this manual focus on the controls of the Windows client. To continue the installation process click on
      the operating system under which your computer is running. A disclaimer will be displayed. Read it carefully and
      if you agree to it, click on the link to start the download. You will be asked to choose a directory into which
      you would like to download the installation file. Select a directory and confirm. Please note that the downloaded
      file requires approximately 6 MB of free disk space on your hard disk.
      <br />
      <br />
      <b>2.2.2 Installation</b>
      <br />
      Once the installation file has been downloaded Tibia is ready to be installed on your computer. Installing Tibia
      is easy. Simply start the installation file. The Tibia setup wizard will lead you through the installation
      process. Once more you will be asked to read and to confirm our licence agreement. Also, you will be asked which
      directory you would like to install the Tibia folder to, what name you would like the Tibia folder to have, and
      whether or not you would like to have Tibia icons on the desktop and/or quickstart bar. Once you have confirmed
      your selections the installation will be carried out. The complete folder will initially take about 15 MB of disk
      space. Note, however, that the size of the client will expand once you have started exploring the world of Tibia.
      Once the full map has been explored the client folder will take more than 100 MB of disk space.
      <br />
      <br />
      <br />
      <h3>2.3 Creating a Character</h3>
      Before you can embark on your adventure you need a hero of your own! Therefore, if you are new to Tibia, you must
      create a character before you can enter the game. To do so log into your account page and click on the "Create
      Character" button. To create characters you must give them names and select their genders as well as the game
      worlds they are playing on. Note that each account can contain up to 20 active characters. All of these characters
      can exist on one game world, but it is also possible to create characters on different game worlds.
      <br />
      <br />
      <b>2.3.1 Choosing a Name</b>
      <br />
      First off your character needs a name. You have great freedom in choosing your favourite name, but there are some
      limiting factors. For instance, your character's name cannot consist of more than 29 letters, and it must not
      contain numbers, special characters, certain words or combinations of letters.
      <br />
      Above all, however, the name must not violate the Tibia Rules. We recommend you select a name that fits well into
      the magical world of Tibia, because this way you can help us to create a nice fantasy atmosphere for all players.
      Keep in mind that you will not be able to rename your character once you have made your choice, so you should
      really pick a name you like.
      <br />
      If you find your imagination lets you down, or if none of your proposals are accepted, our automatic name
      generator may be able to help you to find a decent name. All you need to do is to click on the button "Suggest
      Name" in the bottom right hand corner. A list of 20 name suggestions will be displayed. Select one or, if you
      should like neither of the suggested names, click on the "Suggest Name" button again to create another list. Once
      you have selected a name click on "Submit". The selected name will be entered in the name field automatically.
      <br />
      <br />
      <b>2.3.2 Choosing Your Character's Sex</b>
      <br />
      It is up to you to decide which sex your character should have. Of course, this will determine your character's
      outfit. Also, your character's gender will have an influence on certain situations in the game, but in general
      both sexes are equal in Tibia.
      <br />
      It is possible to change your character's sex later on if you have a Premium Account, although players should not
      feel encouraged to do so. Because of this a nominal fee of 5 premium days is deducted from an account whenever
      players changes one of their characters' gender. For details please see the manual section on accounts.
      <br />
      <br />
      <b>2.3.3 Selecting the Game World</b>
      <br />
      Although there is only one Tibia, the game unfolds on many parallel worlds that are identical in many ways.
      However, for all their similarity you should be aware that there are some notable differences between the various
      game worlds. You will find all the information you need on the various worlds of Tibia in the table on the
      character creation page.
      <ul>
        <li>
          <b>Name</b>
          <br />
          Of course, every game world has a name. Please note that these names often reflect individual characteristics
          of the respective game worlds.
        </li>
        <li>
          <b>Online Since</b>
          <br />
          This column will show you how long the world has been online. For instance, Antica is the oldest game world in
          Tibia (hence the name) - it has been online ever since January 1997! You should consider a game world's age
          before you decide to play on it, because it is likely that the age of a world will influence the way of
          thinking and the social structure of the players that are playing on it. Thus, players on older worlds are
          generally more experienced, and they are usually well organised in powerful guilds, while structures are less
          stable on younger worlds where the difference in between beginners and more experienced players is
          comparatively small. Players may be very different from server to server, so each individual game world has
          its own special flair!
        </li>
        <li>
          <b>Full/Normal/Empty</b>
          <br />
          Depending on how long game worlds have been online they may differ greatly in the size of their population.
          New game worlds may be thinly populated in the beginning, while old worlds are bustling places. We generally
          recommend playing on a game world that is marked as "empty", because at peak times free account players may
          have difficulties to log in to a full world.
        </li>
        <li>
          <b>Location</b>
          <br />
          Tibia runs on servers that are based in different countries. Although you are free to play on any server you
          like, we recommend you choose a game server that is close to your geographical position to make sure you get
          the best playing conditions available. Thus, if you are living in South America or in Asia, you should select
          a server that is based in the U.S., while European players should choose a German server instead.
        </li>
        <li>
          <b>Additional Information</b>
          <br />
          The additional information will show you the game mode under which the respective worlds are running. Worlds
          that are marked as Non-PvP (i.e. "Non-Player versus Player") are worlds on which players cannot attack each
          other, so if you prefer to play peacefully, you should consider choosing a world such as Secura or Harmonia.
          In contrast, game worlds that do not contain any further comment are worlds where the regular PvP restrictions
          apply. On such worlds it is allowed to attack and even kill other players, although excessive player killing
          is still illegal to make sure the situation does not get out of hand. Finally, on worlds marked as
          "PvP-Enforced" combat between players is actually encouraged, so if you prefer the thrill of attacking other
          players, you should choose one of these worlds. For details on PvP combat please see the section on player
          killing.
          <br />A word on the game world of Premia: This world is marked as "Premium", because only premium players may
          play on this world. In all other respects Premia is just like any other world with regular PvP combat.
        </li>
      </ul>
      We suggest you consider the different game worlds carefully before you choose the world you want to play in. Once
      you have chosen your character's world, name and sex you should click on "Accept". Your character will now be
      created. Congratulations! You are now ready to start the game and to enter the world of Tibia! However, before you
      start out on your adventure we strongly recommend you take the time to read our security hints if you have not
      done so already.
      <br />
      <br />
      <br />
      <br />
      <h3>3 Controls and Game Settings</h3>
      <h3>3.1 Starting the Game</h3>
      Once you have created your account as well as a character you are ready to play. To start Tibia, double-click on
      the desktop icon. You will now be taken to the title screen.
      <br />
      <br />
      <img src="/manual/titlescreen.jpg" width="680" height="520" />
      <br />
      You can see a number of buttons in the bottom left hand corner of the title screen:
      <br />
      <ul>
        <li>
          <b>Enter Game</b>
          <br />
          Clicking on this button will take you into the game. Before you can start playing, however, you must enter
          your account number and password. If the data you have entered is correct, a list will appear in which you
          will see the names of all your characters as well as the game worlds they live on. Select the character you
          would like to play with and click on "Ok". Please note that if too many players are online on the selected
          game world, you will have to wait a while before you can log in. The client will show your current position in
          the login queue and automatically try to login your character after the displayed waiting period. Please keep
          in mind that the game server will block all login trials that are made before the waiting period is over.
          <br />
          In case you have not created an account at this stage you can do so now by clicking on the corresponding
          button. This will start the signup process.
        </li>
        <li>
          <b>Access Account</b>
          <br />
          Clicking on this button will take you to your account page. For details please see the manual section on
          accounts.
        </li>
        <li>
          <b>Options</b>
          <br />
          Click on this button to enter the "Options" menu. Most of the buttons displayed here will open further
          submenus where you can change a number of game options to customise the client to your taste. For a
          comprehensive list of all game options see the manual section on game options.
          <br />
          Two buttons are different, however: The one labelled "Hotkeys" does not open up an options menu. Instead it
          will open up a dialog in which you can assign customised messages to hotkeys. The second button is labelled
          "MotD", which is an abbreviation for "Message of the Day". Clicking on this button will display any daily
          server message. Note, however, that you must have logged into the game before any message will be displayed
          here.
        </li>
        <li>
          <b>Info</b>
          <br />
          Click on this button to learn some general information on Tibia as well as on the client you are currently
          using. Also, you will find a button here that will take you to Tibia's official website www.tibia.com.
        </li>
        <li>
          <b>Exit Game</b>
          <br />
          Click on this button to leave the client and to return to the desktop.
        </li>
      </ul>
      <br />
      <h3>3.2 Adventure Screen</h3>
      The adventure screen is the main view of Tibia during the game. The general layout of the adventure screen cannot
      be modified, although some components can be moved around (automap, inventory, status bars and buttons) or
      modified in size (game window, console).
      <br />
      <br />
      <img src="/manual/adventurescreen.jpg" width="685" height="506" />
      <ol>
        <li>Game Window</li>
        <li>Automap</li>
        <li>Status Bars</li>
        <li>Inventory</li>
        <li>Combat Controls</li>
        <li>Control Buttons</li>
        <li>Console</li>
      </ol>
      <table width="100%" cellSpacing="0" cellPadding="3">
        <tbody>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_1.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.1 Game Window</b>
              <br />
              The game window takes up the main part of the adventure screen. Almost all game play takes place on this
              window - you will see other characters moving about, approaching monsters, items on the floor and, of
              course, the surrounding countryside. Your character will always be in the centre of the game window.
              <br />
              It is possible to modify the size of the game window. If you do so, the console will increase or decrease
              in size accordingly. To change the size of the game window left-click on the top bar of the console and
              move your mouse up or down, keeping the left mouse button pressed.
            </td>
          </tr>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="1" height="5" />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_2.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.2 Automap</b>
              <br />
              Similar to a radar screen, the automap shows a bird's-eye view of your surroundings. It is essentially a
              miniature version of the game window, although it covers a much wider area. Like the game window, the
              automap is centred on your character, which is shown as a white cross, and just like in the game window,
              you can move your character by left-clicking on a spot on the automap. There are two important differences
              to the game window, however. Firstly, unexplored areas in the automap are covered by black fog that keeps
              you from seeing what might be hidden there. Secondly, it is possible to mark places of interest on the
              automap. To set a mark right-click onto a spot on the map and select "Set Mark" from the context menu. If
              there is already a mark on the selected spot you can choose to edit or to delete it. If you set or edit a
              mark you can select an icon of your choice and enter a short description.
              <br />
              To the right of the automap you can see a compass rose as well as a number of buttons. These icons are
              designed to scroll the automap in all directions, including higher and lower floors.
              <ul>
                <li>
                  <b>Compass Rose</b>
                  <br />
                  Clicking on the various points of the compass rose will move the automap in the desired direction.
                  Alternatively, you can use a key combination of Alt + cursor keys to scroll the automap.
                  <br />
                  Similar to the "Centre" button, a click on the centre of the compass rose will re-centre your automap.
                </li>
                <li>
                  <b>Magnifying Glasses</b>
                  <br />
                  As might be expected, clicking on the magnifying glasses will modify the automap's zoom factor. The
                  icon at the top serves to zoom out of the map, while a click on the magnifying glass at the bottom
                  will increase the zoom factor.
                  <br />
                  As with all movements of the automap there are shortcuts for modifying the automap zoom factor:
                  Alt+Home will zoom out, while Alt+End will increase the zoom factor.
                </li>
                <li>
                  <b>Show Higher/Lower Floor</b>
                  <br />
                  In addition to the immediate surroundings the automap also shows the maps of floors that are situated
                  above or below your character's current position, provided there are such floors. Click on these
                  buttons to switch the map to upper or lower floors. The corresponding shortcuts are Alt+PageUp and
                  Alt+PageDown, respectively.
                </li>
                <li>
                  <b>Centre</b>
                  <br />
                  Similar to the centre of the compass rose, clicking on this button will re-centre the automap.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_3.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.3 Status Bars</b>
              <br />
              These two bars show the condition of your characters' most important attributes: Their health (red) and
              their mana, i.e. their magical energy (blue). The bars will show the current state of these attributes in
              relation to their respective maximums, while the numbers behind the bars will show the actual amount of
              hit points and mana points your character has.
              <br />
              There are several ways to restore hit points and mana points. For details please see the manual section on
              characters.
            </td>
          </tr>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="1" height="5" />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_4.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.4 Inventory</b>
              <br />
              This part of the adventure screen shows your characters' equipment. You will see a portrait of the active
              character's body, split up into a number of individual slots. These slots represent the locations where
              items can be equipped - there is one slot for your character's head, one for the torso, two for the hands
              etc. To equip an item simply move it into the slot it is intended for.
              <br />
              Please note that items will only be useful when they are placed in the slots they are designed for. You
              can place a shield in the belt slot, but it will not protect you there! Also, placing items in slots they
              are not designed for will often be impossible. For example, you cannot place a sword in your character's
              head slot. If you feel you don't need to see your inventory for a while, you can minimise it by clicking
              on the button in the top left hand corner.
              <br />
              <br />
              Here is a list of all body slots:
              <br />
              <br />
              <ul>
                <li>
                  <b>Armour Slots:</b>
                  These four slots cover your character's feet, legs, torso and head. They are reserved for clothing and
                  armour.
                </li>
                <li>
                  <b>Hand Slots</b>: You can place almost any item in your hands as long as you are strong enough to
                  carry it. However, you will usually carry a weapon and a shield or a single two-handed weapon in your
                  hands. It does not matter in which hand you place the weapon - all characters in Tibia are perfectly
                  ambidextrous.
                </li>
                <li>
                  <b>Amulet Slot:</b>
                  You can place a necklace or an amulet in this slot.
                </li>
                <li>
                  <b>Ring Slot:</b>
                  This slot is reserved for rings.
                </li>
                <li>
                  <b>Container Slot:</b>
                  You can place containers such as bags or backpacks in this slot.
                </li>
                <li>
                  <b>Belt Slot:</b>
                  This slot can be used to store items which you need to have close at hand. It is no coincidence there
                  is an arrow symbol in this slot - if you are using a bow or a crossbow, you have to place your
                  ammunition here. On the other hand, if you are using a melee weapon and a shield, this is a great
                  place to equip a torch.
                </li>
              </ul>
              In addition to these body slots there are three further fields at the bottom of your character's portrait.
              It is not possible to place items there. Instead, these small fields will give you additional information
              about your character:
              <ul>
                <li>
                  <b>Soul Point Indicator</b>
                  <br />
                  The field in the bottom left hand corner shows your amount of soul points. Characters have a maximum
                  of 100 soul points while promoted characters have a maximum amount of 200. Your amount of soul points
                  is reduced whenever you cast a rune spell. Soul points fill up if you receive experience points that
                  equal or are above your character's level for killing one monster, or by sleeping in a bed.
                </li>
                <li>
                  <b>Capacity Indicator</b>
                  <br />
                  The small field in the bottom right hand corner of the inventory shows your current capacity. If you
                  try to pick up an item whose weight exceeds your capacity, you will receive an error message. You can
                  check an item's weight by looking at it.
                </li>
                <li>
                  <b>Condition Indicator</b>
                  <br />
                  The oblong field at the bottom of the inventory will tell you if your character is suffering from a
                  special condition. The nature of the condition is indicated by a corresponding symbol. Special
                  conditions include poisoning and burning as well as the logout block as shown in the image above. For
                  a comprehensive list of all special conditions please see the manual section on characters.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_5.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.5 Combat Controls</b>
              <br />
              To the right of the inventory you can see a set of six buttons. The three buttons to the left serve to set
              the combat mode. How daring do you want your character to be?
              <br />
              <br />
              <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                    <td>
                      <table width="100%" cellSpacing="0" cellPadding="2">
                        <tbody>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols1.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Offensive</b>
                              <br />
                              Characters that are set to offensive combat mode will storm into battle, concentrating all
                              their skill on an all-out attack. Because of this they receive a bonus to their attacks.
                              However, the drawback of such reckless fighting is that a character's defence is reduced
                              as long as he is set to offensive combat mode.
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols2.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Balanced</b>
                              <br />
                              This is the default setting. A character that is set to balanced fighting will divide his
                              skills evenly between attack and defence.
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols3.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Defensive</b>
                              <br />
                              Defensive characters will try to protect themselves as well as possible. Characters can
                              attack while they are set to defensive mode, but their chance to hit will be reduced.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              The buttons to the right will determine your character's automatic movement during combat.
              <br />
              <br />
              <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                    <td>
                      <table width="100%" cellSpacing="0" cellPadding="2">
                        <tbody>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols4.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Stand While Fighting</b>
                              <br />
                              This is the default movement mode. Characters that are set to this mode will try to make a
                              stand where they are. They will not budge unless they are pushed aside or until the player
                              moves them manually.
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols5.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Chase Opponent</b>
                              <br />
                              Characters that are set to "Chase Opponent" will try to corner their opponents. Whenever a
                              target is attacked such characters will try to move as close as possible towards it,
                              taking the shortest way possible. If you are not happy about the way a character is
                              moving, you can easily stop him by clicking on "Stand while fighting" or by moving him
                              using the mouse or the cursor keys. However, note that if "Auto Chase Off" is disabled in
                              the game options, your character will resume with the movement mode "Chase Opponent" once
                              you take the finger from the cursor key.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              The third button to the right serves as a security switch to avoid unwanted attacks on other characters.
              You can use it to switch between secure and normal mode.
              <br />
              <br />
              <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                    <td>
                      <table width="100%" cellSpacing="0" cellPadding="2">
                        <tbody>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols6.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Secure Mode</b>
                              <br />
                              This is the default setting. Characters set to secure mode cannot attack other characters
                              that are not marked by a skull. This way it is not possible to attack innocent bystanders
                              or friends by accident. Note that you can always defend yourself against attacks in this
                              mode because aggressors will be marked automatically. Also, keep in mind that the secure
                              mode will not prevent that your character may get a skull mark if he causes damage, e.g.
                              by fire fields or explosions.
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <img src="/manual/combatcontrols7.gif" width="20" height="20" />
                            </td>
                            <td>
                              <b>Normal Mode</b>
                              <br />
                              If this mode is activated you can freely attack all other characters. Keep in mind,
                              however, that your character will receive a skull mark if you attack unmarked characters.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <img src="/manual/blank.gif" width="20" height="0" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              Please note that the switch for secure and normal mode has no meaning on non-PvP or PvP-enforced game
              worlds. For details on Player vs. Player combat and on skull marks please see our section on
              playerkilling.
            </td>
          </tr>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="1" height="5" />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_6.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.6 Control Buttons</b>
              <br />
              In addition to the combat controls a number of further control buttons are arranged around the inventory.
              <ul>
                <li>
                  <b>Stop</b>
                  <br />
                  The "Stop" button will cause your characters to stop whatever action they are currently taking. As a
                  shortcut you can also hit the Esc key to stop your character.
                </li>
                <li>
                  <b>Options</b>
                  <br />
                  Clicking on this button will open up the game options menu. This menu can also be opened on the title
                  screen. For a comprehensive list of all game options in Tibia see the section on game options.
                </li>
                <li>
                  <b>Help</b>
                  <br />
                  If you have a question or a problem, you can click on this button to get help. A special help menu
                  will open up from which you can select whatever kind of support you need. The first four buttons
                  (client help, game hints, tutor help, rule violation report) will help you to get in-game support,
                  while the last two buttons will refer you to the help sections of our website. For further information
                  on how to get help in the game please see the section on in-game support.
                </li>
                <li>
                  <b>Logout</b>
                  <br />
                  To leave Tibia you should use this button. Unless your character is barred from logging out by a
                  logout block clicking here will take you back to the title screen where your character list will be
                  displayed, so you can easily log in again. The character list will close after 30 seconds due to
                  security reasons if you do not log into the game again.
                  <br />
                  Even though it is possible to shut down the client anytime you should keep in mind that it is possible
                  that your character will stay in the game due to the logout block. The same is possible if you get
                  disconnected from the game server. Also, please note that characters log out automatically if they
                  have been idle for sixteen minutes, i.e. if no action whatsoever is performed during this time. If a
                  character has been idle for 15 minutes, a red message will be displayed which will warn players of the
                  imminent logout.
                </li>
                <li>
                  <b>VIP</b>
                  <br />
                  Clicking on this button will open up the VIP list ("Very Important People"). You can add up to 20
                  characters to this list and premium players even up to 100. This is a very useful feature, because by
                  looking on the VIP list you will always know when your friends and enemies are online. For further
                  information on the VIP list please see the section on player communication.
                </li>
                <li>
                  <b>Battle</b>
                  <br />
                  <table width="100%" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td valign="top">
                          Use this button to open the battle list. In this window you will see a list of all characters
                          and monsters that are currently within battle range. The list includes a miniature version of
                          the character, his health status bar and possibly a skull mark. You will find the battle list
                          is very useful because you can direct your attacks and even your spells through it. However,
                          please note that it is not possible to aim spells at other players' characters through this
                          window.
                        </td>
                        <td width="250" valign="top">
                          <img src="/manual/blank.gif" width="10" height="0" />
                          <img src="/manual/battlelist.gif" width="176" height="90" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <b>Skills</b>
                  <br />A click on this button will open up a subwindow that will show you all you need to know about
                  your character. Among other things, this list contains your character's experience, level, hit points,
                  mana, magic level and skills. The character's current experience and skill levels are indicated by the
                  numbers to the right, while the progress to future skill advancements is indicated by little bars. To
                  receive more detailed information on your progress in a skill place the mouse pointer on the
                  corresponding bar and wait for the tool tip to open. The tool tip will show your progress in per cent.
                  Also, it is possible to hide specific or all skill bars in the skill window. To do so right-click on
                  the window and select the skill bars you would like to hide. For details please see the manual section
                  on characters.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <img src="/manual/bullet_7.gif" width="17" height="17" />
            </td>
            <td width="100%">
              <b>3.2.7 Console</b>
              <br />
              The console is reserved for communication with other players. It consists of an area where all messages
              are logged as well as an entry line in which you can compose messages yourself. The console is usually set
              to the default channel in which you can talk to nearby characters or NPC's. There are further channels
              available, though, which will allow you to chat with people who are far way. You can recognise these
              channels by the labelled tabs. To switch between channels use the "Tab" key or click on the tab of the
              channel you would like to use. For further information on the console and on chatting in general please
              see the manual section on player communication.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <h3>3.3 Basic Controls</h3>
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <img src="/manual/contextmenu.gif" width="121" height="116" />
              <img src="/manual/blank.gif" width="10" height="0" />
            </td>
            <td valign="top">
              Most actions in Tibia are controlled through so-called "context menus". Context menus are small
              sub-windows that open up whenever you right-click on a character or on an item. A number of actions will
              be displayed. To pick one select it by using the mouse pointer and click on the left mouse button to
              confirm.
              <br />
              To the left you can see a typical context menu. You can choose to look at, use, follow or even attack the
              selected creature. Also, you can copy the selected creature's name and paste it by using the key
              combination Ctrl+V.
              <br />
              <b>Note:</b> The keys shown in brackets behind certain actions indicate shortcuts you can use to speed up
              controls. For instance, holding down the Shift key while left-clicking on an item will cause your
              character to look at it, and clicking on an item while pressing the Ctrl key while make him use an item.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>3.3.1 Set Character Outfit</b>
      <br />
      The setting of character outfits is a typical example for an action that is performed by using a context menu.
      Although the set outfit dialog will pop up on the very first time you log in with a character, you can reset
      character outfits anytime. To do so, open a context menu by right-clicking on your character and select "Set
      Outfit". You can choose your favourite outfit from four different figures. Also, you can customise characters even
      further by painting individual body parts with the colour of your choice.
      <br />
      Note that premium players may choose from seven rather than from four character skins. If you have chosen a
      premium skin and your Premium Account runs out, you will be able to keep the skin until the next time you change
      your character's outfit.
      <br />
      <br />
      <img src="/manual/selectoutfit.jpg" width="420" height="225" />
      <br />
      <b>3.3.2 Moving Your Character</b>
      <br />
      <br />
      <b>a) Basic Movement</b>
      <ol>
        <li>
          <b>Using the Mouse</b>
          <br />
          To control your character's movements by using the mouse simply left-click on the spot in the game window
          where you want him to go to. The character will do so automatically, taking the shortest possible route. If
          the spot you have clicked onto is out of reach, the character will stop.
          <br />
          Although characters tend to avoid moving diagonally while walking towards their destination automatically,
          they are nonetheless capable of doing so. To walk diagonally left-click on your character and keep your finger
          on the mouse button, then move the cursor in the direction you want him to go. Please note, however, that
          there is a short delay when moving diagonally.
        </li>
        <li>
          <b>Using the Keyboard</b>
          <br />
          You can also control your character's movement by using the cursor keys or the number keys on the num pad.
          Simply use them in the customary fashion to move up, down, left or right. Moving diagonally is also possible
          by using the keyboard: Simply press 7, 9, 1 or 3 on the num pad to move the character to the northwest,
          northeast, southwest or southeast. Please note, however, that the num lock must not be activated if you want
          to use the num pad to control your character.
        </li>
      </ol>
      It is also possible to change the direction your character is facing: To do so press Ctrl and leave your finger on
      the key, then choose the direction you want your character to look by pressing the corresponding cursor button.
      This feature can be especially useful when casting certain spells that require your character to look into a
      certain direction.
      <br />
      <br />
      <b>b) Moving Up or Down Floors</b>
      <br />
      Of course, characters can also move up or down floors. Depending on the area you are in you will encounter stairs,
      ramps, sewer grates, ladders or simple holes in the floor. If there is a hole in the ceiling, you will see a
      noticeable mark on the dungeon floor. The controls to move up or down floors differ depending on the type of
      stairs you are using:
      <ul>
        <li>To climb stairs or ramps simply move your character onto them. The same is true for holes in the floor.</li>
        <li>
          However, if you would like to climb up through a hole in the ceiling, you must use a rope on the rope spot on
          the floor. Keep in mind that you cannot climb up holes in the ceiling unless you have a rope or the "Magic
          Rope" spell.
        </li>
        <li>To climb down a sewer grate right-click on it and select "Use".</li>
        <li>
          Finally, to climb ladders simply right-click on the floor in front of the ladder you wish to climb and select
          "Use".
        </li>
      </ul>
      Note that it is sometimes possible to move up or down floors by climbing onto items or by using the "Levitate"
      spell.
      <br />
      <br />
      <b>c) Movement Modes</b>
      <br />
      There are two movement modes available which define your character's automatic movement during combat. "Stand
      While Fighting" will cause characters to stay where they are in combat and "Chase Opponent" will make them charge
      towards the enemy. For further information please see the section on the combat controls.
      <br />
      One further option should be noted here, however: You can instruct your character to follow a friendly character
      around on the map without attacking it. To do so right-click on the character and select "Follow". Characters that
      are set to this mode will try to keep as close to the marked character as possible. If the marked character should
      leave the screen, the follow mode will be cancelled. You can also cancel the follow mode manually by
      right-clicking on the character and selecting "Stop follow" or by hitting the Esc key.
      <br />
      <br />
      <b>3.3.3 Looking Around</b>
      <br />
      Sometimes it won't hurt to have a closer look at your surrounding, for example, if you would like to find out the
      name of an item. Taking a closer look at items or creatures is an action that is typically carried out through
      context menus. Simply move the cursor on the item or creature you would like to look at, right-click to open the
      context menu, and select "Look". Alternatively you can click on the item you wish to inspect while holding down a
      Shift key on the keyboard.
      <br />
      <b>Note:</b> Some items have an additional description that you can only see if your character is standing next to
      it or your character carries the item in the inventory. Also, you can get additional information on characters
      like their vocation or level if you look at them. If you look at books, scrolls, letters or other editable items,
      you will receive general descriptions only. To actually read or write into such items they must be used. Please
      see below for instructions on how to use items.
      <br />
      <br />
      <b>3.3.4 Moving Items</b>
      <br />
      <br />
      <b>a) Drag&amp;Drop;</b>
      <br />
      In Tibia, items are moved using "drag&amp;drop;". In other words, to move an item you must left-click on it, then
      pull it on the place you want it to be, holding down the mouse button. For example, if you would like to take an
      item, simply drag it on your backpack and let go of the mouse button. Your character does not have to be placed
      next to the item you would like to take, because characters move automatically towards the desired objects before
      they pick them up. If you would like to throw an item away, click on it and drag it onto the game window. The item
      will be dropped on the spot where you let go of the mouse button. Of course, your character cannot throw items
      through obstracles like walls. Also, note that heavy items such as furniture can only be moved one field away from
      your character at a time.
      <br />
      <br />
      <img src="/manual/moveobjects.gif" width="236" height="136" />
      <b>b) Moving Stacks of Items</b>
      <br />
      If you would like to move a stack such as a heap of gold, a small dialog will open up in which you will be asked
      how many items you would like to move. You can modify the number of items you would like to move by using the
      slide bar or by typing the amount with the keyboard. There are two shortcuts in order to speed up the process of
      moving stacked items: If you drag the stack while holding down the Shift button, you will only move one stacked
      item at a time. On the other hand, if you keep your finger on the Ctrl key while you are dragging the items, the
      whole stack will be moved at once.
      <br />
      <br />
      <b>c) Moving Wall Decorations</b>
      <br />
      Drag&amp;drop; is also used to put up or take down wall decorations such as cuckoo clocks, tapestries and
      paintings. Simply place your character in front of a suitable wall and drag the decoration onto it. Note, however,
      that wall decorations may only be put up on walls facing south and east. You cannot place decorations on windows,
      doors or southeast corner walls. Also, wall decorations may not be placed on walls that are already decorated.
      Finally, please note that some wall decorations cannot be moved at all.
      <br />
      <br />
      <b>3.3.5 Pushing Creatures</b>
      <br />
      Pushing creatures or characters works like moving heavy items. Simply left-click on them and then drag them in the
      direction you want them to be moved. You will find that after a short delay your target will be moved in the
      desired direction, provided there is no wall or other obstacle and the creature or character has not moved away in
      the meantime. Note that your character will skip the next attack if he pushes a creature or character in combat.
      Also, please note that pushing your own character or another character will be slower than moving to the desired
      destination normally. Finally, many strong monsters cannot be pushed and often they can also push items or other
      weak monsters blocking their way.
      <br />
      <br />
      <b>3.3.6 Using Items</b>
      <br />
      Using items is another action that is controlled via context menus. Open the menu by right-clicking on the item,
      then select "Use" with a click on the left mouse button. Items are used in a manner that is typical for their
      function - torches will be lit when used, food will be eaten, scrolls can be edited etc.
      <br />
      <br />
      <img src="/manual/useitem.jpg" width="406" height="185" />
      <br />
      However, some items must be used on other items, characters or map spots in order to work. To use such items open
      the context menu, then select "Use with ...". On doing this the mouse pointer will turn into crosshairs. Now move
      the crosshairs on the item, character or map spot you want to use the item with and left-click to confirm. Typical
      items that are used this way include ropes, shovels, potions and runes. Weapons may also be used this way in order
      to smash many large items such as chairs, chests, etc. Note, however, that each strike only has a limited chance
      of success, so you may have to hit the target you want to destroy several times. Also, please note that you cannot
      destroy most items this way.
      <br />
      Pressing the Ctrl button and holding it down while clicking on an item will work as a shortcut for using items.
      <br />
      <br />
      <b>3.3.7 Rotating Items</b>
      <br />
      This option is only available for large items such as chairs, chests and other kinds of furniture. Since these
      items come with standard facings when bought in a shop you may want to change their facings, e.g. to make sure
      they fit well into your private home. To rotate an item open the context menu by right-clicking on it, then select
      "Rotate" on the menu. The selected item will be turned 90� clockwise, i.e. if it faced south, it will now face
      west.
      <br />
      <br />
      <b>3.3.8 Combat</b>
      <br />
      It is said that the pen is stronger than the sword, but you will quickly find that in Tibia, you will have to
      resort to brute force on many occasions. To attack creatures or other players open the context menu by
      right-clicking on your opponent, then select "Attack". A red frame will appear around your opponent, indicating
      that you are attacking him. Your character will actively attack your opponent as soon as he is in range. If your
      enemy strikes back, there will also be a black frame around him that flashes up briefly with every attack. To
      cancel the attack simply open the context menu again and select "Stop attack." As usual, clicking on the Stop
      button will also work to cancel the attack. Finally, clicking on an opponent while holding down the Alt key works
      as a shortcut to start or to stop an attack.
      <br />
      Please take a look at the description of the combat controls before attacking other characters. For further
      information on combat in Tibia you should have a look at the manual section on combat.
      <br />
      <br />
      <img src="/manual/combat.jpg" width="685" height="506" />
      <br />
      Creatures as well as players often carry items on them, and if they die, those items can be taken. To loot dead
      creatures or characters simply right-click on their bodies to open them. You can now check any possessions the
      dead creature or character has. If you find something that catches your eye, you can take it.
      <br />
      <b>Note:</b> Even though the looting of creatures' bodies killed by other players is not a violation of the Tibia
      Rules it is considered very impolite, and it is also a sure way to upset other people. We therefore recommend you
      ask your fellow players for permission before you loot creatures they have killed.
      <br />
      <br />
      <b>3.3.9 Trading Items with Other Players</b>
      <br />
      Although you can buy a lot of good equipment in the various shops, you will soon realise that the best items are
      actually sold by other players. Watch out for interesting offers. If you do not find the item you are looking for,
      you may consider having a look at the trade channel or on your world's trade board.
      <br />
      Once you have found somebody who is offering the item you are looking for you should meet up with him to trade.
      There is a safe trade option in Tibia which will allow you to trade items with other characters without having to
      fear that you are cheated. To initiate a safe trade open up a context menu by right-clicking on the item you wish
      to trade, then select "Trade with ...". The mouse pointer will turn into crosshairs. Now move the pointer onto the
      character you want to trade with and confirm by clicking on the left mouse button. The player will receive a
      message written in green letters that you are offering to trade an item.
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              If a player offers something in exchange, a trade dialog will open up in which you will see all items that
              are on offer. You can now inspect them by clicking on the items on offer with the right mouse button. If
              you are happy with the item your trading partner is offering, you should click on accept. If your partner
              does the same, the trade will be executed, provided that both trading partners have enough capacity left
              to carry the new items. Please note that it is not possible to modify an offer in the safe trade dialog.
              Trying to do so will result in a cancellation of the deal. If you are not satisfied with your partner's
              offer, simply click on "Reject" and start a new trade. If you want to trade several items at once, place
              them in a container such as a bag or backpack. Right-click on the container and select "Trade with ..." to
              trade it including the content. To avoid misunderstandings any containers that are on offer will be
              opened, and their contents will be shown. You should keep in mind that it is not possible to trade more
              than 100 items at a time.
            </td>
            <td valign="top">
              <img src="/manual/blank.gif" width="10" height="0" />
              <img src="/manual/safetrade.jpg" width="176" height="96" />
              <img src="/manual/blank.gif" width="20" height="0" />
            </td>
          </tr>
        </tbody>
      </table>
      <b>Note:</b> Even though there are other ways to trade items in Tibia, we strongly recommend you always use the
      safe trade mode to avoid unnecessary risks. Keep in mind that thieving is not a violation of the Tibia Rules, so
      the gamemasters will not be able to help you if somebody steals from you.
      <br />
      <br />
      <br />
      <br />
      <h3>3.4 Player Communication</h3>
      Social interaction plays a vital role in Tibia, and communication between players is very important. However, we
      must make sure that a certain language standard is maintained to make sure some players do not spoil the fun of
      others. Remember that insulting, racist, extremely right-wing, sexist, harassing or generally offensive statements
      are considered to be violations of the Tibia Rules. Also, since the various channels have been specifically
      designed for different purposes you should always use the right channel for the kind of message you want to post.
      Finally, please note that the game language in Tibia is English, so unless you are posting on the RL-chat or in a
      private channel you must use English at all times.
      <br />
      <br />
      <b>3.4.1 How to Write Messages</b>
      <br />
      To write messages simply enter the text you want your character to say in the entry line at the bottom of the
      console, then hit Return. There are a number of text functions that will help you to edit your messages: You can
      position the cursor in the line by using Shift+CursorLeft and Shift+CursorRight. To scroll up or down text you
      have previously written use Shift+CursorUp or Shift+CursorDown. Hitting the Home key will place the cursor at the
      beginning of a line, while End will position it at the end of the line.
      <br />
      <br />
      <b>a) Hotkeys</b>
      <br />
      If you find that you need to write a certain message time and again, it is a good idea to assign it to a hotkey.
      This is especially useful for spells. To open the hotkey options dialog click on the "Options" button and select
      "Hotkeys" or hit the key combination Ctrl+K. Select the key you would like to assign your message to with a click
      on the left mouse button and enter the text in the dialog line. From now on the text will appear in the entry line
      whenever you press the hotkey. Your character will actually say the hotkey text as soon as you hit "Enter". You
      can define a hotkey text to be sent automatically by ticking the little box at the bottom of the dialog.
      <br />
      The available hotkeys comprise the function keys F1-F12, Shift+F1-F12 and Ctrl+F1-F12, so you can assign a total
      of 36 messages.
      <br />
      <br />
      <b>b) Automatic Spam Control</b>
      <br />
      Messaging in the default channel and all public channels is limited to make sure that it is not possible to
      continually repeat identical, similar or nonsensical statements within a short period of time ("spamming"). This
      measure ensures the amount of text displayed in the game does not get out of hand. More precisely, all entries are
      monitored to make sure no player writes more than one line of text every 2.5 seconds for a longer period of time.
      Characters that repeatedly exceed this limit will be temporarily muted, unless they are actually casting spells.
      During the time that your character is muted any text you enter will not be displayed, and a message in the game
      window will inform you that your character has been muted. It is still possible for muted characters to write
      private messages to other players or to use private channels, but your characters will not be able to cast spells.
      To prevent possible problems each player has a buffer of four lines of text which can be entered without any
      delay. This buffer will be reduced by one for every line that is entered, but it continually refills at a rate of
      2.5 seconds until the maximum of four lines is reached. However, once the buffer has been emptied, i.e. as soon as
      four lines of text have been entered in rapid succession, it is a good idea to slow down because otherwise your
      character will be temporarily muted. The amount of time during which players are muted depends on how often they
      have exceeded the rate limit already. More precisely, characters will be muted for five seconds multiplied by the
      square number of times they have exceeded the limit in the last hours. Once the mute status has ended the buffer
      will be full again. The number of times the limit has been exceeded will be recorded by the server, so relogging a
      character will not help to reduce the mute punishment.
      <br />
      <b>Note:</b> Players can still be banished by gamemasters for spamming if they circumvent the automatic spam
      control and repeat identical, similar or nonsensical statements within a short period of time or use badly
      formatted text.
      <br />
      <br />
      <b>3.4.2 Channels</b>
      <br />
      To handle communication in Tibia effectively it has been organised in channels. You can identify the various
      channels by the names on top of the tabs. To switch between channels click on the tabs or use the Tab key.
      <br />
      To open up a new channel click on the button with the file card symbol in the top right hand corner of the console
      or hit the key combination Ctrl+O. You can select an open channel from the menu by left-clicking on it with the
      left mouse button. Alternatively, you can open a private message channel to another player by entering the
      character name in the little entry line at the bottom of the dialog. You do not have to write the character's full
      name - it is sufficient to write as many letters as are necessary to distinguish the name from any other and to
      append a tilde symbol (~). If you are finished with a channel, you can click on the "X" button on the console or
      press the key combination Ctrl+E to close it.
      <br />
      As you can see on the tab, communication usually takes place in the "default" channel. However, there are many
      more channels in Tibia which you should be aware of. Note that not all of the channels are open to the public, so
      you can switch to a private channel if you prefer to keep your conversations private. Also, keep in mind that as
      long characters are level 1 they can only use the default channel or write private messages. Certain players who
      are entrusted with game monitoring are able to write eye-catching red text. Such messages are generally important,
      and you should always read them carefully.
      <ul>
        <li>
          <b>Default Channel</b>
          <br />
          Whenever you enter text in this channel your message will be displayed in the game window right above your
          character's head. All nearby characters, i.e. those who are visible on the game window will be able to read
          what you are saying.
          <br />
          It is possible to raise or lower your character's voice. To do so click on the little volume icon in the
          bottom left hand corner. The default setting is one sound wave, i.e. normal loudness. If you click on the icon
          once, your character will yell next time you are entering text, i.e. he will be heard over a great distance.
          If you click twice, however, both sound waves will disappear, and your character will whisper the next line of
          text you enter. Only characters that are standing next to your own character will hear what you are saying.
          <br />
          Alternatively, it is also possible to raise or lower your voice by pressing the hash key "#" as well as a
          letter that will specify the loudness of your character's statement in front of the text. Thus, "#w [Text]"
          will cause the following message to be whispered, while putting #y in front of the text will result in a yell
          and #s will make your character say the following text normally. Note that messages that are entered after #s,
          #y or #w are always posted in the default channel, regardless of which channel is open while it is entered.
          Also, keep in mind that there is a delay of thirty seconds on yelling, so characters cannot yell more than two
          messages per minute.
          <br />A final note: If you find that the game window is covered by too many statements, you can speed up the
          automatic removal of statements by using the key combination Ctrl+W.
        </li>
        <li>
          <b>Game-Chat Channel</b>
          <br />
          The game-chat is a chat channel that is open to all players. Any topic that is discussed in this channel must
          be related to Tibia in some way.
        </li>
        <li>
          <b>Trade Channel</b>
          <br />
          Like its name says, this is a public channel that is reserved for in-game trading, so if there is something
          you would like to buy or sell, this is the channel to use. If you find something interesting, you should send
          a private message to the player who is offering the item and ask for the price.
          <br />
          <b>Note:</b> To avoid confusion the trade channel features an extended message delay of two minutes, i.e.
          players cannot post more than one message per two minutes. Of course, it is not possible for characters to
          post messages in the trade channel while they are muted.
        </li>
        <li>
          <b>RL-Chat Channel</b>
          <br />
          The so-called "Real life" chat is another public chat channel. Unlike the game-chat, however, players are
          allowed to discuss any topic they wish on this channel. Also, this is the only channel in Tibia where
          discussions in languages other than English will be tolerated.
        </li>
        <li>
          <b>Help Channel</b>
          <br />
          The help channel is a public channel in which you can turn to the tutors for help if you have a question on
          topics such as the client controls, the use of accounts, the official website or on general game play. You can
          recognise tutors on the help channel by the noticeable orange colour of the text they write. Please keep in
          mind that it is not the tutors' duty to answer in-depth questions about game content such as quests.
        </li>
        <li>
          <b>Private Chat Channels</b>
          <br />
          If you are a premium player, you can open a private chat channel. You can invite any player you like to this
          channel. Simply right-click on characters or on their names on the VIP list or some other channel and select
          "Invite to private chat". Alternatively, you can right-click on the private chat channel tab. This will open
          up a different context menu which will give you an extended range of options. Apart from inviting players, you
          can also exclude players from your private chat channel by using this menu. To do so click on "exclude
          player", then enter the character's name in the dialog. Finally, note that you can also invite or exclude
          players by entering a hash symbol "#" and a letter that specifies the action you want to take. Thus, "#i
          [Name]" will invite a player, while "#x [Name]" will exclude the character from your private chat channel.
          <br />
          Players who have been invited on a private chat channel will receive a message. They can join the channel by
          selecting it from their channel menus. Private chat channels are closed if the character that has opened it
          decides to do so. If the player who has started the channel leaves the game without closing his private
          channel, it will remain until the last participant closes it or logs out.
        </li>
        <li>
          <b>Guild Channels</b>
          <br />
          Finally, if you are a member of a guild, you will be able to enter a special channel that carries your guild's
          name. Guild channels are private, so it is not possible to invite a player who is not a member of the guild to
          this channel.
        </li>
      </ul>
      <b>3.4.3 Messaging Other Players</b>
      <br />
      If you prefer to have a private conversation with another player, you can open a private message channel. To do so
      simply open a context menu by right-clicking either on the character or on his name in the VIP list or in another
      channel. Now select "Message to [Name]" and click on the left mouse button. Once you have done that a private
      message channel will open. Except for you and the player you are writing to, no other player will be able to read
      messages you are writing in this channel, so this is a fine way to ensure privacy. You can identify private
      message channels by the blue writing and by the character name on the tab.
      <br />
      There is another way to contact players directly, though: Enter the character's name and put an asterisk "*" both
      in front of and behind the name, then enter the message, i.e. "*[Name]* [Text]". Note that this will dispatch a
      single message only without opening a private message channel.
      <br />
      <br />
      <b>3.4.4 Ignore List</b>
      <img src="/manual/ignorelist_icon.gif" width="16" height="16" />
      <br />
      If you feel bothered by some other players' messages, you can ignore them by using the ignore list. To open the
      ignore list click on the "No Entry" symbol in the top right hand corner of the console or hit the key combination
      Ctrl+I. There click on "Add" and enter the name of the character you would like to ignore. Alternatively, you can
      right-click on a character or on a character's message and select "Ignore [Name]". Then the character will
      directly be added to this list. All further messages from characters in the ignore list will not be shown by your
      client until you remove them from your ignore list or until your client is closed. Note that it is also possible
      to permanently set characters on ignore if you select this character in your ignore list and tick the box labelled
      "Ignore Permanently". The selected character's name will now be displayed in red. This character will be ignored
      until the tick is removed. Also, please note that players whose characters are entered on your ignore list will
      not know that they are currently being ignored.
      <br />
      In addition to a list of characters, the ignore dialog also contains two extra functions. "Ignore Yelling" will
      blend out any yelled messages, while "Ignore Private Messages" will keep you from receiving private messages. To
      enable one or both of these ignore functions add ticks in the corresponding boxes by left-clicking on them.
      <br />
      Finally note that the "No Entry" symbol will flash shortly in red if a message has been filtered by the ignore
      list.
      <br />
      <br />
      <b>3.4.5 VIP List</b>
      <br />
      The VIP list is a powerful tool that will help you to find out which of your friends or enemies are online. To
      administer your contacts to fellow Tibians you can customise the VIP list just to your liking and manage it via
      the context menu in which you can choose from the following options:
      <br />
      <ul>
        <li>
          <b>Remove [Name]:</b> This option will remove a selected character from your VIP list.
        </li>
        <li>
          <b>Edit [Name]:</b> Selecting this option will open a dialog in which you can edit a character. Characters on
          the VIP list can be edited in one or more of the following ways:
          <ul>
            <li>
              <b>Select a Type:</b> Characters can be categorised by using this option. Select a graphical icon in order
              to mark the selected name. The character will now be considered to belong to a special type of characters.
              If you sort characters by type, all characters marked with this icon will be displayed together. Note that
              characters can only belong to one type at a time.
            </li>
            <li>
              <b>Write Short Description:</b> It is also possible to comment on characters. Any text entered here will
              be saved. Note that you will have to select "Edit" again in order to read a description.
            </li>
            <li>
              <b>Notify at Login:</b> If you tick this box, you will receive an automatic message whenever the selected
              character logs into the game.
            </li>
          </ul>
        </li>
        <li>
          <b>Message to [Name]:</b> Selecting this option will open a private message channel to the selected character.
        </li>
        <li>
          <b>Add New VIP:</b> Select this option to add a new character to the VIP list. Note that characters can also
          be added to VIP lists by using the regular context menu. Simply right-click on a character and select "Add to
          VIP list."
        </li>
        <li>
          <b>Sort:</b> This option will allow you to organise the VIP list so that you can find out what you need to
          know at a glance. Character names can be arranged in three different ways:
        </li>
        <ul>
          <li>
            <b>By Name:</b> If this option is used, characters will be arranged in an alphabetical order.
          </li>
          <li>
            <b>By Status:</b> Selecting this option will cause the list to show characters according to their status:
            All characters on your VIP list who are currently online will be displayed on the top of the list, while all
            other characters on the list will be displayed in alphabetical order.
          </li>
          <li>
            <b>By Type:</b> To arrange characters according to their type select this option. Players can determine the
            type of a character on their VIP lists by editing the entry.
          </li>
        </ul>
        <li>
          <b>Hide Offline VIPs:</b> If you would like only the characters that are currently online to be displayed, you
          can use this option.
        </li>
        <li>
          <b>Copy Name:</b> By using this option you can copy the name of the selected character in order to use it
          elsewhere. To paste the selected name right-click on the entry field of your choice and select "Paste".
          Alternatively you can use the hotkey Ctrl+V to paste the name.
        </li>
      </ul>
      VIP lists can contain up to 20 names. Note, however, premium players have their VIP lists extended to a total of
      100 names.
      <br />
      <br />
      <br />
      <br />
      <h3>3.5 Game Options</h3>
      <b>3.5.1 General Game Options</b>
      <br />
      This is a list of general game settings. You can enter the options menu by clicking on the "Options" button on the
      title screen or on the adventure screen.
      <ul>
        <li>
          <b>Tibia Classic Controls</b>
          <br />
          Place a tick in this box to use an alternative set of controls. If the classic controls are enabled,
          right-clicking on a target will automatically cause your character to use or - if the target is a character or
          a creature - to attack it. Also, players who are using the classic controls can look at things or characters
          on the game window by placing the mouse pointer on them and by simultaneously clicking on the left and right
          mouse button. It is still possible to use context menus when the classic controls are enabled. To do so hold
          down the Ctrl key and click on a target with any mouse button.
          <br />
          Please note that while the classic controls may be a bit faster than the regular controls you will also be
          more likely to make mistakes because of the increased risk of making a misplaced click.
        </li>
        <li>
          <b>Auto Chase Off</b>
          <br />
          If this option is enabled, your character will automatically interrupt his chase of an opponent whenever you
          move manually, i.e. if you press a cursor key or click somewhere on the game window. Deselecting this option
          will cause the movement mode "Chase Opponent" to remain active, i.e. characters will resume the chase as soon
          as you stop moving them manually.
        </li>
        <li>
          <b>Show Hints</b>
          <br />
          Tibia has a hint feature which is activated whenever something special happens, e.g. when you have killed your
          first monster, when you have reached level 2 etc. A flashing icon in the top right hand corner of the game
          window indicates that a new hint is available. If you click on this icon, a message will open in the game
          window. However, you can deactivate this feature by removing the tick from the "Show hints" box in the general
          options menu. If you should change your mind later on, you can look up any hint in the "Help" menu.
        </li>
        <li>
          <b>Show Names of Creatures</b>
          <br />
          In the game window, all characters and creatures are marked with their names and health bars. However, you can
          remove both the names and/or the health bars by deselecting this option in the game options menu.
          Alternatively this effect can also be achieved by pressing the key combination Ctrl+N. This might be useful
          when you enter a crowded place.
        </li>
        <li>
          <b>Show Textual Effects</b>
          <br />
          Whenever creatures or characters lose hit points or gain experience small numbers appear over their heads in
          the game window. If you prefer not to see this effect, you can turn it off by disabling this option.
        </li>
      </ul>
      <b>3.5.2 Graphics Options</b>
      <br />
      Like its title indicates, this menu offers a variety of options that will allow you to change the graphics
      settings of the client.
      <ul>
        <li>
          <b>Fullscreen Mode</b>
          <br />
          You can play Tibia both in a fullscreen mode and in a windowed mode. In fullscreen mode, Tibia will take up
          the whole screen, while it is possible to modify the client's size if the windowed mode is activated. You can
          toggle between the two modes by using this option. Alternatively, pressing either of the shortcuts Ctrl+F or
          Alt+Enter will have the same effect.
        </li>
        <li>
          <b>Available Resolutions</b>
          <br />
          Select the resolution of your choice in this box. Please note that the client will only be displayed in the
          set resolution if the fullsceen mode is activated. If you are running Tibia in windowed mode, the client's
          resolution will be adapted automatically to match the current resolution of the desktop.
        </li>
        <li>
          <b>Advanced Options</b>
          <ul>
            <li>
              <b>Show Light Effects</b>
              <br />
              Not all graphics cards are capable of drawing light effects fast enough for Tibia to run smoothly. If your
              system is working with such a graphics card, it may be a good idea to deactivate the light effects in
              order to improve overall game performance. However, this will cause the light effects to be replaced by a
              rather ungainly mesh of black dots, so Tibia will not look as nice as it could. Note that this option will
              be disabled if your graphics card is not able to draw light effects at all.
            </li>
            <li>
              <b>Don't Stretch/Shrink the Map</b>
              <br />
              The stretching and shrinking of graphics to adapt the size of the game window is another process which can
              take up a lot of performance. If you check this option, the game window can only be displayed in its
              original size, which will make your graphics card's job easier and improve performance.
              <br />
              When you start Tibia for the first time, the client will work out automatically whether or not your
              graphics card supports stretching and set this option accordingly.
            </li>
            <li>
              <b>Windows Mouse Pointer</b>
              <br />
              It can happen that your graphics card is not capable of drawing the Windows mouse pointer correctly. You
              will recognise that this is the case if the mouse pointer flickers. To counter this effect you can disable
              this option, which will cause Tibia to use its own mouse pointer.
            </li>
            <li>
              <b>Adjust Framerate Limit</b>
              <br />
              By using this slide bar you can modify the preset framerate limit. The default setting is "max", i.e. the
              client will try to produce as many frames as possible. If you find your computer's performance is bad when
              running Tibia, you should try reducing the framerate.
            </li>
          </ul>
        </li>
      </ul>
      <b>3.5.3 Console Options</b>
      <br />
      This set of options will allow you to customise the settings of the console.
      <ul>
        <li>
          <b>Show Info Messages</b>
          <br />
          Whenever you examine characters or items you receive a green message that will be displayed both in the game
          window and in the console. By deselecting this option it is possible to deactivate info messages in the
          console. Note, however, that messages in the game window cannot be deactivated.
        </li>
        <li>
          <b>Show Event Messages</b>
          <br />
          Game events such as skill or level advances are displayed in white writing. If this option is disabled, event
          messages will not be displayed in the console. However, they will be displayed in the game window normally.
        </li>
        <li>
          <b>Show Status Messages</b>
          <br />
          Status messages include changes in your character's condition such as hit point loss or poisoning as well as a
          number of messages that relate to different topics. Although they are displayed at the bottom of the game
          window they are similar to event messages in that they are displayed in white writing. You can deactivate
          status messages in the console by removing the tick from this option. However, you cannot deactivate status
          messages in the game window by using this option.
        </li>
        <li>
          <b>Show Timestamp in Console</b>
          <br />
          If this option is checked, the time will be added to any message that is displayed in the game window. Note
          that the time displayed is the time that your own computer is set to.
        </li>
      </ul>
      <b>3.5.4 Network Options</b>
      <ul>
        <li>
          <b>Test Server Login</b>
          <br />
          This is the only option you can select from the network menu. If this box is checked, your client will try to
          connect to the test server. If no test server is online, you will receive an error message. To find out
          whether or not a test server is currently online you should make sure to check out the news on the official
          website regularly. Please note that you will not be able to log into regular game worlds as long as this
          option is selected.
        </li>
      </ul>
      <br />
      <h3>3.6 Shortcuts</h3>
      This is a comprehensive list of all shortcuts that are available in Tibia.
      <br />
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
            <td>
              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Text Editing</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+C</td>
                    <td>Copy the selected text into the clipboard.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+X</td>
                    <td>Cut the selected text and put it into the clipboard.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+V</td>
                    <td>Paste text from the clipboard to the current cursor position.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+A</td>
                    <td>Mark the whole text in the current entry field.</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Channels</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Tab / Shift+Tab</td>
                    <td>Switch between channels.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+D</td>
                    <td>Switch to the default channel.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+O</td>
                    <td>Open a new channel.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+E</td>
                    <td>Close the current channel.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+M</td>
                    <td>Show messages in the current channel.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+I</td>
                    <td>Open the ignore list.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+K</td>
                    <td>Assign texts to hotkeys.</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Help Functions</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+H</td>
                    <td>Get online help for the client.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+T</td>
                    <td>Open the help channel to get help by tutors.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+R</td>
                    <td>Send a rule violation report to the gamemasters and counsellors.</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Automap</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Alt+Home/End</td>
                    <td>Zoom the automap in or out.</td>
                  </tr>
                  <tr>
                    <td width="15%">Alt+CursorKeys</td>
                    <td>Scroll on the automap.</td>
                  </tr>
                  <tr>
                    <td width="15%">Alt+PageUp/Down</td>
                    <td>Switch the automap to a higher or lower floor.</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Control Buttons</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Esc</td>
                    <td>Stop any character action.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+Q / Ctrl+L</td>
                    <td>Log out from the game.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+G</td>
                    <td>Log out and switch to another character of your account.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+P</td>
                    <td>Open the VIP list.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+B</td>
                    <td>Open the battle list.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+S</td>
                    <td>Open the skills window.</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="white">
                      <b>Game Window</b>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+W</td>
                    <td>Delete old messages from the game window.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+N</td>
                    <td>Hide the names and the status bars of the creatures on the game window.</td>
                  </tr>
                  <tr>
                    <td width="15%">Ctrl+F / Alt+Enter</td>
                    <td>Switch to fullscreen mode or back to windowed mode.</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <h3>4 Game Play</h3>
      In this section you will learn everything you need to know about Tibia's game play. This includes detailed
      information on characters, on the world and creatures of Tibia, and on major game features such as magic, quests,
      guilds and houses.
      <br />
      <br />
      <br />
      <h3>4.1 Characters</h3>
      Player avatars in Tibia are called characters. They are of crucial importance, because it is not possible to play
      the game without them. Also, you will find that the training and general improvement of your characters makes up a
      large part of the fun of Tibia. Characters are determined by a number of characteristics. Some of these
      characteristics such as name, gender or game world are defined during character creation and cannot be modified
      later on, while others will change in the course of the game.
      <br />
      <br />
      <b>4.1.1 Experience Points and Levels</b>
      <br />
      The level of a character is perhaps the single most important characteristic. As a rule, characters grow in power
      whenever their level increases, so the level is a good indicator of a character's overall strength. To advance in
      level characters must collect experience points. Whenever they have amassed enough points they will advance to the
      next level automatically. The progress to the next level advancement is indicated by a little bar. Experience
      points are gained by killing creatures. Killing other characters will not yield experience unless you are playing
      on a PvP-enforced world, where the killing of characters is rewarded with experience points if certain
      requirements are met.
      <br /> In addition to the increase in power there is another good reason why you should try to level up your
      character: Many quests can only be completed by characters that have reached a certain level.
      <br />
      Here is a list of all characteristics that improve whenever characters advance in level:
      <ul>
        <li>
          <b>Hit Points and Mana</b>
          <br />
          Both his maximum hit points and maximum mana increase whenever a character advances in level. Note, however,
          that while the net increase of hit points and mana is initially the same for all characters this will change
          once they have left Rookgaard and chosen their vocation. On the main continent the amount by which hit points
          and mana increase is determined by a character's vocation: Fighter classes such as knights gain more hit
          points than mana, while spell casters such as druids will receive more mana and fewer hit points with each
          level.
          <br />
        </li>
        <li>
          <b>Capacity</b>
          <br />A character's capacity to carry items will increase each time he advances a level. Again, a character's
          vocation is a crucial factor here, because some character classes tend to be stronger than others.
        </li>
        <li>
          <b>Movement</b>
          <br />A character's walking speed will increase with every level. Note that this does not influence combat
          speed, i.e. a character will not be able to attack or defend faster if he advances in level.
        </li>
        <li>
          <b>Magical Power</b>
          <br />
          The damage a character causes when using harmful magic will increase with every level. Also, any healing
          spells a player uses will become more effective.
        </li>
      </ul>
      <b>4.1.2 Hit Points, Mana and Soul Points</b>
      <br />
      Hit points and mana represent your character's health and magical energy. The maximum levels of hitpoints and mana
      are determined by a character's level and vocation. If characters have their hit points reduced to zero, they die,
      while characters that have no mana are unable to cast any spells. Hit points and mana can be restored by feeding
      characters. Both values will steadily regenerate as long as a character has had enough to eat. Healing spells or
      life fluids can also be used to restore hit points quickly, while mana fluids can boost a character's mana.
      However, it is not possible to raise a character's mana and hit points beyond the maximum of his respective level
      by any means. Also, please note that hit points and mana will not regenerate if a player is standing in a
      protection zone such as a depot or a house.
      <br />
      In addition to mana your character needs soul points to cast rune spells. The maximum of soul points is 100 for
      normal characters, and 200 for promoted ones. If you gain experience points that equal at least your character's
      level for one kill, you obtain soul points for a while. For example, if you are a level 10 character, you would
      need to kill a monster that gives you at least 10 experience points, e.g. a snake you have killed alone. From that
      kill on, your soul points will be restored for 4 minutes. Normal characters will regain 1 soul point every 2
      minutes, promoted characters will restore 1 soul point every 15 seconds. Characters also obtain one soul point for
      every 15 minutes they are sleeping in a bed.
      <br />
      <br />
      <b>4.1.3 Skills</b>
      <br />
      Skills determine a character's ability to perform vital actions such as attacking with a weapon, blocking blows
      with shields etc. Unlike hit points or mana, skills do not improve when a character advances in level. Instead,
      skill levels increase through use, i.e. a character will train sword fighting when using an edged weapon, while
      his club fighting skill will gradually improve if he uses a blunt weapon. If a skill has been used often enough to
      advance, a white message will be displayed in the game window, and the skill level will automatically increase by
      one. The progress to the next skill level is indicated by a little bar underneath each skill.
      <br />
      There are eight different skills in Tibia. Most of them are related to the use of weapons:
      <ul>
        <li>
          <b>Fist Fighting</b>
          <br />
          This skill determines your character's ability to fight with bare hands. Needless to say, characters do not
          cause much damage when fighting unarmed, even if they have a considerable fist fighting skill.
        </li>
        <li>
          <b>Club Fighting</b>
          <br />
          This is a weapon skill. It covers blunt weapons such as clubs, maces, staffs and hammers.
        </li>
        <li>
          <b>Sword Fighting</b>
          <br />
          Another weapon skill. This skill is needed to use any kind of edged weapons, from puny knifes to mighty giant
          swords.
        </li>
        <li>
          <b>Axe Fighting</b>
          <br />
          Similar to other weapon skills, your character will need the corresponding skill to successfully wield any
          kinds of axe, from hatchets to the legendary Stonecutter Axe. Also, this skill covers pole weapons such as
          halberds and lances.
        </li>
        <li>
          <b>Distance Fighting</b>
          <br />
          This weapon skill covers all distance weapons. This includes thrown weapons such as stones and spears as well
          as bows and crossbows.
        </li>
        <li>
          <b>Shielding</b>
          <br />
          Shielding is the ability to successfully block enemy attacks with a shield. Of course, characters need to hold
          shields in their hands in order to use this skill. Note that it is not possible to block more than two
          opponents at a time.
        </li>
        <li>
          <b>Fishing</b>
          <br />
          Fishing differs from other skills in that you must actively use it every single time you try to catch a fish.
          To use this skill characters need a fishing rod and some worms in their inventory. Worms can be found in some
          monsters or can be bought at shops that also sell fishing rods. Equipped with the fishing gear, you will have
          to find a place close to a river or some other body of water that contains fish. Once you have found a
          suitable place all you need to do is to use the fishing rod on the water. Do not worry if you do not catch a
          fish immediately - your chance to catch one will increase with your fishing skill. However, please note that
          not all bodies of water contain fish, so if you have tried many times without success, it is probably a good
          idea to try your luck somewhere else. A worm will vanish from your inventory every time you actually catch a
          fish, so make sure you have always enough baits left.
        </li>
        <li>
          <b>Magic Level</b>
          <br />
          The magic level is similar to regular weapon skills in that it directly influences the power of a character's
          spells. Thus, a druid with a high magic level will restore more hit points when casting a healing spell than a
          druid of comparable experience who has a lower magic level. This way it is even possible for a low-level
          spellcaster to cast spells that are more powerful than those of some high-level characters. However, magic
          levels are also important for another reason. Each spell in Tibia has a certain spell level, and characters
          can only learn spells if their magic level is equal to or surpasses that level, e.g. a sorcerer can only learn
          the "Ultimate Healing" spell if he has magic level 8 or higher. Please see the manual section on magic for
          details.
        </li>
      </ul>
      <b>4.1.4 Vocations</b>
      <br />
      Characters in Tibia divide into vocations, i.e. character professions. A character's vocation determines his
      characteristics in many ways. For instance, a knight will quickly surpass any spellcaster in his fighting skills,
      while magic users such as sorcerers or druids can advance their magic levels further than any paladin or knight
      can ever hope to reach. There are four vocations players can choose from once their character has made level 8:{" "}
      <br />
      <br />
      <b>a) Druids</b>
      <br />
      <img src="/manual/druids.gif" width="340" height="343" />
      Druids are pure magic users. Like sorcerers, they are of weak build, and their weapon skills are rather limited.
      But then, what do you need weapons for when you can bring down your enemies with a well-aimed blast of magic?
      Druids have a couple of offensive spells at their disposals, and an experienced druid with a reasonable magic
      level can take out enemies as well as any other vocation in Tibia.
      <br />
      However, even though druids can be fearsome opponents their magic focuses on different aspects. Being friends of
      nature and of all that is alive druids concentrate on the creative and benevolent side of magic. Druids are
      Tibia's best healers, and their ability to cure others makes them very popular with all other vocations. Only they
      know how to create the coveted "Ultimate Healing" runes, and they can earn lots of money this way. Experienced
      druids are never short of cash, because players of other vocations will be happy to pay them good money in
      exchange for their runes. Knights especially depend on healing runes because they have little magic of their own.
      Druids and knights often enter symbiotic partnerships for this reason. However, players of all other vocations
      appreciate to have a druid around as a life-saver, so druids have an advantage when it comes to making friends.
      <br />
      If you prefer using powerful magic instead of brute force, and if you are also a team player who likes to
      cooperate with others, you should choose a druid.
      <br />
      <br />
      <b>b) Knights</b>
      <br />
      <img src="/manual/knights.gif" width="300" height="361" />
      Knights are the toughest warriors in Tibia. They are strong, resilient, and they know how to wield any melee
      weapon with fearsome efficiency. In combat, they are always found in the front line, engaging the mightiest
      opponents in vicious hand-to-hand fighting. When they are travelling with others it is usually the knights who
      charge enemies in order to protect their companions. Because of their incredible resilience and their skilled use
      of shields experienced knights are almost impossible to overcome, and even if a battle is lost, it is usually the
      knights who are the last to fall.
      <br />
      However, for all their prowess in combat knights do have a weakness: Knights are comparatively weak when it comes
      to using magic. Even though they know how to cast a spell or two their magic is rather basic. For this reason
      knights rely on others to heal the wounds they receive in battle, often resorting to the use of healing runes
      which they buy from druids. However, this has never been a problem for a knight, because their ability to amass
      large amounts of gold is legendary. Strong as they are, knights can carry immense amounts of items which they can
      sell for good money in any city, so knights never leave a dungeon with empty hands.
      <br />
      If you are looking for a vocation that is easy to play and to level up, the knight is what you are looking for.
      <br />
      <br />
      <b>c) Paladins</b>
      <br />
      <img src="/manual/paladins.gif" width="300" height="360" />
      Paladins, too, are gifted melee fighters. Their ability to train melee skills is second only to that of knights.
      Also, they know how to handle shields very well, and experienced paladins can block most enemies even though they
      are not as resilient as knights. The paladins' true strength, however, is their ability to fight at a distance.
      Paladins are the best archers there are. From throwing simple stones to firing crossbows, paladins can learn to
      handle any distance weapon with deadly accuracy. A paladin that manages to keep away from his opponents in battle
      can bring down any enemy.
      <br />
      But there is more to paladins. They are also magic users of considerable skill who have an impressive range of
      spells at their disposal. And even though their magic skill cannot be compared to that of pure magic users such as
      druids or sorcerers, paladins have access to many additional spells because they can use almost any rune there is.
      An experienced paladin firing crossbow bolts while using lethal Sudden Death runes at the same time is a
      terrifying sight indeed!
      <br />
      Paladins are the best all-rounders in Tibia. If you are looking for a good fighter who can also handle magic
      pretty well, the paladin should be your choice.
      <br />
      <br />
      <b>d) Sorcerers</b>
      <br />
      <img src="/manual/sorcerers.gif" width="340" height="331" />
      Like druids, Sorcerers focus on magic use. Similar to their more peaceful brethren, sorcerers require skill and
      patience to rise in power because their weapon skills are very limited, and just like druids sorcerers are of weak
      constitution. However, sorcerers have great potential. In fact, due to the offensive nature of their spells
      sorcerers are perhaps the most deadly of all vocations. Unlike druids who focus on the creative and benevolent
      side of magic, sorcerers concentrate on its dark, destructive side, and their spells can become truly devastating
      in higher levels. Only sorcerers can create the notorious "Sudden Death" runes, and only they can learn how to
      cast the apocalyptic "Ultimate Explosion" spell. An experienced sorcerer can bring down the mightiest enemies with
      a wink of his eye, and because of this sorcerers are a welcome addition to any hunting party.
      <br />
      The sorcerer is more difficult to play than other vocations, so if you are new to Tibia, you should go for a
      fighter vocation such as the knight or the paladin instead. However, if it is sheer magic fire power you are
      looking for, you should go for a sorcerer.
      <br />
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
            <td>
              <table width="100%" cellSpacing="1" cellPadding="2">
                <tbody>
                  <tr>
                    <td></td>
                    <td className="white" align="center">
                      <b>Druid</b>
                    </td>
                    <td className="white" align="center">
                      <b>Knight</b>
                    </td>
                    <td className="white" align="center">
                      <b>Paladin</b>
                    </td>
                    <td className="white" align="center">
                      <b>Sorcerer</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Hit Point Gain / Level</b>
                    </td>
                    <td align="center">5</td>
                    <td align="center">15</td>
                    <td align="center">10</td>
                    <td align="center">5</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Mana Gain / Level</b>
                    </td>
                    <td align="center">30</td>
                    <td align="center">5</td>
                    <td align="center">15</td>
                    <td align="center">30</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Capacity Gain / Level</b>
                    </td>
                    <td align="center">10</td>
                    <td align="center">25</td>
                    <td align="center">20</td>
                    <td align="center">10</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Favourite Skill</b>
                    </td>
                    <td align="center">Magic Level</td>
                    <td align="center">all melee skills</td>
                    <td align="center">Distance Fighting</td>
                    <td align="center">Magic Level</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Favourite Spell</b>
                    </td>
                    <td align="center">Ultimate Healing Rune</td>
                    <td align="center">Light Healing</td>
                    <td align="center">Create Arrows</td>
                    <td align="center">Sudden Death Rune</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Hit Point Regeneration</b>
                    </td>
                    <td align="center">slow</td>
                    <td align="center">fast</td>
                    <td align="center">medium</td>
                    <td align="center">slow</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Mana Regeneration</b>
                    </td>
                    <td align="center">fast</td>
                    <td align="center">slow</td>
                    <td align="center">medium</td>
                    <td align="center">fast</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Promotion Title</b>
                    </td>
                    <td align="center">Elder Druid</td>
                    <td align="center">Elite Knight</td>
                    <td align="center">Royal Paladin</td>
                    <td align="center">Master Sorcerer</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>4.1.5 Special Conditions</b>
      <br />
      Characters can suffer from special conditions which can have diverse effects. They have one thing in common,
      however - all special conditions are temporary, and given time they will eventually disappear.
      <br />
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="20" height="1" />
            </td>
            <td>
              <table width="100%" cellSpacing="0" cellPadding="2">
                <tbody>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_poisoned.gif" width="11" height="11" />
                      <b>Poisoned:</b>
                      This condition can be caused by spells or by certain monsters. Poisons can vary greatly in the
                      total damage done, but any poisoning can easily be removed by using the "Antidote" spell or the
                      "Antidote Rune".
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_burning.gif" width="11" height="11" />
                      <b>Burning:</b>
                      This is a harmful element effect which will cause your character to lose hit points for an
                      extended period of time. Until it has ended a searing flame will appear on your character at
                      regular intervals. The damage done by the fire depends on its cause.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_electrified.gif" width="11" height="11" />
                      <b>Electrified:</b>
                      Another damaging element effect, electrification leads to protracted hit point loss similar to the
                      damage caused by fire. A flash of electrical energy will appear on your character at regular
                      intervals, causing loss of hit points each time it appears. Similar to the condition of burning
                      there is no remedy against electrification - all you can do is to heal the caused damage and to
                      wait for the special condition to go away.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_drunk.gif" width="11" height="11" />
                      <b>Drunk:</b>
                      The taverns on Tibia are popular places, and many adventurers enjoy relaxing from their wearisome
                      travels over a pint of cool beer. However, Tibian beer is pretty strong, so don't be surprised if
                      your character has problems with walking in a straight line for a while.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_slowed.gif" width="11" height="11" />
                      <b>Slowed:</b>
                      Some creatures or spells may slow your character down. Until the effect has ended or until it has
                      been dispelled by using healing magic your character will walk much slower than usual. Note,
                      however, that all other actions such as casting spells can be carried out normally.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_haste.gif" width="11" height="11" />
                      <b>Haste:</b>
                      This condition is the direct opposite of the "Slow" effect. As long as it lasts your character
                      will move much faster, although any other character effects such as hit point regeneration or
                      attack rate will not be sped up. Needless to say, this is a desirable effect. Characters can be
                      hasted by spells or by special magical items.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_magicshield.gif" width="11" height="11" />
                      <b>Magic Shield:</b>
                      Another positive spell effect. Magic shields protect characters from hit point loss while they
                      last, reducing their mana instead. However, note that if a character's mana is reduced to zero,
                      any further damage will be deducted from his hit points normally.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/condition_logoutblock.gif" width="11" height="11" />
                      <b>Logout Block:</b>
                      Although it is perhaps the most common condition, the logout block icon is special. Whenever your
                      characters are involved in violence they will be barred from logging out. For details please see
                      the manual section on logout block.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <img src="/manual/skull_white.gif" width="11" height="11" />
                      <b>Skull Mark:</b>
                      Another special case, a skull indicates that your character has been marked as a player killer or
                      that he has joined a party. For details please see the sections on skull marks and on the party
                      mode.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <img src="/manual/blank.gif" width="20" height="1" />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>4.1.6 Death</b>
      <br />
      Each character has a limited amount of hit points. Whenever characters take damage through weapon attacks, poison
      or other harmful effects they lose hit points. Characters whose hit points drop to zero die. However, in Tibia,
      death is not the end. Characters that have died will always be returned to the temple of their home city safe and
      sound, so even though it is possible for characters to die they are in fact immortal. However, this does not mean
      that dying does not matter at all in the game, nor is death desirable. A character that dies will suffer the
      following penalties:
      <ul>
        <li>
          <b>Experience Loss</b>
          <br />
          Characters that die lose ten per cent of their total experience points. If their character is promoted, this
          amount will be reduced to seven per cent. Moreover, your character can also collect blessings in the game
          which will further reduce the loss in experience points. Each blessing will reduce the loss by a further per
          cent, so promoted characters that have received all five blessings will lose a mere two per cent of their
          experience point total.
        </li>
        <li>
          <b>Skill Loss</b>
          <br />
          In addition to the loss of experience points, a character's skills will also be reduced on dying. However,
          this does not necessarily mean that your character will lose levels in every skill because of the way skill
          level advancements are calculated. If your character was close to an advancement in a given skill when he
          died, he may actually not lose a skill level at all, although he will take much longer to advance in that
          skill again. Similar to experience loss, skill loss will be reduced if a character is promoted or if he has
          received one or more blessings.
        </li>
        <li>
          <b>Item Loss</b>
          <br />
          Finally, characters that die have a chance of dropping items. This chance is ten per cent per equipped item.
          However, containers such as bags or backpacks will always be dropped along with their contents. Any items lost
          upon death will remain with the body of the deceased character and can be looted by any player who happens to
          find it. Please note that the chance of dropping items will not be reduced if a character is promoted.
          However, there is an item in this game called the "Amulet of Loss" which will effectively prevent your
          character from dropping any item upon death unless your character is marked with a red skull. Characters that
          are marked with a red skull will always lose all equipment whenever they die.
        </li>
      </ul>
      <b>4.1.7 Promotions</b>
      <br />
      Each vocation has an elite rank which is achieved by getting promoted. A promotion will give several advantages to
      your character:
      <ul>
        <li>
          <b>Cool Title:</b> Characters receive an official title which depends on their vocation. Thus, paladins will
          be promoted to "royal paladins", while sorcerers will be appointed "master sorcerers" etc.
        </li>
        <li>
          <b>Improved Regeneration:</b> The regeneration speed of a character's hit points and mana will increase. More
          precisely, druids and sorcerers will regenerate mana at an increased rate, while knights take less time to
          heal their wounds. Promoted paladins, on the other hand, have both their mana and their hit point regeneration
          speed increased.
        </li>
        <li>
          <b>More Soul Points:</b> Promoted characters have 200 soul points instead of 100. Also the regeneration speed
          of soul points is increased. A promoted character will regain 1 soul point every 15 seconds.
        </li>
        <li>
          <b>Reduced Loss when Dying:</b> Your character will lose fewer experience points and skills when dying.
        </li>
        <li>
          <b>Special Spell:</b> Each vocation will be able to learn one extra spell.
        </li>
      </ul>
      Promotions are awarded by the king of Thais, the queen of Carlin, the dwarven emperor in Kazordoon and the grand
      vizier of Ankrahmun. Your character must talk to any one of these NPCs and ask them to <i>promote</i> you.
      However, characters must meet certain conditions in order to be promoted to their vocation's elite rank:
      <ul>
        <li>
          <b>Level:</b> They must have reached at least level 20.
        </li>
        <li>
          <b>Payment:</b> They must pay a one-time fee of 20,000 gold pieces.
        </li>
        <li>
          <b>Premium Account:</b> Only premium players can promote their characters.
        </li>
      </ul>
      Please note that promotions will be suspended if a Premium Account expires. Any promoted characters on an account
      are reduced to their normal rank, and all benefits provided by promotions are disabled. However, as soon as a new
      Premium Account is assigned all previously promoted characters will get their former status back without having to
      pay the promotion fee again.
      <br />
      <br />
      <br />
      <br />
      <h3>4.2 The World of Tibia</h3>
      Just like the real world, Tibia is a busy place full of life and activity, a world that has a history and a
      distinct look of its own. All players should know a thing or two about it in order to understand the complex
      universe their characters are living in.
      <br />
      <br />
      <b>4.2.1 Creatures, NPCs and Characters</b>
      <br />
      The people and creatures who populate the world of Tibia divide into three different groups: characters, NPCs and
      creatures.
      <br />
      <br />
      <b>a) Creatures</b>
      <br />
      The term creature comprises many different kinds of unpleasant life forms: From renegade humans such as amazons or
      black knights to ghastly undead such as vampires or ghouls, from dangerous wildlife such as wolves or bears to
      vicious monsters such as giant spiders or mighty dragons. Beside regular creatures you can encounter special
      monsters that appear rarely or only at a single location. So the world of Tibia is teeming with a large variety of
      species that make an adventurer's life difficult. However, it is only by killing creatures that characters can
      earn the coveted experience points, so every adventurer who is worth his salt will sooner or later start hunting
      them. Don't worry about the biological diversity of Tibia: Creatures have amazing reproductive abilities, and you
      can be sure that for every monster you kill another one will reappear sooner or later. However, note that
      creatures do not respawn in an area as long as characters are near. But be aware of monster raids! Sometimes
      creatures suddenly team up to a surprise attack on passing adventurers or even assault a city. You can find a
      comprehensive list of all creatures in our library section.
      <br />
      <br />
      <b>b) Non Player Characters (NPCs)</b>
      <br />
      The permanent inhabitants of Tibia are usually referred to as "non player characters" or simply "NPCs". They are
      usually humanoid in appearance, but unlike characters they are always around, and they cannot be attacked. For the
      most part, NPCs are the good people who live in the villages and cities, but you can find them just about anywhere
      in Tibia. There are even some NPC monsters! Non player characters can be helpful in many ways - they may trade
      with you, send you on quests or provide you with valuable information. Talking to NPCs is generally done by using
      key words. Make sure you greet an NPC by saying <i>hello</i> or <i>hi</i>, then try some words. Typical key words
      include <i>name</i>,<i>job</i>, <i>help</i> or <i>mission</i>. You should always watch out for core phrases that
      will give you a hint on what to look for. However, make sure you do not use swear words when a non player
      character is near. Many of them are easily offended, and they may punish you harshly if you insult them!
      <br />
      <br />
      <b>c) Characters</b>
      <br />
      All player avatars including your own are referred to as "characters". You can talk, trade, explore and sometimes
      even fight with other characters. Always keep in mind that a real person is behind every single character, so make
      sure to follow the Tibia Rules when dealing with other characters at all times. For further information please see
      the section on characters.
      <br />
      <br />
      <b>4.2.2 Geography</b>
      <br />
      <br />
      <b>a) Rookgaard</b>
      <br />
      Off the western shore of the main continent, the large island Rookgaard is situated in a stormy sea. This island
      is the first part of Tibia new adventurers explore, because King Tibianus III himself declared that any new
      adventurers must master the challenges of Rookgaard island before they are allowed to enter his realm.
      <br />
      Because of its special role Rookgaard has its own laws. No player killing is permitted there, even if you are
      playing on a world where PvP combat is enabled or enforced. Also, characters must have reached level 2 before they
      can leave the village and enter the surrounding wilderness. The south western part of Rookgaard is reserved to
      premium players. If you are new to Tibia, the quickstart can provide you with some valuable tips on what to do and
      where to go in Rookgaard.
      <br />
      <b>Note:</b> Characters cannot return to Rookgaard once they have moved on to the main continent. There is one
      exception to this rule, though. Characters that have been killed often enough to have their level reduced to 5
      will be completely reset. Reset characters must start from scratch again, i.e. they are taken back to Rookgaard
      and lose all carried items, all skills, spells and levels. Note, however, that items a character has stored in
      depots will not be deleted if he is killed often enough to be sent back to Rookgaard.
      <br />
      <br />
      <b>b) Main Continent</b>
      <br />
      The main continent is the biggest land mass of Tibia. It is an ancient land that is surrounded on all sides by
      savage seas. Due to intense volcanic activity in prehistoric times the interior of the continent is extremely
      diverse, often changing its face abruptly. Stretches of quiet forests and of fertile farming land are interrupted
      by wild rivers or by craggy mountain ranges, and a day's travel can easily bring the wanderer from a marshy swamp
      to an arid desert.
      <br />
      The long history of Tibia has left its mark upon the land. There are many ruins and ancient dungeons whose origins
      are lost in history, and many a place that used to be grand and glorious is now the gloomy hideout of some vile
      creature. However, civilised life has survived to this day, and the mighty cities of Tibia are bustling with life
      and commerce like they have always done.
      <br />
      <br />
      <b>c) Premium Areas</b>
      <br />
      To the east of the main continent two huge islands may be found. The only way for a traveller to reach them is by
      boat or by flying carpet, so only premium players can go there. The island of Edron, a crescent-shaped piece of
      land off the main continent's north-eastern coast, is the smaller one of the two. Its existence has been a secret
      for a long time, and the city that bears its name is the youngest settlement in the whole of Tibia. Darama, on the
      other hand, is much bigger - in fact, it is large enough to be considered a continent in its own right. In the dry
      eastern part, two rival kingdoms, the caliphate of Caliph Kazan and the empire ruled by the pharaoh of Ankrahmun,
      vie for dominance over this continent. Their capitals, the thriving oasis of Darashia and the sombre temple city
      of Ankrahmun, are Darama's commercial and cultural centres. Moreover, there is the huge jungle of Tiquanda in the
      western part of Darama. The Thaian Kingdom has founded the colony of Port Hope there.
      <br />
      <br />
      <b>4.2.3 Cities</b>
      <br />
      <br />
      <b>a) Traveller's Guide to the Cities of Tibia</b>
      <br />
      There are numerous settlements on Tibia, but, of course, not all of them are large enough to be considered cities.
      At the moment there are seven settlements who have the chartered right to call themselves cities:
      <ul>
        <li>
          <b>Ab'dendriel</b>
          <br />
          The majestic elven city of Ab'dendriel is a jewel among Tibian cities. It is almost entirely erected on huge
          trees fostered by the sagacious elven tree singers, and elven magic keeps it lush and verdant despite its
          position far to the north of the main continent. However, even this seemingly peaceful centre of music and art
          has a dark side to it, because deep underneath the lovely forests troll slaves toil night and day to mine the
          precious metals their elven masters need to create their amazing jewellery.
        </li>
        <li>
          <b>Ankrahmun</b>
          <br />
          The sombre city of Ankrahmun is unlike any other place in Tibia. Its inhabitants do not dwell in common houses
          but rather in age-worn pyramids, and even though Ankrahmun is far from lifeless, it is essentially a city of
          death. Its inhabitants are worshippers of an obscure cult that celebrates undeath as the key to immortality,
          and the pharaoh himself, who is both the high priest and the primary deity of his cult, has long since entered
          the gruesome state of life in death. His oppressive presence is almost physically tangible all over the city,
          and the eerie silence emanating from his palace fills the heart of the amazed visitor with apprehension.
          <br />
          Today, the majority of Ankrahmun's citizens are still living people, but being true believers of their creed
          they strive to follow their spiritual leader into the twilight state of undeath. To further encourage his
          followers' zeal the pharaoh has filled the lower levels of his own palace with skeletons and other walking
          dead so that those who still cling to their mortal forms may see the glory of undeath for themselves.
          <br />
          <img src="/manual/ankrahmun.gif" width="600" height="200" />
          <br />
        </li>
        <li>
          <b>Carlin</b>
          <br />
          Carlin is the undisputed ruler of Tibia's north-western region. This venerable city has experienced a past of
          continuous strife and conflict, both because of civil unrest and because of the endless rivalry to Thais.
          However, ever since a handful of brave women took over the leadership of the city Carlin has been a haven of
          peace. Today, the only threat to public security comes from underground: Carlin has a major sanitary problem,
          for its sewers are teeming with rats, bugs and other, even less pleasant vermin.
        </li>
        <li>
          <b>Darashia</b>
          <br />
          The city of Darashia is the capital of Caliph Kazzan's desert kingdom. Erected in the middle of a huge stretch
          of barren wasteland, Darashia is a jewel of the desert that owes its existence to an abundance of fresh water
          springs. Its citizens are friendly and hospitable, and its many artisans produce items of great beauty that
          are sought all over Tibia. One product, however, is prized more than anything else: The delicious honey
          produced by the ferocious sand wasps. The Caliph effectively controls the trade of the "liquid gold", as it is
          often called, and he relies on it more than on anything else to keep his coffers filled.
          <br />
          Unfortunately, the Caliph's loyal subjects see the matter in a slightly different light. Sand wasps are
          aggressive predators who do not even shy away from attacking humans, and their nests are dangerously close to
          the city. However, since the Caliph is not too keen to remove his most important source of income the
          Darashians have grudgingly accepted to live under the daily threat of being attacked by poisonous 2-foot
          killer insects.
        </li>
        <li>
          <b>Edron</b>
          <br />
          Despite its many impressive buildings Edron is a young city. In fact, Edron is Thais's most recent colony, and
          it appears the Thaians have once more founded a city which will eventually rival their own city's fame and
          wealth. Unfortunately, however, it has turned out that the location on which Edron was erected has not been
          chosen wisely, because the area is infested with vicious rotworms. Also, it is said that the trolls, goblins
          and orcs living to the west of Edron have dug secret underground tunnels which they are planning to use for an
          attack on the city.
        </li>
        <li>
          <b>Kazordoon</b>
          <br />
          Situated in a huge extinct volcano, the city of Kazordoon is the pride of the dwarven race, a miracle of
          science and of craftsmanship. Many generations of dwarven artisans and engineers have contributed to the
          creation of its beautiful halls and passageways, and countless mine workers have spent their lives extracting
          ore in order to help amassing the fabulous wealth that is hidden deep in the dwarven city. However, for all
          their pride and their wariness of outsiders dwarves are in fact a sociable people, and anybody who is ready to
          spend a gold piece or two is welcome in the mighty city of Kazordoon.
          <br />
          Be careful, though, for not all dwarves are as friendly as the inhabitants of Kazordoon. The mines surrounding
          the city are occupied by renegade dwarf tribes who will attack anybody that is foolish enough to enter their
          realm.
        </li>
        <li>
          <b>Port Hope</b>
          <br />
          The colony of Port Hope is one of the newer acquisitions of the Thaian Kingdom. After Port Hope was founded as
          a small camp on the new continent, it was soon found out that the jungle of Tiquanda was rich on valuable
          resources. Quite soon the tradesmen of Venore stepped in to help the kingdom in this new challenge. Neither
          the climate nor the constant problems could break the spirit of the colonists. People from all over the known
          world gather there now, looking for new opportunities or just the chance to start over. Their combined efforts
          have created a small but thriving community that boldly faces the dangers and problems they encounter in this
          new land. Surrounded by the hostile jungle full of enemies and threats they have never seen before, the town
          is in constant need of valiant protectors and explorers. Hunters and adventurers alike meet here for their
          expeditions into the jungle and share the tales of their latest missions.
          <br />
          <img src="/manual/porthope.gif" width="550" height="294" />
          <br />
        </li>
        <li>
          <b>Thais</b>
          <br />
          Thais is the largest city in Tibia. Due to its long history and to the fact that king Tibianus III resides
          there the Thaians consider their city to be the capital of the realm, even though this has never been
          officially confirmed by the king. The truth is, in fact, that Thais's star has been slowly waning for a long
          time because other, more dynamic cities have managed to take over much of the commerce. However, for all its
          age Thais is still an exemplary city, and even though today many sneer at its anachronistic traditions they
          secretly admire Thais's dignified majesty.
          <br />
          To the east of Thais a troll tribe is threatening the trade with the western cities. It is said Thaian
          aristocrats are keeping the army from rooting it out because troll-hunting is their favourite sport, but then
          that may only be another rumour intended to slander the local nobility.
          <br />
          <img src="/manual/thais.gif" width="540" height="212" />
          <br />
        </li>
        <li>
          <b>Venore</b>
          <br />
          Venore is a bustling merchant city situated deep in the eastern marsh lands. Originally a colony of Thaian
          settlers, its splendour and riches are now rivalling those of its mother city. The Venorian merchants have
          organised themselves in mighty merchant guilds that effectively have taken over the rule of the community.
          Their commercial skill is legendary, but so is their proverbial stinginess - the tight-fisted Venorian
          merchant is a common fun figure. The Venorians' avarice clearly shows in their refusal to finance a military
          expedition that would finally rid Venore of the tiresome trolls living to the south and west of the city.
        </li>
      </ul>
      <b>b) What to Find in Cities</b>
      <br />
      <ul>
        <li>
          <b>Temples</b>
          <br />
          Temples rank among the most important buildings, and they are usually found in some central area of a city.
          Holy places of peace and of introspection, temples are out of bounds for petty fighting between characters, so
          player vs. player combat is not possible there. Since temples are sacred places dedicated to the worship of
          the gods it is here that characters that have died are sent to. Please note that priests can cure minor wounds
          if your character is seriously injured. Simply ask them to <i>heal</i> you.
        </li>
        <li>
          <b>Depots</b>
          <br />
          No city can do without a depot. Depots are warehouses where adventurers can safely store their hard-earned
          items. They typically look like large multi-storey buildings filled with many individual booths where
          characters can access their lockers. You can usually find depots in a central city district. Depots are
          protection zones where characters cannot fight each other, so they are popular places that are often crowded.
          <br />
          Characters can store their items in depot boxes. Depots are safe places to store items because every character
          has a personal box nobody else can open. To get access to your character's personal depot box simply place the
          character in front of any free locker and open it. You will now see your character's personal depot box. Also,
          if you have received any parcels or letters lately, they will arrive here. If you are a free account player
          you can safely store up to 1000 items in each city's depot box, while premium players are allowed to store a
          maximum of 2000 items per depot box.
          <br />
          <b>Note:</b> Once a depot box has reached its limit no further items may by placed in it, although it is still
          possible to send parcels to that depot. Any surplus item sent via mail will be safely stored until it is
          removed by the depot box's owner. However, no further items may be placed manually in the box until enough
          items have been removed.
        </li>
        <li>
          <b>Post Offices</b>
          <br />
          Post offices are branches of the Royal Tibian Mail service which are usually found close to depots. Post
          office clerks sell parcels or letters that can be delivered to any depot in Tibia by the Royal Tibian Mail.
          <br />
          Would you like to write to a friend or to send some items to another city? The "Royal Tibia Mail" offers
          reliable service that makes the life of Tibia adventurers so much easier! Simply buy a letter or a fresh
          parcel at your local post office and write the name of the addressee as well as the name of the city you want
          to send it to on the letter or the parcel label. Note that the addressee's name and the city name must be
          entered in the first and second line respectively, so you should hit "Enter" after you have written down the
          addressee's name. Make sure both the name and the city are spelt correctly - a single typing error such as a
          superfluous letter or blank space can be enough for the sending to fail. Also, if it is a parcel you would
          like to send, do not forget to place the label in it.
          <br />
          Once your parcel or letter is ready all you have to do is to place it on one of Tibia's famous blue mail
          boxes. It will be sent off immediately to its destination. However, if you have made a mistake such as writing
          down an invalid address, your parcel or letter will not be sent off. Therefore, if you find your parcel is
          lying on top of the mail box instead of simply vanishing, you should pick it up again and check the address.
        </li>

        <li>
          <b>Shops</b>
          <br />
          Cities are important centres of trade, and you will find there are many different items for sale in the
          various shops. Of course, weapons and armour shops are most popular with adventurers, but there are many more:
          From flowers to furniture, from food to footballs you will find there are few items your character won't be
          able to buy in Tibia's cities - just make sure to find the shop you are looking for. You will usually be able
          to find out what a shop specialises in by looking at the items that are on display behind the shop counter, or
          by reading a shop board. Also, shopkeepers will be happy to tell you about their wares if you ask them what
          they have on <i>offer</i>. To trade items simply tell them you would like to <i>buy</i> or <i>sell</i>
          an item. A little tip in advance: You should make sure to compare prices, because they can vary from city to
          city. Thus, if you would like to sell some hard-earned loot, you should sell it where you can earn most!
          <br />
          A note on furniture shops: Very large pieces of furniture that would not fit into your backpack will be sold
          in handy construction kids. You can carry them in your backpack and unwrap them safely by right-clicking on
          the package in all rentable houses. Once they have been unpacked, you can move them around but you cannot put
          them back in your backpack.
          <br />
          Please note that there are also shops that are actually run by other players! If you would like to open up a
          business yourself, please see the manual section on houses for details.
        </li>
        <li>
          <b>Magic Shops</b>
          <br />
          Another favourite with Tibian adventurers, magic shops provide adepts of magic with everything they need to
          carry out their trade. From magical equipment such as spellbooks or all kinds of runes including blank runes
          to potions and spells, you can get it all at your local magic shop. Needless to say, however, you must have
          the right vocation to buy spells there - fighter classes such as knights and paladins cannot buy spells in
          magic shops. Instead, they must turn to special NPCs who are masters of their vocations. Just like in regular
          magic shops, you must ask these NPCs for the <i>spells</i> they have on offer.
          <br />A word on potions: Magical liquids such as red life fluids or pink mana fluids come in flasks. If the
          fluids are used, empty flasks remain. You should not throw these empty flasks away! Each flasks has a deposit
          of five gold pieces on it, so you should return empty flasks to any magic shop and reclaim your money - this
          way you can make money and protect the Tibian environment at the same time! To sell your empty flasks simply
          ask the shopkeeper for your
          <i>deposit</i>.
        </li>
        <li>
          <b>Banks</b>
          <br />
          Thanks to its brave adventurers, the Tibian economy is running smoothly and the various banks profit greatly
          from the incessant struggle against the evil hordes. However, banks also offer an important service to Tibian
          adventurers: They change gold into platinum and platinum into crystal. Platinum and crystal coins represent
          gradations of gold coins, the standard Tibia monetary unit, with 1 platinum coin representing 100 gold coins
          and 1 crystal coin representing 100 platinum coins, i.e. 10000 gold coins! You will come to appreciate this
          service a lot once you have become a successful adventurer. After all, it is so much more convenient to have
          one's pouch filled a few platinum pieces rather than with cumbersome piles of gold!
          <br />
          To change gold into platinum simply say
          <i>hi</i> or <i>hello</i> to a bank assistant and ask to <i>change gold</i> for you. On the other hand, if you
          ask to
          <i>change platinum</i>
          for you, the bank assistant will ask you if you would like to change your money into gold or into crystal.
          Also, the bank assistant can change crystal into platinum. Note that because of the massive difference in
          value it is not possible to directly change gold coins into crystal coins or vice versa.
        </li>
        <li>
          <b>Portals of Citizenship</b>
          <br />
          All characters except for those who are on Rookgaard must be citizens of one of Tibia's cities. They can
          select their future home city when leaving Rookgaard, and the Oracle or the Gatekeeper will teleport them to
          the selected city after they have finished choosing their destiny. Once characters are citizens they are
          teleported back to the temple of their home city whenever they die.
          <br />
          Unlike vocations, a character's citizenship can be changed afterwards. To select a new home city for your
          character you must use a portal of citizenship. These portals can be found in every city - they look like
          magic force fields that are shimmering with blue magic. To re-register characters to a different city simply
          move them into the portal of citizenship of the target city. It is possible to look up home cities of
          characters by looking at their character information page.
        </li>
        <li>
          <b>Houses</b>
          <br />
          Finally, just like in real life the majority of buildings in cities are private houses that are rented by
          players. Characters can only enter private houses if they have been invited or if they are actually the
          owners. For details please see the manual section on houses.
        </li>
      </ul>
      <b>4.2.4 Exploring the World</b>
      <br />
      Travelling the world of Tibia is part of the fun. However, adventurers need to know some basic things before they
      can start out to explore the world they are living in. This section will help you to understand the world of
      Tibia.
      <br />
      A note in advance: Before exploring a new area or dungeon it is a good idea to ask a veteran player what to
      expect. Remember you could get lost or run into the arms of some unfriendly creature!
      <br />
      <br />
      <b>a) Wilderness</b>
      <br />
      There are many settlements, but for the most part they are nothing but isolated outposts of civilisation in a land
      that otherwise is wild and unforgiving. The wilderness is full of dangers, and even though there are some
      well-kept roads travelling is always risky. The following is a list of things you may encounter on your travels.
      <ul>
        <li>
          <b>Blueberry Bushes:</b> The popular Tibia blueberries grow on these bushes. To pick berries simply
          right-click on the bush and select "Use".
        </li>
        <li>
          <b>Grass:</b> While grass is not as well suited for travelling as roads are, your character will be able to
          move reasonably fast on it.
        </li>
        <li>
          <b>Jungle Grass:</b> Highly grown jungle grass is impassable. However, adventurers can remove it using
          machetes.
        </li>
        <li>
          <b>Loose Stone Piles:</b> You should watch out for loose stone piles, since they are usually reliable
          indicators that there is a hidden entrance to a dungeon. You can open the entrance by using a shovel on the
          stone pile.
        </li>
        <li>
          <b>Roads:</b> Roads are built for travelling, and on no ground will your character move faster than on a road.
        </li>
        <li>
          <b>Sand:</b> Not surprisingly, sand is quite common in desert areas. It is fairly good to travel on.
        </li>
        <li>
          <b>Snow:</b> While your characters are quite immune against its cold, snow will slow them down considerably.
        </li>
        <li>
          <b>Swamp:</b> The eastern regions of Tibia are full of treacherous swamps. Swamps cannot be entered, so you
          will have to find a way around them.
        </li>
        <li>
          <b>Trees:</b> A common sight in Tibia. It is not possible to cut down trees.
        </li>
        <li>
          <b>Water:</b> It is not possible to move on these fields, so to cross rivers or other bodies of water you will
          have to find a bridge or perhaps a ferry. Be careful when handling items close to water, because any items
          thrown into it are lost forever. It is possible to fish in water fields by using a fishing rod onto them.
          However, note that fishing may not work with certain water fields, or if a fish has recently been caught in
          the field you are fishing in.
        </li>
        <li>
          <b>Wheat:</b> Wheat is cultivated in many areas on Tibia. Once it is ripe it can be harvested by using a
          scythe. A bunch of wheat can be used to bake the delicious Tibia bread.
        </li>
      </ul>
      <b>b) Dungeons</b>
      <br />
      The world of Tibia is ancient, and many different civilisations have left their mark on it. There are mysterious
      ruins and long-forgotten dungeons everywhere, and these remnants of past glory are now ideal hideouts for the many
      dangerous creatures that roam the land. Dungeons come in many forms, but there are one or two things players
      should know. Pay attention to the ground you are moving on, because dungeon floors are often muddy, and your
      character will be much slower than usual when moving on such ground. Also, note that dungeon floors can conceal
      secret holes. Use a pick axe on the floor to find out if there are secret openings. To go down a hole move your
      character onto it. However, note that you need a rope if you wish to climb up a hole. Place your character on the
      floor next to a rope spot and use a rope on it. Note that it is also possible to rope up other characters or
      creatures from above if they are placed below a hole.
      <br />
      <br />
      <b>c) Transportation</b>
      <br />
      Even though the Tibian oceans are notoriously difficult to sail there are some fearless captains who brave the
      treacherous waters. These weather-beaten men of the sea will take you to almost any place for a hefty fee,
      provided you are a premium player. Also, there is also a flying carpet service which is famous for its convenient
      travels and the unrivalled speed of transportation. Unfortunately, though, this service is only available in very
      few places. Finally, there are rumours of an underground connection between the dwarven city of Kazordoon and the
      small island of Cormaya. It is said this service relies on mysterious dwarven technology, but as of yet, no hard
      evidence has been found that could prove the truth of these reports.
      <br />
      <br />
      <br />
      <br />
      <h3>4.3 Combat</h3>
      Tibia is a world threatened by dark forces, and its brave inhabitants fight an endless battle to hold off the evil
      hordes that have sworn to destroy all civilised life. Adventurers are in the front line of this epic struggle, and
      they are actually making a good living from it - if they survive, that is. Doing battle is part of their everyday
      business, and every adventurer who is worth his salt must learn the trade of war.
      <br />
      The following section is designed as an expansion of the basic introduction into combat provided in the section on
      basic controls. Here you will find additional information on how combat works as well as some useful strategy
      hints. Moreover, the logout block is explained here, as are regulations concerning combat against other players.
      The final section covers the party mode which allows characters to form adventuring parties.
      <br />
      <br />
      <b>4.3.1 How Combat Works</b>
      <br />
      <br />
      <b>a) Combat Rounds</b>
      <br />
      Characters or creatures who are attacking will try to hit designated opponents whenever they come into range,
      using whatever weapon they are currently wielding. Attacks take place at regular intervals, i.e. once characters
      or creatures have started striking at an opponent they will go on striking steadily. Only one target can be
      attacked at a time. Attacked characters or creatures always get a chance to defend themselves against attacks even
      if they do not retaliate. Note, however, that no more than two attacks can be blocked at a time.
      <br />
      The outcome of a fight is determined by a number of factors including the attacker's and defender's skills,
      equipment, set combat mode, and also a random factor. Each time the attacker lashes out at the defender his attack
      value is calculated and compared to his opponent's defence value. If the attack value exceeds the defence value, a
      hit has been scored, and the damage done is displayed as a little number on top of the injured character's or
      creature's head. On the other hand, a little puff of smoke is displayed if an attack has been blocked successfully
      by the defender, while flashing sparks indicate that the defender's armour has protected him against a blow.
      <br />
      <br />
      <b>b) Skills, Equipment and Combat Modes</b>
      <br />
      As has been said before a character's performance in combat is determined by three factors: his skills, equipment
      and the combat mode he is set to. The skill that is used to calculate a character's attack value is determined by
      the weapon that is currently wielded. Note that if no weapon is equipped at all, the fist fighting skill is used.
      <br />
      Items determine a character's performance in combat in accordance to their nature. For instance, armour such as
      legs or helmets will reduce the damage caused by successful hits, while the bonus yielded by shields is used to
      calculate a character's chance to block attacks. Weapons are generally characterised by two values, since they
      influence both a character's attack and defence value.
      <br />
      Finally, the combat mode a character is set to also influences his chance to successfully attack or defend. For
      instance, characters that are set to "Offensive Fighting" have an increased chance to hit their opponents, while
      their chances to successfully block attacks are reduced. Conversely, characters that are fighting defensively will
      receive a bonus to their defence, while the strength of their attacks is reduced. Please note that changing the
      combat mode takes a bit of time, so you will lose one attack whenever you do so.
      <br />
      <br />
      <b>c) Distance Fighting</b>
      <br />
      Distance attacks are handled similar to melee attacks. Characters or creatures who fight from a distance will fire
      or hurl projectiles at regular intervals. Note that the actual distance between attackers and their targets has an
      influence on how successful distance attacks are - the larger the distance the smaller the chance to hit. Note,
      however, that distance fighters will also receive a to-hit penalty if they are firing at an opponent who is
      standing right next to them! What's more, distance weapons such as bows and crossbows are ill-suited for melee
      combat, since they require the use of both hands. Because of this distance fighters are well advised to maintain a
      moderate distance between themselves and their enemies. However, if your character is a skilled distance fighter
      and you cannot avoid a melee situation, you should equip him with a throwing weapon and a shield rather than a bow
      or a crossbow. Please note that thrown weapons vanish sometimes when used.
      <br />
      <br />
      <b>d) Protection Zones</b>
      <br />
      Unless characters play on non-PvP worlds, they are free to attack each other once they have left Rookgaard.
      However, even on worlds with regular or enforced PvP combat there are areas where characters are not allowed to
      fight each other. These areas are called "protection zones". Players standing in protection zones are perfectly
      safe from any offensive action, even if others are trying to attack them from outside. Conversely, players cannot
      perform any offensive action inside protection zones. Note that the regeneration of hit points and mana is also
      disabled in protection zones, although food will be consumed normally.
      <br />
      Protection zones are usually found in temples, depots, ships or in private houses. Also the pyramids in Ankrahmun
      are protection zones that cannot be entered if you have just attacked another character. Players can seek shelter
      in protection zones anytime, provided they are not affected by a protection zone block, and they can stay there
      for as long as they like. Note that although characters can be pushed around normally within protection zones it
      is impossible to push them out of protection zones.
      <br />
      <br />
      <b>4.3.2 Combat Strategy Guide</b>
      <br />
      This is a little compilation of useful combat tips. Read them carefully. If you observe them, they may save your
      character's life one day!
      <br />
      <ul>
        <li>
          <b>Know Your Enemy</b>
          <br />
          Ever since Sun-Tzu wrote his famous treatise on the art of war, generals have known how important it is to
          study the enemy you are fighting against. Things are by no means different in Tibia: Creatures have different
          strengths and weaknesses, and you should know them all. Make investigations. Study them. Remember you can
          check out our list of all creatures in the library section or ask veteran players. And most of all, be wary
          whenever you meet a new creature. If you carelessly bump into a new enemy, you may quickly learn its strengths
          the hard way!
        </li>
        <li>
          <b>Know Your Surroundings</b>
          <br />
          Knowing the terrain your character is moving in is also important. You should always have a general idea of
          your surroundings so that you know which direction to run if your character is in trouble. After all, there is
          no point in fleeing from a drooling giant spider only to find yourself looking into the mocking faces of a
          couple of bloodthirsty hunters!
          <br />
          Be careful when exploring, but explore as much as you can. Eventually you will learn to use your knowledge of
          the land against your enemies.
        </li>
        <li>
          <b>Cooperate with Other Players</b>
          <br />
          It is a mistake to believe that two characters are twice as strong as one - in fact, two characters that are
          well attuned to each other are much, much stronger than solitary fighters! In a team, characters can fully
          exploit their individual strengths while relying on others to make up for their weaknesses. An experienced
          group of characters that concentrates its firepower on one enemy can bring down the toughest creature in no
          time!
          <br />
          Moreover, keep in mind that characters can also cooperate in many different ways: For example, they can rope
          each other through holes, they can carry equipment for each other etc. Just think how glad you will be if your
          character dies and a friend of yours is ready to save your lost equipment!
        </li>
        <li>
          <b>Avoid Getting Cornered</b>
          <br />
          Characters that are surrounded by enemies often have a hard time to escape. Remember even weak creatures can
          hand out hefty damage if there are too many of them. Remember that no matter how high your character's
          shielding skill, you will not be able to block more than two opponents at a time! Always make sure your
          characters are placed in positions where no more than two enemies can attack them at a time - ideally you
          should also have an escape route in case something goes dreadfully wrong.
          <br />
          However, if one of your characters ever gets cornered, you can try pushing an opponent away. You have to be
          quick to move out once you have pushed him, though, because your opponent will probably move back into
          position as soon as possible. Also, note that pushing will not work with all creatures.
        </li>
        <li>
          <b>Plan Your Attacks</b>
          <br />
          While charging head-on into battle may have its charms you should keep in mind that the most dangerous enemies
          are those who plan their actions well. You should do just like them - prepare a strategy based on what you
          know about your adversaries and put it into practice in a deliberate, systematic way. Finish off opponents one
          at a time, starting with the most dangerous one. Make sure your first opponent is dead before you start
          attacking another one - remember a heavily wounded enemy can still attack as viciously as a healthy one. More
          importantly, you should always think about your character's safety. You should try to figure out how you can
          protect your character best - perhaps you can find a way to attack enemies from where they cannot even reach
          your character. While this may not be particularly heroic it will definitely help to extend your character's
          life expectancy. Reserve honourable fighting for combat against other characters.
        </li>
        <li>
          <b>Have a Backup Plan Ready</b>
          <br />
          Remember even the most fool-proof of plans can fail, and even if something has worked perfectly for a thousand
          times it may still fail spectacularly next time you are trying it out. You should be prepared for that -
          always expect the unexpected! Have some healing magic available at all time, and be prepared to get your
          characters out alive from whatever situation they may possibly encounter. Most of all, don't hesitate to run
          if you find a battle is too tough. Remember those who run away from a lost battle will live to fight another
          day!
        </li>
      </ul>
      <b>4.3.3 Player Killing</b>
      <br />
      As a rule, killing other characters is legal on all game worlds of Tibia where player vs. player combat (PvP) is
      enabled. However, there are differences. On PvP-enforced game worlds are no limitations on player killing at all,
      while there are certain restrictions on worlds with regular PvP combat to make sure player killing does not get
      out of control.
      <br />
      <br />
      <b>a) Player Killing on PvP-Enforced Worlds</b>
      <br />
      On PvP-enforced worlds there are no restrictions whatsoever on player killing. Quite the contrary, attacking and
      killing other characters is actually encouraged, and those who manage to kill other characters are even rewarded
      with experience points! However, the actual amount of experience can vary greatly. The lower the level of player
      killers compared to the level of their victims the more experience points they will gain. If the victim is a
      member of the killer's party, or if the killer exceeds his victim's level by ten percent or more, no experience
      points will be awarded at all.
      <br />
      In addition to this regulation further elements of the game have been modified on PvP-enforced worlds to allow for
      equal odds between all vocations. For instance, the delay to cast aggressive spells is reduced to one second, and
      the spell "cancel invisibility" has a chance to destroy worn stealth rings. Also, blessings or amulets of loss are
      generally not available on any world where PvP is enforced.
      <br />
      <br />
      <b>b) Player Killing on Worlds with Regular PvP Combat</b>
      <br />
      On worlds where a limited amount of player killing is allowed kills are divided into two categories, in "good"
      kills, i.e. kills of marked characters, and in "bad" kills, i.e. kills of unmarked characters. A character may
      make any number of "good" kills, but it is not allowed to make too many "bad" kills within certain limited periods
      of time. Please note that killing other characters does never yield experience points on worlds where a moderate
      amount of player killing is allowed. For details on how characters are marked please see the next section.
      <br />
      Please note that you are also considered to be the killer of a character if you have inflicted the largest amount
      of damage on him within the last 60 seconds, even though it was not actually you who finished him off. If this is
      the case both your character and the character that struck the final blow will be recorded as "good" or "bad"
      killers.
      <br />
      <br />
      <b>c) Skull Marks</b>
      <br />
      Skull marks appear only on game worlds with regular PvP combat. A character that has not recently been involved in
      any violence is unmarked. You will not be able to attack such a character if your combat controls are set to
      secure mode.
      <br />
      If unmarked characters are attacked or hurt by another player, the attacker will receive a mark which will be
      visible to all other players as a little skull underneath the name. Once a character is marked all other players
      are free to attack this character! A character that attacks or kills a marked character will not receive a skull
      mark himself. Therefore you can kill as many marked characters as you want to without any negative consequences
      for you. However, if you plan to attack a marked character, you should still be careful, because, like everybody
      else, marked characters have the right to defend themselves. Whenever a skull mark is about to vanish it will
      flash for about 5 seconds. During this time the skull mark is still valid but you know that it will be gone in a
      moment. Please note that not all skull marks indicate that a character is truly a player killer, even though any
      marked character may be attacked. The skulls come in four different colours:
      <br />
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="20" height="0" />
            </td>
            <td>
              <table width="100%" cellSpacing="0" cellPadding="3">
                <tbody>
                  <tr>
                    <td>
                      <img src="/manual/skull_white.gif" width="11" height="11" />
                      <b>White Skulls</b>
                      <br />A character marked with a white skull has recently attacked or killed an unmarked character.
                      All players can see this mark. It will stay as long as the logout block is active. If the
                      character performs further offensive actions while he is marked with a white skull the duration of
                      the mark will be extended, as will the duration of the logout block and of the connected
                      protection zone block. Still, white skulls are temporary marks which indicate that the marked
                      character has been aggressive recently.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="/manual/skull_red.gif" width="11" height="11" />
                      <b>Red Skulls</b>
                      <br />
                      Characters that kill several unmarked characters within some periods of time will be marked by a
                      red skull and have to face serious sanctions for their player killing. This happens when a
                      character kills
                      <table width="100%" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td>
                              <img src="/manual/blank.gif" width="20" height="0" />
                              <img src="/manual/bullet.gif" width="12" height="15" />
                              three or more unmarked characters in one day (24 hours),
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img src="/manual/blank.gif" width="20" height="0" />
                              <img src="/manual/bullet.gif" width="12" height="15" />
                              five or more unmarked characters in one week (7 days) or
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img src="/manual/blank.gif" width="20" height="0" />
                              <img src="/manual/bullet.gif" width="12" height="15" />
                              ten or more unmarked characters in one month (30 days).
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      As soon as a character reaches one of these three limits the character will be marked with a red
                      skull for 30 days. Note that every time one of these limits is reached again the red skull mark is
                      reset to 30 days. Also, as an additional sanction a character marked with a red skull will drop
                      all items in case of death, no matter if an "Amulet of Loss" is worn or not! This will encourage
                      players to hunt and to punish the player killer.
                      <br />
                      Excessive killing of characters that are not marked with a skull is a violation of the Tibia
                      Rules. Therefore players who reach twice the number of unjustified kills that are needed to get a
                      red skull will be banished automatically. Remember that too many banishments will result in the
                      deletion of an account.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="/manual/skull_yellow.gif" width="11" height="11" />
                      <b>Yellow Skulls</b>
                      <br />
                      This skull is somewhat special because it is not visible to all players on the screen. You will
                      only see it if your own character is also marked with a skull. A yellow skull underneath a name
                      indicates that this character has attacked or damaged your character first. This happens because
                      you have the right to defend yourself. If you kill a character with a yellow skull, the kill does
                      not count as a "bad" kill as any other kill of a marked character.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="/manual/skull_green.gif" width="11" height="11" />
                      <b>Green Skulls</b>
                      <br />
                      Green skulls are another special case. A player marked with a green skull is also a member of the
                      party you belong to. Like any other marked character you can attack or kill party members without
                      any negative consequences. Parties are mainly designed for training and hunting in groups or to
                      challenge another player to a duel. Please see the manual section for more information about the
                      party mode.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <img src="/manual/blank.gif" width="20" height="0" />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>d) Good Kills vs. Bad Kills</b>
      <br />
      Some words on "justice" on worlds where a moderate amount of player killing is allowed. Of course, every automatic
      system has weaknesses. Players may try to abuse the protection offered by this system to provoke you in any
      conceivable way. Still, you should always think twice before attacking an unmarked character, no matter what he
      has done. You may have good excuses for attacking the character, but your fellow players may not accept them. It
      is not the goal of the marking rules to ensure justice but to handle most typical conflicts arising in the game in
      a fair and neutral way.
      <br />
      All your kills will be automatically evaluated and logged. As has been said before, you are free to kill as many
      marked characters as you like. This is because your victims have either been aggressive towards unmarked
      characters (white and red skull), attacked you first (yellow skull) or because they have joined the same party as
      you out of their own free will (green skull).
      <br />
      Things are different, however, with "bad" kills, i.e. kills of unmarked characters. You may only kill a limited
      number of unmarked characters within some limited periods of time. The marking rules on player killing are
      designed to penalise aggressive players. We feel it is never a good thing if you attack a character that has no
      mark. Therefore you have to bear the disadvantages of being marked as an aggressive player. If this does not stop
      you from behaving aggressively, the red skull mark comes along with the additional punishment of full item loss.
      Moreover, if you exceed one or more of the aforementioned kill limits, further killing of unmarked characters will
      be considered a violation of the Tibia Rules and result in an automatic account banishment.
      <br />
      <br />
      <b>4.3.4 Logout Block</b>
      <img src="/manual/condition_logoutblock.gif" width="11" height="11" />
      <br />
      Normally, characters can leave the game anytime. However, if characters are currently fighting, or if they have
      recently been involved in some sort of violence, they will be temporarily barred from logging out. You can tell a
      character cannot log out by the little icon displayed in the condition indicator field underneath the inventory.
      As long as this icon is displayed you cannot log out, although you are of course free to shut down the client or
      even to turn off the computer. However, this is not a good idea since your characters will stay in the game if you
      do so, and they will be extremely vulnerable! For this reason it is a good idea to make sure the logout block icon
      has disappeared whenever you decide to leave Tibia. Characters will receive a logout block whenever they
      <ul>
        <li>attack other characters or creatures,</li>
        <li>get attacked by other characters or creatures,</li>
        <li>damage other characters or creatures,</li>
        <li>get damaged by any means,</li>
        <li>cast offensive spells or use offensive runes.</li>
      </ul>
      A logout block is generally lifted 60 seconds after its cause has disappeared. In other words, the duration of the
      logout block is set to 60 seconds each time one of the above conditions is met.
      <br />
      A special case of the logout block applies whenever characters engage other characters in PvP combat. As with
      normal logout blocks, characters affected by so-called protection zone blocks are not allowed to log out. However,
      in addition to the regular logout block, these characters are also barred from entering protection zones such as
      temples, depots or private houses to make sure all attacked characters get a chance to retaliate. Protection zone
      blocks last as long as logout blocks do, i.e. characters that have attacked others will be able to enter
      protection zones if at least 60 seconds have passed since the last time have been involved in violence.
      <br />
      <b>Note:</b> Casting certain offensive spells will also cause characters to receive protection zone blocks even if
      the spells are not used to attack other characters at all! Spells of this kind include all field runes as well as
      the "Summon Creature" spell. Protection zone blocks imposed because of such spells last for 60 seconds due to the
      connected logout block, provided the casting character does not get involved in combat in the meantime.
      <br />
      Things are more serious if a character has actually killed another character. Player killers may not log out or
      enter protection zones for a full 15 minutes! What's more, if the killer is involved in violence during the last
      minute of this period, his logout block will be extended as usual, so player killers can be barred from logging
      out for a long time.
      <br />
      <br />
      <b>4.3.5 Party Mode</b>
      <br />
      Players can form groups called "parties". Parties are useful if you would like to hunt, train or have a duel with
      a friend, because the party mode allows its members to attack or kill each other without negative consequences.
      For this reason characters within the same party do not only have a shield mark but also a skull mark beneath
      their names on game worlds with regular PvP combat. The skull mark is green if the character has no other skull
      mark, and it can only be seen by members of same party. Keep in mind that you are running a risk whenever you join
      a party, because any party member can attack and even kill you without receiving a skull mark.
      <br />
      <br />
      <b>a) How to Form a Party</b>
      <br />
      If you want to form a party, you have to invite another character. Simply right-click on the other character and
      select "Invite to Party". This character can accept your invitation by right-clicking your character and selecting
      "Join XXX's Party". The player who has invited the others automatically becomes the leader of the party. Any
      further characters that join will become members of the party.
      <br />
      The party-status of a member is indicated by a small shield mark beneath the character's name:
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/blank.gif" width="20" height="0" />
            </td>
            <td width="100%">
              <img src="/manual/shield_yellow.gif" width="11" height="11" /> leader of your party
              <br />
              <img src="/manual/shield_blue.gif" width="11" height="11" /> member of your party
              <br />
              <img src="/manual/shield_whiteyellow.gif" width="11" height="11" /> leader of the party to which you are
              invited
              <br />
              <img src="/manual/shield_whiteblue.gif" width="11" height="11" />
              character that has been invited to your party, but has not joined yet
            </td>
            <td>
              <img src="/manual/blank.gif" width="20" height="0" />
            </td>
          </tr>
        </tbody>
      </table>
      Please note that each character can only be a member of one party at a time. Moreover, only the party leader can
      invite further characters. Additionally, a leader can always pass the leadership on to any other member of the
      party by right-clicking on a member and by selecting "Pass Leadership".
      <br />
      <br />
      <b>b) How to Leave a Party</b>
      <br />
      You can leave a party by right-clicking on yourself and selecting "Leave Party". Please note that you cannot leave
      a party if you have a logout block. If you leave a party, your character's shield mark vanishes immediately. Also,
      the character's skull mark will blink noticeably for 5 seconds to warn other players that you have left the party,
      and that further attacks may be judged as unjustified violence. You will be dismissed from a party if you log out
      or you get disconnected. If the leader leaves the party or gets disconnected, the leadership will automatically be
      forwarded to the first invited member. If this character decides to leave the party, the second character that was
      invited will become leader and so on.
      <br />
      <br />
      <br />
      <br />
      <h3>4.4 Magic</h3>
      Tibia, as you know, is a land of magic. There are many powerful spells to learn and master, and you will soon find
      out that magic is one of those things you just can't do without, even if you are a knight. On a basic level spells
      can provide you with food, ammunition or light, while later on you will learn how to heal yourself or to fry your
      enemies with deadly bolts of magical energy. For a comprehensive spell list please see the magic section of our
      library. Even if your character is a knight, you will eventually start using magic regularly.
      <br />
      <br />
      <b>4.4.1 How to Learn Spells</b>
      <br />
      When you start out on your travels on the island of Rookgard, you will find that you are unable to cast any spell.
      However, once you have made it to the main continent your character should start using magic as soon as possible.
      You won't be able to start casting right away, though - you must learn a spell before you can cast it!
      <br />
      Spells are taught by various NPCs - against a hefty fee, of course! Unfortunately, it is not possible to give an
      extensive list of all NPCs who can teach spells to characters, because there are too many of them. Magic shops are
      good places to start looking for such inhabitants, but you will not always find the spell you are looking for
      there, especially if you are a knight or a paladin. If you don't know where to find the local magic spell dealer
      for your vocation, you should ask some experienced players - preferably those who are of your own vocation.
      <br />
      To buy spells greet the NPC, then ask him for
      <i>spells</i>. He will ask you for the magic level of the spell you would like to buy. Since this is probably the
      first spell you are buying you should enter "0". The spell dealer will then give you a choice of the spells you
      can buy for this spell level. Enter the name of the spell you would like to buy and confirm the deal. Provided you
      have the required cash you will now be able to cast the spell.
      <br />
      A word of advice: If you can afford to do so, you should scrape your hard earned gold pieces together and buy a
      spell book. Spell books can be bought in any of the quaint magic shops within the major cities of Tibia, or you
      may be able to buy them from your fellow travellers. Spell books are helpful, especially when you are just setting
      out. They will record all your spells, and although later on you will become so good at casting spells that you
      won't need to look them up every time, starting off with a spellbook is a good idea.
      <br />
      <br />
      <b>4.4.2 Casting Spells</b>
      <br />
      Once you have bought some spells you are ready to start using magic! Spells are cast simply by saying the magic
      words and hitting the Return key. For example, if you would like to cast the light spell, you must enter the
      formula "utevo lux". Once you have hit the Return key, three things will happen: Firstly, you will feel a funny
      feeling running from your toes up, and little blue stars will appear around your body. Secondly, you will be able
      to see a lot better. The area around you will be as bright as if it were day or as if you were carrying a torch.
      Finally, your mana will go down. Mana is the energy you need for casting spells. If your mana is exhausted, you
      will have to wait until you have enough mana to cast another spell. You can check the level of your mana by
      looking at the blue status bar or by looking at your character's skills menu. If you do not have enough mana, or
      if you try to cast a spell which you have not yet learnt, then all that will happen is that you will see a puff of
      smoke and get a message "Sorry, you do not have enough mana" or "You must learn this spell first".
      <br />
      Also, note that the speed with which spells can be cast is limited: If you are trying to cast a spell too quickly,
      you will receive a message that your character is exhausted. Simply wait a second before casting another spell to
      prevent this from happening.
      <br />
      Later, when your spell book is bursting at the seams with spells, you might like to make it easier and quicker to
      cast the spells you use regularly. A good way to do this is to assign hotkeys to spells. Whenever you press a
      hotkey to which a spell is assigned the spell will automatically appear in the entry line of your console, and you
      can cast it without losing any time.
      <br />
      <br />
      <b>4.4.3 Magic Level</b>
      <br />
      In order to get better at casting and to be able to use the more powerful spells, you need to practice, practice,
      practice! Remember the magic level is a skill that needs to be trained in order to improve. Of course, some
      vocations are simply more adept at casting magic than others, and while a druid or a sorcerer will advance
      quickly, a paladin takes longer to advance, and the knights, whose strength is in their sword arm rather than in
      their affinity with magic advance at a painfully slow pace. However, all vocations need to train their magic level
      quickly, because it is important in a number of ways:
      <ul>
        <li>
          The magic level determines which spells characters can cast - the higher their magic level the better the
          spell. However, the mana cost of a spell is also a limiting factor. There is little use for a level 9 sorcerer
          to train his magic level to 25 in order to cast the awesome "Sudden Death" spell, because this spell requires
          220 mana - much more than a puny level 9 sorcerer can muster!
        </li>
        <li>
          Characters need to have a minimum magic level in order to use runes, so this skill level is also important for
          paladins and knights. For example, characters can only use the powerful "Ultimate Healing" runes once they
          have reached magic level 4.
        </li>
        <li>
          Finally, magic levels are important because they directly influence the power of spells. You will find the
          damage your character does with, say, a heavy magic missile increases both with his magic level and his actual
          level, so a spellcaster with magic level 34 and level 25 will cause more damage than a spellcaster with magic
          level 30 and level 25, as he has had more practice and can control runes more effectively.
        </li>
      </ul>
      <b>4.4.4 Runes</b>
      <br />
      As you get more adept at using magic, you will need to learn how to deal with runes. Runes are little stones which
      absorb magic much like a sponge absorbs water. While the majority of spells work instantly, there is a whole class
      of spells which only work with these stones. The magic of the spell is stored in the runes to be released at a
      later date when it is needed. Blank as well as already charged runes can be purchased from specialist magic shops
      in the cities. Blank runes may not be cheap, but for a business-minded druid or sorcerer they provide a very good
      opportunity to make lots of money. Charged runes are even more expensive but you do not depend on mana or soul
      points to obtain them.
      <br />
      To cast a spell upon a blank rune, the rune needs to be held in your hand as you speak the magic words. In this
      way, and with enough mana and soul points, you can cast the same spell on two runes at once - this does not save
      mana or soul points, but it may save time. Once the spell is cast successfully the rune will change colour and
      shape. You can now use the rune like any normal, usable item by right-clicking on it, then selecting "Use with
      ...". Your mouse pointer will turn into crosshairs. Move it on the target in the game window and confirm by
      clicking on the left mouse button. Note that if you are aiming a rune at a creature, you do not have to click on
      the creature itself: Simply find the name of your unfortunate, soon-to-be-toasted enemy in the battle list and
      click on it - you will find this will make targeting runes a lot easier. However, note that it is not possible to
      aim runes at characters this way. If you are using runes in PvP-combat, you will have to aim at the enemy
      character in the game window.
      <br />
      Some runes can only be used once, while others can be used up to five times. Note that the use of runes also
      requires a certain magic level, so initially your character will not be able to use runes. You can find out the
      magic level required to use a rune by looking at it.
      <br />
      <br />
      <b>4.4.5 Using Magic in Combat</b>
      <br />
      Since pure magic users such as druids or sorcerers are weak melee fighters they need to make up for this by using
      powerful magic against their enemies. For this reason they have a large arsenal of offensive spells at their
      disposal. However, mighty spells alone will not save you if you do not know how to use them effectively. The
      following hints should help you to make the most of your spell-casting character:
      <ul>
        <li>
          <b>Aiming</b>
          <br />
          As noted earlier it is fairly easy to aim a spell using the battle list. However, since it is not possible to
          aim at other characters this way this option is not always open to you, so be careful in PvP combat. Keep away
          from your enemy if you can and try using the terrain against him. Perhaps you can manoeuvre your enemy into a
          position where he is an easy target.
        </li>
        <li>
          <b>Area Attacks</b>
          <br />
          From simple missile or strike spells that affect individual fields to the "Great Fireball" or the mighty
          "Ultimate Explosion" which can cover most of the game window, attack spells can vary greatly in the area they
          affect. Needless to say, massive area attacks are easy to aim, but then their area of effect is not always
          easy to predict. If you are unlucky, you may accidentally attack innocent bystanders - which can lead to some
          very nasty consequences! So, if you want to keep out of trouble, you should think twice about casting area
          spells when other characters are near. Also, if you are hunting with friends, you should definitely team up in
          a party to avoid getting a skull mark if you hit one of your companions by accident.
        </li>
        <li>
          <b>Immunities</b>
          <br />
          Spells do not affect all creatures to the same degree. While a "Fireball" spell will work just fine with
          skeletons, a dragon who is hit by a ball of magic flame will be rather unimpressed. Likewise, heavily armoured
          enemies will be better protected against normal attacks such as the "Force Strike" spell. You should do some
          experimenting in order to find out which spell works best against which enemy. A clever choice of spells has
          saved many a sorcerer's life!
        </li>
        <li>
          <b>Spend your Mana Wisely</b>
          <br />
          Wise Tibians ensure that they have enough mana or runes to heal themselves should the worst come to the worst
          - a bit of caution could buy you that extra minute which makes the difference between life and death. If you
          are in trouble, remember that spells such as "Invisibility" or "Magic Shield" are real life savers! Also, if
          you are going on a longer hunting expedition, you are well advised to invest in mana fluids, deliciously
          refreshing pink potions that can be bought from your local magic shop. This will allow your character to
          regain mana points quickly in an emergency.
        </li>
      </ul>
      <br />
      <br />
      <h3>4.5 Quests</h3>
      <b>4.5.1 What are Quests?</b>
      <br />
      Characters are more than mere mortals - they are the brave heroes who protect the land from the incursions of the
      evil hordes! Because of their special position fate has reserved many dangerous adventures for them in which they
      can win both fame and riches. These adventures are called quests. From simple errands to treasure hunts that will
      take you to the end of the world, quests can vary immensely in scope and complexity. However, all missions
      typically have some things in common. First, all characters can embark upon them, provided they meet the necessary
      requirements such as the required minimum level. Secondly, almost all quests can only be done once per character,
      and the corresponding treasures can also be found only once per character. Finally, you need not worry about time
      while doing a quest. You can log out normally anytime and resume your quest whenever you like.
      <br />
      <br />
      <b>4.5.2 How to Get Quests</b>
      <br />
      Of course, quests are popular with players because of the treasures that can be found, and also because of the
      extra fun they add to the game. However, before you set out on a quest you must have actually received it. Take
      the time to talk to all NPCs you meet during your travels - you will find that quite a number of them have some
      quest for you. Just greet them politely and ask them for a<i>quest</i> or a <i>mission</i>. However, it is also
      possible that you stumble on a quest by accident. Keep your eyes open for anything that seems special when
      exploring the land. Make a mental note whenever you encounter a door you cannot open, especially if you receive a
      message that you are not worthy to enter - this indicates that your character's level is not high enough to enter
      here. Simply come back when your character has reached the level that is necessary to open it. On the other hand,
      if you find a locked door, you should try any key you find along the way - with a bit of luck you will eventually
      find the right key.
      <br />
      <br />
      <b>4.5.3 Sample Quest</b>
      <br />
      In the following you will find a very basic sample quest that will give you an idea how quests work. Seymour, the
      head of Rookgaard Academy, is a wizened old man who has taken it upon himself to instruct new heroes. He will send
      any new adventurers on a little mission. Here is what to do in order to get and to solve it:
      <ol>
        <li>
          Talk to Seymour and ask him about a<i>mission</i>. You will find him in the Academy, a tall building north of
          the temple in Rookgaard. Provided your character is level 4 or below, Seymour will order you to kill rats and
          bring their fresh carcasses to him.
        </li>
        <li>
          Now look for some rats and pick up their bodies before they start to decay. The sewers are near, so it should
          not take you long to find one.
        </li>
        <li>
          Bring the dead rats to Seymour and say
          <i>rat</i>. He will thank you and take one carcass in exchange for a reward of two gold pieces! Luckily, he
          will give you the gold for each carcass you bring to him.
        </li>
      </ol>
      Of course, not all quests are as easy as this one. Just talk to Seymour once your character has made level five,
      and he will send you on another mission that takes more than finding the carcass of a rat!
      <br />
      <br />
      <b>4.5.4 A Word on Quest Spoiling</b>
      <br />
      When you are stuck in a quest it will always be tempting to turn to a veteran player for help. However, you should
      not do that even though so-called "quest spoiling" is not officially illegal under the Tibia Rules. After all,
      quests have been included in the game to add flavour and excitement. We feel that players who simply consider
      quests an easy way to get interesting equipment are actually depriving themselves of an important dimension of the
      game, and after all, having fun is what playing Tibia is about! Therefore we recommend you try solving quests on
      your own - after all, think how proud you will feel if you have solved a quest all on your own!
      <br />
      Since we do not approve of quest spoiling, we have instructed our tutors not to answer any questions concerning
      game content such as quests. Therefore you should keep in mind that posting questions concerning quests on the
      help channel is actually considered an abuse of the channel.
      <br />
      <br />
      <br />
      <br />
      <h3>4.6 Houses</h3>
      Common wisdom has it that one's home is one's castle. If this is true in the real world, how much truer is it in
      the rough world of Tibia! After all, adventuring is a tiresome trade, so characters that are lucky enough to
      possess a home of their own soon come to appreciate the cosiness of a bed. What are you waiting for? Rob your
      piggy bank and bid for a house today!
      <br />
      <br />
      <b>4.6.1 Benefits of Houses</b>
      <br />
      Houses are private buildings that can be rented by players. They are mostly found in the cities, but rentable
      buildings can be found anywhere in Tibia. Houses come in three forms: There are normal houses that are designed as
      resting places for single characters or perhaps for small groups of friends, shops in which players can build up a
      prospering business and even guildhalls in which whole guilds can find shelter! Buildings offer a variety of
      advantages to characters:
      <ul>
        <li>
          <b>Protection</b>
          <br />
          Houses work as protection zones, so any character that is standing in the safety of a house is safe from any
          attacker. However, as with all other protection zones characters that are standing in houses do no regenerate
          hit points or mana. Moreover, only invited characters can enter a house, so your belongings are also safe from
          stealing.
        </li>
        <li>
          <b>Improved Recuperation</b>
          <br />
          Most houses have one or more beds in which characters of premium players can sleep while being offline.
          Characters placed in a bed will restore one soul point for every 15 minutes they are sleeping. Moreover, also
          hit points and mana will be restored as long as the food they have eaten before going to bed lasts. However,
          please note that this process will take much longer than if the character actually would be online.
        </li>
        <li>
          <b>Improved Item Storage</b>
          <br />
          Characters can furnish their homes to their taste. Far from being simple decoration, containers such as chests
          or drawers can be used to store items. Also, unlike depot chests they do not have any item limit, so you can
          store infinite numbers of items in your house!
        </li>
        <li>
          <b>Prestige</b>
          <br />
          Let's not beat around the bush: A nice house will also increase your character's prestige with the community.
          After all, houses are in great demand, so if you have that nice four-bed mansion with the cool garden, you can
          be sure many other players will envy you. What's more, you can safely display your hard-earned treasures in
          your house without having to fear that anybody can steal your stuff. You will find that if you want to impress
          others, few things will work better than a nice house!
        </li>
      </ul>
      <b>4.6.2 Getting Houses</b>
      <br />
      So you have made up your mind to find a house for your character? There are two different ways to acquire houses
      in Tibia: You can purchase one at an auction, or you can buy one from another character. Make sure you have saved
      up a bit, because getting a house is often quite costly!
      <br />
      Also, keep in mind that there are restrictions: Only premium players can rent or bid for houses, and they cannot
      rent more than one house per game world. Moreover, no more than three characters from the same account can rent
      houses at a time. If one character is a guild leader, the player can also rent one guildhall, even if he has
      rented three houses already. Needless to say, guildhalls can only be rented by guild leaders. Finally, please note
      that you can only bid on or buy one house at a time.
      <br />
      <br />
      <b>a) House Auctions</b>
      <br />
      Houses that are currently uninhabited are auctioned on the house section of the official website. You will find a
      detailed list of all houses that are available on all worlds there.
      <br />
      If you would like to bid for a house, go to this page, choose your world and your favourite city and set the
      "Status" to "auctioned", then confirm by clicking on "Submit". Unless no houses are available at the moment, you
      will now see a list of the houses that are currently on offer. A click on the "View" button will reveal further
      information on the house, such as its location, the depot which will be debited with the rent, and the name of the
      character that has currently made the highest bid. Also, this is the place to make a bid yourself. Click on the
      button "Make a bid". If you are not logged into your account already, you will be asked to enter your account data
      now. Then a new page will open up where you can enter your bid limit and select one of your characters on the
      corresponding game world. When you have submitted your bid a confirmation page will open where you have to confirm
      it by entering your password. Please keep in mind that bids cannot be withdrawn once they have been confirmed!
      <br />
      An auction for a house starts as soon as a player makes a bid for it. Auctions last for seven days, counting from
      the day the first bid has been made. House auctions work much like other internet auctions. Players who wish to
      bid for a house must enter a bid limit. This is the maximum amount of money they are willing to pay for the house.
      However, it is likely that a player will not immediately bid the full amount of this limit. Instead, the bid
      automat will only bid enough money to outbid all other bidders. Thus, if there is no other player who is bidding
      for the house, your bid will be set to 0 gold pieces, even though you are prepared to pay thousands of gold
      pieces. However, if somebody else makes a bid, the bid automat will try to outbid this player until the limit you
      have entered is reached. The bid automat will never bid more than the amount you have entered as your limit.
      <br />
      <b>Example:</b> You have set your bid limit to 1000 gold. As long as there is no other bidder the bid remains set
      to 0 gold. Now another player appears on the scene who is prepared to bid up to 500 gold. However, as you have set
      a higher or the same limit you will automatically outbid him. You are now bidding 500 gold pieces.
      <br />
      Once you have won an auction the amount you have bid for your house will be debited from the corresponding depot
      along with the first month's rent. You should make sure to have the cash ready, because if you cannot pay, you
      will not get the house, and it will be auctioned again. Worse, you will be excluded from bidding for any house for
      a duration of one month and a notation will be added in your criminal record. If you have spoilt three auctions
      your account will be even banished.
      <br />
      <br />
      <b>b) Buying and Selling Houses</b>
      <br />
      If you do not want to auction for a house, or if no interesting house is currently available, there is another
      option: Perhaps you can buy a house from another player. There is a special trade option which allows players to
      carry out safe transfers of houses to make sure there are no misunderstandings.
      <br />
      A character that owns a house can initiate a transfer on the house section on the Tibia website. The owner must
      view the house, then click on the button "Move out" to get to the house transfer page. Provided the owner is
      logged into the account, a form will be displayed in which the owner can specify the date of the transfer, the
      name of the character to which the house will be transferred, and the amount of money this character has to pay.
      Note that the date of transfer must be 1-30 days in the future. Also, you can transfer your house to another
      character of your account on the same game world. Please keep in mind that there must be a tick in the box to the
      left of the character name if the house is to be transferred to another character. To transfer the house for free
      set the amount to 0 gold.
      <br />
      Once the owner has filled in the form and confirmed the transfer by entering the account password the target
      character can accept or reject the deal when viewing the house in the house section, provided the player is logged
      into the account. This player must accept the deal before the date of the transfer and make sure to have the
      required amount of gold pieces in his depot of the corresponding city. House transfers will be cancelled in any of
      the following cases: If the future owner rejects the transfer, if he does not accept the deal, or if he does not
      have the required amount of money in the correct depot. Please note that the house owner can cancel the deal at
      any time until the transfer is accepted by the receiver of the house. To do so the owner has to view the house
      page, click on "Move out" and select "No, I don't want to leave my house".
      <br />
      If all requirements are met, the transfer will take place at the server save on the specified date. The former
      owner should make sure that all of his belongings are moved out of the house in time, because once a transfer is
      complete he will not be able to get them back unless the new owner of the house agrees to return them.
      <br />
      <br />
      <b>4.6.3 Rights and Duties</b>
      <br />
      <br />
      <b>a) Rent</b>
      <br />
      In addition to any one-time payment that is necessary to get a house, characters must pay a monthly rent for their
      houses. Rents can vary greatly depending on the size, location and furnishing of a house. Since landlords are
      suspicious people the rent must always be paid in advance. The money is automatically debited from the character's
      depot of the city the house is registered to. If this depot does not contain enough money when the rent is due,
      the owner of the house will receive a warning letter every day during the last week. If the rent is not paid
      during this week, the character will be evicted from the house and all belongings in the house will be moved into
      the depot of the character. Moreover, all characters on the player's account are blocked from renting another
      house for one month. The house concerned will be ready to be auctioned again.
      <br />
      <br />
      <b>b) House Rights</b>
      <br />
      Ownership of a house confers a number of special rights to characters. They can invite other characters, appoint
      them sub-owners who can also invite others, or kick out unwanted visitors from their home. Editing of house rights
      is handled in way that is similar to casting spells, i.e. owners can use special "house spells" while standing in
      their houses which will allow them to edit house rights. In the following you will find a list of all house
      spells:
      <ul>
        <li>
          <b>Edit Guest List: "Aleta Sio"</b>
          <br />
          Using this spell owners and sub-owners with Premium Account can edit a list of all characters that are invited
          to their house. Simply enter the name of any character you would like to invite, making sure only one name is
          entered per line. To un-invite characters simply erase their names.
        </li>
        <li>
          <b>Edit Sub-Owners: "Aleta Som"</b>
          <br />
          Similar to the list of invited characters, owners can appoint sub-owners by editing this list. Not only are
          sub-owners invited to houses, they are also entitled to invite or uninvite other characters to it themselves.
          Also, sub-owners can kick characters if they wish to do so.
        </li>
        <li>
          <b>Kick Character: "Alana Sio"</b>
          <br />
          If this spell is pronounced along with the name of a character, this character will be kicked out of the
          house. Owners and sub-owners can use this spell, but characters can also kick themselves if they wish to.
        </li>
        <li>
          <b>Edit Door Rights: "Aleta Grav"</b>
          <br />
          This spell allows owners to edit door rights of houses, i.e. they can determine which characters may open or
          close front or interior doors. This way they can create private spaces within buildings, which is particularly
          handy in big houses such as guildhalls. To edit door rights the owner must be placed in front of a door and
          pronounce the "Aleta grav" spell. Of course, the owner of a house always has access to any room beside the
          characters listed for a door.
        </li>
      </ul>
      There are a number of abbreviations to assist owners in editing lists of rights. Thus, an asterisk (*) can replace
      a longer string of letters, while a question mark (?) represents a single letter. Moreover, guild leaders can use
      special patterns to mark all guildmembers (*@guildname) or even to specify members of a certain rank
      (rankname@guildname).
      <br />
      If an exclamation mark (!) is placed at the beginning of a line, all matching characters are excluded. Lines
      starting with a hash (#), on the other hand, are considered to be comments, so they have no effect.
      <br />
      <br />
      <br />
      <br />
      <h3>4.7 Guilds</h3>
      Tibia is a game in which personal contacts and relationships between players are extremely important, and those
      who play it will learn soon enough that unity is strength. For this reason there are so-called "guilds" in Tibia
      in which players band together.
      <br />
      <br />
      <b>4.7.1 What are Guilds?</b>
      <br />
      Guilds are coalitions of Tibia characters that are established to bring like-minded players together. They are
      very popular since they usually offer good protection to its members. Powerful guilds generally have more
      influence on the events and politics in a world than any single player could possibly have, and few will be
      foolish enough to mess with a member of a strong guild. However, note that each character can only be a member of
      one guild at a time. Membership in a guild offers the following benefits:
      <ul>
        <li>
          <b>Guild Rank</b>
          <br />
          Each member of a guild receives a guild title. This title will be displayed both on the character's account
          page and on the guild page. Also, players can see a character's guild rank if they look at him.
        </li>
        <li>
          <b>Guild Chat Channel</b>
          <br />
          Guild members can open and use a private guild channel.
        </li>
        <li>
          <b>Guild Message Board</b>
          <br />
          Guild members can read and post on a private guild board.
        </li>
        <li>
          <b>Access to Guildhalls</b>
          <br />
          Guildhalls are houses that are specifically designed to be used by guilds. Provided the guild leader has
          rented one, any guild member can enter the guildhall. This way, even free account players get a home for their
          characters! However, note that guild leaders can make areas within the guildhall inaccessible to other guild
          members, so guild members may not have access to the entire building.
        </li>
      </ul>
      <b>4.7.2 Guild Structure</b>
      <br />
      All Tibia guilds have a basic structure. Even though guilds can have a complex hierarchy with up to 20 different
      individual ranks and countless individual titles, Tibia guilds are always made up of three basic ranks: Guild
      leaders, vice leaders and regular members.
      <br />
      The rank of the guild leader is always the highest rank in a guild and this position must always be hold by
      exactly one character. Characters who found new guilds will automatically be elevated to this rank, so guilds are
      usually led by their founders.
      <br />
      Moreover, every guild should have at least four vice leaders. These vice leaders form the inner leadership circle
      of guilds. Characters that wish to found a new guild must recruit at least four vice leaders within three days
      after the guild's foundation. If they fail to convince enough players to join as vice leaders, the guild will be
      disbanded. Also, if a vice leader leaves an existing guild and there are not enough vice leaders anymore, the
      guild leader must find a replacement within two weeks.
      <br />
      Please note that only premium players can become guild leaders or vice leaders. Also, keep in mind that there must
      not be more than one guild leader or vice leader on one account. Thus, for example, it is not possible to have a
      guild leader on one world and a vice leader on another. Moreover, if the Premium Account of a guild leader or vice
      leader runs out, he can still keep his position and perform every action connected to his guild rank. Note, if
      there are less than five premium players in the rank of the leader or vice leader, the guild will be disbanded
      after two weeks.
      <br />
      Finally, regular members are the rank and file of guilds, i.e. they make up the mass of guild members. A guild can
      have an infinite number of members, and any characters can join a guild provided they have been invited by the
      guild leader or by a vice leader.
      <br />
      <br />
      <b>4.7.3 Administrating Guilds</b>
      <br />
      Any administrative action such as founding, editing or joining guilds is done through the guild section of the
      official website www.tibia.com. Except for "Found Guild", all guild edit functions can be found on the guild's
      information page. To check out a guild's information page select a guild and click on "View". A special page will
      open which will show you various data about the guild, including the guild's motto, logo and member list.
      Remember, however, that you must be logged in with your character in order to do any editing on the guild page. If
      you are not logged in, all you can do is to look at guilds. Also, keep in mind that any changes that are made on
      this page will only be activated on the next login. For example, if a guild member who is currently online, is
      excluded from the guild he will remain an official member until he logs out. In the following you will find a
      comprehensive list of all guild edit functions:
      <ul>
        <li>
          <b>Found Guild</b>
          <br />
          This button is found on a world's guild list. Click on this button to found a new guild. You will be asked to
          enter the guild's name, the world it is on and the name of the future leader. Also, you must enter your
          password to confirm the process. Remember that you will not be able to found a guild if you are not a Premium
          player, or if another character on the account is a guild leader or a vice leader already. Also, keep in mind
          that the name rules also apply to guild names. Offensive guild names are considered to be violations of the
          Tibia Rules and can be punished accordingly. Also, since guild names cannot be changed subsequently a guild
          with an invalid guild name is likely to be disbanded.
        </li>
        <li>
          <b>Join Guild</b>
          <br />
          This button is available if you view a guild on the website while being logged in with an account that
          contains one or more invited characters. To join that guild simply click on the button, then select the
          character you would like to join the guild and confirm.
        </li>
        <li>
          <b>Edit Description</b>
          <br />
          This option is only available to guild leaders. Clicking on this function will open up an edit page in which
          the guild leader can enter a description of up to 500 characters, a link to a homepage and a guild logo.
          Again, offensive commentaries, links or guild logos may lead to a punishment.
        </li>
        <li>
          <b>Create/Delete Board</b>
          <br />
          This is another option that is only available to guild leaders. By pressing this button guild leaders can
          create a private message board on which only guild members can read or post. Like all regular boards the guild
          board can be selected in the forum section of the website.
        </li>
        <li>
          <b>Disband Guild</b>
          <br />A guild leader can always disband his guild if he chooses to do so by clicking on this button. He will
          be asked to confirm by entering his password. Once the decision has been confirmed by the guild leader the
          disbandment will be executed immediately. If there is a guildhall, it will be automatically cleared on the
          next server save, and any items that are stored in it at this moment will be transported to the guild leader's
          depot.
        </li>
        <li>
          <b>Edit Ranks</b>
          <br />
          Only guild leaders can edit guild ranks. They can determine the number of guild ranks (up to 20) and give them
          names. If a guild leader chooses to give identical names to the leader rank and the vice leader rank, the
          member list will appear as if there were only vice leaders and regular members. However, this is only a
          cosmetic effect: Any guild must have one leader, and in fact, the first character on the list is the regular
          guild leader even though it may not look that way.
        </li>
        <li>
          <b>Appoint Character</b>
          <br />
          Several options are available here: Individual titles can be conferred to characters by using the "Set title
          to" option. Also, characters can be excluded from their guild by selecting the "Exclude from guild" option.
          Finally, by selecting "Set rank to" it is possible to promote or demote characters. Note that any guild member
          can promote or demote guild members who are inferior in rank. Thus, if a guild has ten ranks, a guild member
          of fourth rank can promote or demote any guild member of fifth rank or below. However, it is not possible to
          promote guild members to one's own rank, so if the mentioned guild member would like a friend to rise to rank
          four, he would have to ask a guild member of rank three or better.
        </li>
        <li>
          <b>Resign From Leadership</b>
          <br />
          Obviously, only guild leaders can resign from leadership. If they do so, the leadership of the guild will be
          passed on to a character of their choice. The transferral will come into effect on the next server save. If
          there is a rented guildhall, it will automatically be transferred to the new guild leader. Note that the
          player of the new guild leader must not have another vice leader or guild leader on his account.
        </li>
        <li>
          <b>Invite Player</b>
          <br />
          Both guild leaders and vice leaders can invite players. Characters that are invited must agree to join by
          clicking at the button on the guild information page.
        </li>
        <li>
          <b>Exclude Player</b>
          <br />
          Similar to inviting characters, both guild leaders and vice leaders can exclude players. Excluded Players who
          are online during the exclusion will remain members of a guild until they log out.
        </li>
        <li>
          <b>Leave Guild</b>
          <br />
          All vice leaders or regular members are free to leave a guild whenever they choose to do so. However, if they
          are logged in while leaving the guild, they will remain guild members until they log out.
        </li>
      </ul>
      <br />
      <br />
      <h3>5 Accounts</h3>
      Every Tibia player must have an account. Tibia accounts are like personal registers that help players to organise
      their activities in Tibia. If you have not created an account yet, please sign up first. Each account has its own
      account page on the official website. The holder of the account can access the corresponding account page anytime
      by clicking on the "My Account" button on the left hand side of the website. A login dialog will appear in which
      the account number and password must be entered. Once the login is completed the account page will be displayed.
      Account pages divide into five sections: general information, payment information, personal information, the
      criminal record and a character list.
      <br />
      <b>Note:</b> Players who are new to Tibia should understand that accounts represent real value, especially if
      there are characters of medium or high level on them. For this reason accounts are protected by a number of safety
      features. Players should take some basic precautions to protect their accounts. If you would like to know more, we
      strongly recommend you to read the security hints today!
      <br />
      <br />
      <br />
      <h3>5.1 Notes on Account Data</h3>
      During the process of account creation three important kinds of data are established that are unique for each
      Tibia account: The account number, the password and the registered email address. You should keep a wary eye on
      this data at all times in order to keep your account safe.
      <ul>
        <li>
          <b>Account Number</b>
          <br />
          Account numbers are used to identify accounts. They are displayed during the signup process, and new players
          should memorise them well, since they must enter their account number whenever they wish to play Tibia. If you
          have forgotten your account number, you can request it by using the Lost Account Interface. The account number
          will be sent to the registered email address. However, note that this will not generate a new number, since
          account numbers cannot be changed for technical reasons.
        </li>
        <li>
          <b>Password</b>
          <br />
          The password is the second code players must enter when logging into Tibia. Passwords are combinations of
          letters and figures which consist of a minimum of eight digits. New players will have a secure password sent
          to their registered email during the signup process. However, they can easily change this password anytime by
          using the "Change password" option on the account page. Unlike changes of the registered email address,
          changes of passwords take effect immediately. Note, however, that customised passwords should be secure -
          ideally they should contain a mix of upper and lower case letters, numbers and special symbols. If you have
          forgotten your password, you can use the Lost Account Interface to request a new one. The new password will be
          sent to the registered email address.
        </li>
        <li>
          <b>Registered Email Address</b>
          <br />
          This is the email address to which the Tibia account is registered during the signup process. The registered
          email address is extremely important, because it is the key to the account it is assigned to. A player who has
          lost both the account number and the password can still get an account back by using the Lost Account
          Interface, provided he has access to the registered email address. Note that only one Tibia account can be
          registered to one email address at a time. It is possible to change the registered email address later on the
          account page.
        </li>
      </ul>
      <br />
      <h3>5.2 Managing Your Account</h3>
      Account management is done on the account page. Your account page may contain up to five sections which are
      explained in the following.
      <br />
      <br />
      <b>5.2.1 General Information</b>
      <br />
      This section includes general account information such as the registered email address, the date when the account
      was created, and the current account status, i.e. whether or not a Premium Account is currently assigned to this
      account. Also, the date of the last account update will be given. Entries in this field usually refer to changes
      of account data.
      <br />
      The following options are available in this section:
      <ul>
        <li>
          <b>Change Email Address</b>
          <br />
          Players can initiate a change of the registered email address by using this option. To confirm the change a
          new email address as well as the valid password must be entered. Note that accounts cannot be registered to
          email addresses that are already registered to a different Tibia account. For reasons of security email
          changes are not performed immediately. In fact, changes of email addresses take thirty days to take effect.
          During this period the process can be cancelled anytime. To make sure the account holder does not forget about
          the pending email change a note will be added on top of the account page. Also, an automatic email containing
          a warning about the upcoming email change is sent to the old email address. When the thirty days have passed
          players will be asked once more to confirm the email change. If the change is confirmed, the Tibia account is
          reassigned to the new email address, and a new password is sent to this address. Players must log into the
          game within 24 hours using this new password. If they fail to do so, the change of the email address is
          cancelled, and they can log in normally using their last valid password.
          <br />
          Note that the process of re-registering accounts to new email addresses can be sped up by using the Lost
          Account Interface. However, to do so a player must have the valid recovery key. Email changes that have been
          performed using the recovery key are instantaneous.
        </li>
        <li>
          <b>Change Password</b>
          <br />
          By using this option players can change the account password. To do so the current as well as the future
          password must be entered. Contrary to the process of changing the registered email address password changes
          are instantaneous.
        </li>
        <li>
          <b>Become a Tutor</b>
          <br />
          Players who would like to be tutors should click on this button. However, note that players must meet certain
          requirements to be tutors. Please see the tutor guide for details on requirements and duties of tutors.
        </li>
        <li>
          <b>Logout</b>
          <br />
          Logging out from accounts is done by clicking on this button. You must log in successfully before you can
          access your account page again. Note that characters will automatically log out from the website if they have
          been inactive for 45 minutes.
        </li>
      </ul>
      <b>5.2.2 Payment Information</b>
      <br />
      This section shows a record of all Premium Accounts that have ever been assigned to the account. If the account
      has never had a Premium Account assigned to it, the message "No payments yet" will be displayed.
      <br />
      Tibia is a free game, and non-paying customers are welcome to play as often as they like. However, it is possible
      to order so-called Premium Accounts which will open up many additional premium features inside and outside the
      game. Order a Premium Account and enjoy the full Tibia experience today! To order a Premium Account for yourself
      or for a friend simply click on the button "Get Premium Account" at the bottom of the Payment Information section.
      For details please see the manual section explaining the online payment interface.
      <br />
      <br />
      <b>5.2.3 Personal Information</b>
      <br />
      This section is reserved for personal information that players would like to share with others. Such information
      can include the real name or place of residence of the player as well as links to private homepages or
      ICQ-numbers. Information given in this section will be displayed whenever other players look at the character
      information page of one of the characters. Note, however, that no personal information will be displayed if an
      inspected character is marked as hidden.
      <br />
      To edit your personal information simply click on the corresponding button. Whether or not you are entering
      correct data here is entirely up to you. However, note that offensive statements or links leading to offensive
      websites are considered rule violations and may be punished accordingly.
      <br />
      <br />
      <b>5.2.4 Criminal Record</b>
      <br />
      If you have violated one or more of the Tibia Rules, your account page will also contain a criminal record. A
      criminal record is a list of all punishments that have been imposed on one or more characters on a Tibia account.
      <br />
      <br />
      <b>a) What is a Criminal Record?</b>
      <br />
      A criminal record is a list of all punishments that have been imposed on one or more characters on a Tibia
      account. You can check the criminal record of your account by looking at your account page. If you cannot find a
      criminal record on your account page, there is no entry, i.e. your criminal record is clean. Entries in the
      criminal record will show (from left to right) the date, character name, reason, type and duration of the
      punishment. The stated reason will clearly point out the rule that was violated. Moreover, gamemasters can add own
      commentaries that will help you understand the reason for your punishment.
      <br />
      <br />
      <b>b) Clearing Your Criminal Record</b>
      <br />
      The criminal record will be deleted automatically at the beginning of a month once a period of at least six months
      has passed since the most recent entry. However, if there are further entries during this period of time, the
      duration will again be extended, and you must wait another six months before the criminal record is cleared again.
      Please note that as long as there are entries in your criminal record you will not be able to apply as a tutor.
      <br />
      <br />
      <b>c) Complaining About Punishments</b>
      <br />
      Provided that it is possible to complain about a given punishment you can do so in your criminal record. Please
      click on the corresponding button next to the punishment you would like to complain about. Once you have done so a
      dialog will open up which will allow you to write a message to the gamemaster who has imposed the punishment on
      you. However, keep in mind that it is not always possible to complain. Remember that punishments given by the
      customer support cannot be complained about. Also, please note that you may only complain within a week after the
      punishment has been imposed. If you do not complain within seven days after the punishment, the complaint button
      will disappear, and you will not be able complain about this banishment anymore.
      <br />
      If you have complained about your punishment, you will receive an answer by the gamemaster. Please be patient if
      you do not receive a reply immediately. You will soon find the gamemaster's answer at the top of your account
      page. If the gamemaster has not closed the thread, you can write to him again. If you feel the gamemaster has not
      replied in an adequate way, it is possible to forward the complaint to the customer support. The customer support
      will evaluate your punishment and answer your complaint within seven days. Please note that any decisions taken by
      the customer support are final. Whatever the customer support judges, you will have to accept it.
      <br />
      <br />
      <b>5.2.5 Characters</b>
      <br />
      This is a list of all characters belonging to the account that is currently displayed. The list shows the name,
      game world and status of each character, with "Status" indicating whether or not a character is hidden. Also, you
      will find an "Edit" button behind each name. Click on this button to edit the way in which a character is
      presented on the website:
      <ul>
        <li>
          <b>Comment</b>: Any comment that is entered in this box will be displayed whenever a player views the
          character on the website.
        </li>
        <li>
          <b>Forum Signature</b>: Text entered in this box will be attached to any message this character posts in the
          forum. Note that signatures must not exceed four lines of text with a total of two hundred letters.
        </li>
        <li>
          <b>Hide Character</b>:
        </li>
        If this box is ticked, the character can be viewed normally by any other player on the character information
        page. However, if the tick is removed, the character will be hidden, and other players will only see basic
        information such as the character name, sex, etc. when looking at the corresponding character information page.
        Most importantly, all other characters on the account will be hidden.
      </ul>
      Please note that any texts entered in these fields can be seen by the whole community, so offensive statements
      will be treated as violations of the Tibia Rules and dealt with accordingly.
      <br />
      In addition to the editing of individual characters the following options are available in the character section:
      <br />
      <ul>
        <li>
          <b>Create New Character</b>
          <br />
          New characters can be created by clicking on this button. For details please see the section on character
          creation. Note that each account can hold a maximum of 20 active characters. If you would like to create
          further characters, you must delete at least one existing character first.
        </li>
        <li>
          <b>Delete Character</b>
          <br />
          This option can be used to delete characters. If you would like to remove one or more characters from your
          account, click on this button, then enter the name of the character you wish to delete and confirm by entering
          the valid account password. Character deletions are not finalised until 60 days have passed, so you have 2
          months to change your mind. During this period of time deletions can be cancelled by clicking on the button
          "Undelete Character" and by entering the respective character name. Keep in mind, however, that characters are
          permanently deleted once the 2-month period is finished, and there is no way to restore them!
          <br />
          Characters who are set for deletion do not count as active, so they are not considered for the calculation of
          the character limit. Thus, if there are 20 active characters on your account and you set one of them for
          deletion, you may create one new character. However, if you do that, you cannot undelete the character who is
          set for deletion because the limit of 20 active characters can never be exceeded. To undelete that character
          you must set another active character for deletion first. Please note that the total of active and deleted
          characters may not exceed 25.
          <br />
          <b>Note:</b> Please understand that names of deleted characters cannot be chosen for new characters for about
          six months. Moreover, if you would like to delete a whole account, you must delete all characters on it first.
          Once you have done this the account will be deleted after a few months as a result of an automatic procedure.
        </li>
        <li>
          <b>Change Character Sex</b>
          <br />
          Premium players can change their characters' sex. To do so click on this button and enter a character's name
          as well as the valid password to confirm. Look out, however, because a fee of
          <b>five premium days</b>
          will be charged for each sex change, so you should not use this option lightly. Please note that you cannot
          change the sex of a character more than once within 7 days.
        </li>
      </ul>
      <br />
      <h3>5.3 Online Payment Interface</h3>
      Tibia Premium Accounts are ordered via the online payment interface. To successfully order a Premium Account you
      must agree to our extended licence agreements and enter all required information in the interface. Note that it is
      possible to order Premium Accounts both for yourself as well as for friends.
      <br />
      When ordering you should always keep in mind that payments for Tibia Premium Accounts are <b>not</b>
      processed by CipSoft itself. Instead, they are processed by CipSoft's partner company element 5, a payment service
      provider who specialises in software sales. For this reason payments must be sent to element 5 rather than to
      CipSoft. Once element 5 has confirmed a payment CipSoft will be notified and a corresponding Premium Account will
      be assigned to the account for which it has been bought. The Premium Account will be activated the next time the
      holder of the account logs into the game. However, if the Premium Account has been ordered and paid for by
      somebody else the account user must accept it first on the account page.
      <br />
      <b>Note</b>: Premium Accounts will <b>not</b> be extended automatically. If an existing Premium Account runs out,
      the corresponding account is set to free status and the additional Premium features are suspended. We recommended
      you always order a new Premium Account in time to avoid problems such as lost houses.
      <br />
      <br />
      In the following you will find a detailed description on how to order and pay for Premium Accounts:
      <ol>
        <li>
          <b>Welcome Page</b>
          <br />
          This page contains general information on Premium Account orders such as lists of prices and available payment
          methods as well as some notes concerning legal limitations on ordering. To start the ordering process simply
          click on "Continue".
        </li>
        <li>
          <b>Extended Tibia Licence Agreement</b>
          <br />
          Firstly, you will be asked to accept the Extended Tibia Licence Agreement. This agreement should be considered
          to be an addition to the Tibia Licence Agreement that must be accepted by all registered users. You should
          read it carefully before proceeding to the next step. If you feel you would rather not accept the Extended
          Tibia Licence Agreement, you can break off the ordering process by clicking on "Cancel".
        </li>
        <li>
          <b>Specify Age, Recipient and Product</b>
          <br />
          Next, you will be asked to enter your age. Note that if you are underage, you must have the permission of your
          parents or legal guardians to order. Also, this is where you can specify the recipient of the Premium Account
          you are about to order:
          <ul>
            <li>
              If you wish to order a Premium Account <b>for yourself</b> you will be asked to enter your account number
              and password. Also, you must select the duration of the Tibia Account you would like to buy. Once you have
              entered the required data and clicked on "Submit" all given information will be displayed again so you can
              double-check the data you have entered. If all information is correct click on "Continue" to confirm.
            </li>
            <li>
              If you wish to order a Premium Account <b>for a friend</b> enter the name of one character on the account
              on which you want the Premium Account to be on. Also, you can enter your name and a personal comment to
              let the recipient know your name and the cause of this happy surprise. Please make sure to give correct
              and comprehensible data here because we advise all players not to accept Premium Accounts paid by others
              if they do not know who sent them and why. Do not forget to specify the duration of the Premium Account
              you would like to enter. Once you have submitted your order a confirmation page will be displayed where
              you can review your entries. If you are satisfied with the data you have given click on "Continue" to
              confirm.
              <br />
              <b>Note:</b> The name of one character on an account is all that is ever needed to buy a Premium Account
              for somebody else. If somebody else is buying a Premium Account for you, make sure not to give him any
              further information. Remember you must keep your account data confidential at all times!
            </li>
          </ul>
        </li>
        <li>
          <b>Payment Data Review Page</b>
          <br />
          Once you have filled in the form a review page will be displayed in which you can check the payment
          information, including the billing address, shipping address, selected payment method and billing currency,
          and the product number of the Tibia account you are about to order. Note that the billing address and the
          shipping address will be identical even if you have ordered the Premium Account for a friend rather than for
          yourself. If you are satisfied with the payment information you have given click on the "Order" button to
          finalise the ordering process.
        </li>
        <li>
          <b>Confirmation Page</b>
          <br />
          On this final page element 5 confirms your order if everything worked well. You will receive the reference
          number of your order and information on how to pay if you have chosen another payment method than credit card.
          Also, you will get the information via email. If the Premium Account is not available immediately, please do
          not start another attempt to order a Premium Account, otherwise you might get two Premium Accounts you have to
          pay. Please keep in mind that the purchased Premium Account will not be available until your payment has
          actually arrived and confirmed by element 5. Please allow some time for this to happen. Depending on the
          chosen payment method as well as your location it can take up to three weeks until your payment has arrived,
          especially if you have paid by using cheque, bank transfer, or by sending cash. Payments made by credit card
          are normally processed within 24 hours. Finally please note that you need the order reference number if you
          have a request concerning your order.
        </li>
      </ol>
      <br />
      <h3>5.4 Personalisation and Recovery Key</h3>
      Non-personalised accounts which currently have a Premium Account on them can be personalised to their users.
      Personalisations provide additional security because they create lasting bonds between users and their accounts.
      Players who have correctly personalised their accounts will always be able to get them back in case they lose
      access to them. Note that personalisations are never available on free accounts, even if an account has previously
      had a Premium Account on it.
      <br />
      <br />
      <b>a) How to Personalise Accounts</b>
      <br />
      If an account is ready to be personalised, a button labelled "Personalise Account" will appear at the top of the
      account page. Clicking on this button opens up a form in which you will be asked to enter personal data such as
      your first ("given") and last ("Surname") name, date and place of birth, and nationality. Make sure to enter
      correct data, because personalisations that contain nonsensical entries are invalid and will not be accepted by
      CipSoft if new account data is requested. Once the entry has been confirmed a recovery key will be displayed.
      Write it down carefully on a piece of paper and store it in a safe place. Note that for reasons of security the
      recovery key will only be displayed once and it will <b>not</b> be sent by email, so you should really make sure
      you write it down!
      <br />
      Personalisations do not take effect until seven days have passed. During this period of time a warning will be
      displayed at the top of the account page to make sure the player is aware that a personalisation is taking place,
      and any data entered in the personalisation can be changed. <br />
      <br />
      <b>b) Why Should I Personalise my Account?</b>
      <br />
      Personalisations provide additional security to your account. More precisely, personalisations offer the following
      advantages:
      <ul>
        <li>
          <b>Registered Ownership</b>
          <br />
          Once you have successfully completed the personalisation you will be considered to be the one and only
          rightful holder of your account by CipSoft. If others should raise claims on your account, you can easily
          prove them wrong if the account is personalised to you.
        </li>
        <li>
          <b>Recovery Key</b>
          <br />
          Once you have successfully personalised your account you will receive a 20-digit recovery key. This key is
          very important, because players who have the correct recovery key can instantly register accounts to new email
          addresses by using the Lost Account Interface. For this reason the possession of a recovery key will
          effectively give you total control of the corresponding account.
          <br />
          <b>Note:</b> If you should ever lose your recovery key, or if you feel somebody else has managed to get access
          to it, you can request a new key by proving you are the rightful holder of the account. To do so send a letter
          to CipSoft via postal services. State your request and the name of your character in this letter and sign it.
          Also, include a photocopy of an official document with your first and last name, date and place of birth, and
          nationality on it. This is necessary to prove that the account is personalised to you. Any passport, identity
          card, birth certificate or driving licence will do. Please understand that for reasons of security CipSoft
          cannot accept faxes or scans sent as email attachments. If the data on the included photocopy matches the data
          of your account personalisation, CipSoft will send a new password as well as a new recovery key to the email
          address your account is registered to. If the data does not match, you will receive an email informing you
          that your request has been rejected.
          <br />
          It is a good idea to include a new email address that is not registered to a Tibia account yet in your letter.
          CipSoft will register your account to this new address and send your new password and recovery key there.
        </li>
        <li>
          <b>Tutorship</b>
          <br />
          You can only become a tutor if your account is correctly personalised to you. For details on how to become a
          tutor please take a look at the tutor guide.
        </li>
      </ul>
      A word on privacy: CipSoft guarantees that any data entered during personalisation is used exclusively for the
      stated purpose of registering Tibia accounts to their users. The given data will be held strictly confidential,
      and under no circumstances will information be passed on to third parties. If you have purchased a Premium
      Account, you should really take this opportunity to improve your account's security!
      <br />
      <br />
      <br />
      <br />
      <h3>6 Support</h3>
      Tibia is a busy place where tens of thousands of people meet every day. Of course, with a community that size
      things cannot go smoothly all the time and players may have all kinds of questions. To make sure all problems are
      dealt with swiftly Tibia offers a number of features. Various help options as well as a network of experienced
      players make sure all players get the answers they are looking for. Also, the game is constantly monitored by
      so-called gamemasters to make sure the Tibia Rules are followed by all players.
      <br />
      <br />
      <br />
      <h3>6.1 Getting Help</h3>
      <b>6.1.1 In-Game Help</b>
      <br />
      Need help to find your way around? This manual may be an exhaustive source of information concerning game play or
      general questions about Tibia, but it is often nicer to get help just where you need it. If you are logged in and
      there is a question you don't have an answer for, you will find there are many ways to get help in the game. Note,
      however, that you will frequently be referred to specific parts of the manual or other documentations on the
      website. This way you will get authentic information.
      <br />
      <br />
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <b>a) Client Help</b>
            </td>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
              <img src="/manual/lens.gif" width="17" height="17" />
            </td>
          </tr>
        </tbody>
      </table>
      The client contains an automatic online help feature which allows you to identify most elements of the adventure
      screen, including any notable items or areas on the map. To activate the client help open the help menu and select
      "Client Help" or hit the key combination Ctrl+H.
      <br />
      You can tell the client help has been activated if the mouse pointer has turned into a magnifying glass. Now, to
      learn more about an element of the adventure screen all you need to do is to place the mouse pointer on it. If
      there is some information available on the selected element, an info box will be displayed which will provide you
      with additional information. Any mouse click will stop the client help and you can continue to play.
      <br />
      <br />
      <b>b) Hints</b>
      <br />
      In addition to the client help there is an automatic hint feature which will supply you with useful tips as you go
      along. These hints are designed to help new players to understand the basics of Tibia's game play.
      <br />
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/hint_icon.gif" width="32" height="32" />
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
            <td>
              You can tell that you have received a new hint by the flashing icon in the top right hand corner of the
              game window. Click on this icon to read the new hint. The flashing icon will remain until the message has
              been read, so you do not have to read the hint immediately. If you would like to read old hints again,
              click at the button labelled "Hints" in the help menu, select the desired hint message and confirm by
              clicking "OK".
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>c) Tutor Help</b>
      <br />
      If you feel you would like to ask a real person instead of using an automatic help feature, you should turn to one
      of Tibia's trusted tutors for advice. Tutors are experienced players who will gladly answer any question
      concerning client controls, general game play, use of accounts and the official website you might have. Keep in
      mind, however, that they are not required to answer in-depth questions about the content of the game such as
      quests, so if you are stuck with a quest, you will have to work it out yourself.
      <br />
      Tutors can be contacted on the help channel. To enter this channel open the help menu and click on "Tutor Help" or
      open the new channel dialog by clicking on the file card symbol in the top right hand corner of the console and
      select "Help". Alternatively, hitting the key combination Ctrl+T will also work to open up the help channel. For
      additional information on the duties of Tibia tutors you should have a look at the tutor guide.
      <br />
      <br />
      <b>6.1.2 Offline Help</b>
      <br />
      The various online help options offer many ways to find the information you are looking for. However, it is always
      possible that you hit upon some special question to which you just can't seem to find an answer in the game.
      Whenever this happens Tibia's official website is the place to turn to. The website offers many additional sources
      of help that are designed to give players the answers they need in as little time as possible.
      <br />
      The most important single source of information is the manual you are reading this very moment. The manual is
      designed as a comprehensive reference to Tibia that covers every conceivable aspect of the game. However, a number
      of further sources of information and support features are available for all players:
      <ul>
        <li>
          <b>Quickstart</b>
          <br />
          The quickstart is a step by step tutorial into the game intended as an introduction for beginners. Here, those
          who are playing Tibia for the very first time will find brief and easy-to-follow instructions on how to get
          started. This includes explanations of the signup process, basic game controls, and general information on
          what to do and where to go. If you are new to Tibia and you find the manual is just too much to read for the
          moment, you should read the quickstart instead.
        </li>
        <li>
          <b>FAQ</b>
          <br />
          Are you having some special problem with Tibia? Then you should have a look at our FAQ ("Frequently Asked
          Questions"). Here, a large number of questions concerning important aspects such as gameplay or the account
          system are answered in a concise and informative way. Some sections specifically cover problems with the
          client or with internet connections, so the FAQ can also aid you with troubleshooting if you have a technical
          problem with the game. Of course, the number of problems covered here is limited, but when it comes to finding
          answers quickly nothing can beat the FAQ.
        </li>
        <li>
          <b>Tibia Rules</b>
          <br />
          The Tibia Rules are another section of the website that players should actually read before they start
          playing. This code of conduct must be followed by players whenever they are playing or expressing themselves
          on the website, e.g. at the forum. Whenever you have a question concerning the rules you should have a look at
          this list, because each rule is discussed at length in a separate commentary.
        </li>
        <li>
          <b>Security Hints</b>
          <br />
          It is difficult to exaggerate the importance of account security in Tibia. For this reason security hints are
          given on the website which are more than just a source of help to be read in case of a problem. In fact, this
          section is highly recommended reading for everybody, because reading the security hints will help you to fight
          security problems before they actually emerge. Please read and follow them carefully. You will save a lot of
          trouble if you protect your account well.
        </li>
        <li>
          <b>Lost Account Interface</b>
          <br />
          If you have problems to log into your account because you have lost your account number or password or you
          have been hacked, use the Lost Account Interface. The Lost Account Interface is a self-help tool which can
          help you to get your account back. Simply specify your problem, enter the required information and follow the
          instructions. Please note that the Lost Account interface is the only way to get access to lost accounts.
        </li>
        <li>
          <b>Library</b>
          <br />
          The library section of the website contains a number of reference pages. Here you will find exhaustive
          descriptions of all magic spells and creatures as well as detailed maps of the world. Also, a historical
          overview can be found here which will tell you all about how the world of Tibia was created by the gods.
        </li>
        <li>
          <b>Website Index</b>
          <br />
          The website index is a list of relevant key words that come up in the context of the game. It is not really an
          independent source of information in its own right, but rather a useful tool to quickly find the information
          you are looking for. Simply click on a key word to see to the corresponding section of the website.
        </li>
        <li>
          <b>Help Board</b>
          <br />
          Although it is not likely to happen it is nonetheless possible that you are having a problem to which simply
          no answer can be found anywhere on the website. If this should be the case, you can try the help board. Write
          a post there and try to explain your problem as clearly as possible. Many players, above all the tutors, check
          out the help board regularly, so with a bit of luck another player who had a similar problem will be able to
          help you with your problem.
        </li>
        <li>
          <b>Tutor Guide</b>
          <br />
          The tutor guide is a special reference that describes the role of tutors in the game. From details on the
          application process to guidelines concerning the behaviour of tutors you will find that all aspects of
          tutorship are covered in this guide. If you think about applying for tutorship, you should read it carefully.
          However, the tutor guide is recommended reading for all other Tibia players, too, because it is advisable to
          know the rights and limitations of tutors well.
        </li>
        <li>
          <b>News</b>
          <br />
          The world of Tibia is constantly changing, and new updates, problems or topics can crop up every day. Of
          course, CipSoft will make an announcement on the website whenever something important has happened that
          players need to know about. To stay informed it is always a good idea to check the news on the website.
        </li>
        <li>
          <b>Company Information</b>
          <br />
          If you have a question concerning CipSoft, the company who is publishing Tibia, you are invited to take a look
          at the contact page. You will find general information on CipSoft here including the company's postal address,
          links to the official website and a list of legal limitations.
        </li>
        <li>
          <b>Contacting the Customer Support Team</b>
          <br />
          If you have tried all other sources of help without any positive result, you can contact the customer support
          team at support@tibia.com and ask them for assistance. Of course, the customer support team is always glad to
          help, but please understand that the various other help options provided inside and outside the game have been
          introduced with a reason. We therefore ask all players to look upon the customer support team as a last resort
          and to contact them only if all other help options fail.
        </li>
      </ul>
      <br />
      <h3>6.2 Rule Enforcement</h3>
      As a rule, Tibia players are friendly, respectful people who only want to have a good time. However, not all
      people are like that. Because of this Tibia, just like the real world, needs laws and rules which clearly show
      what is allowed and what is not. These rules are called the Tibia Rules. We expect our players to respect and to
      follow the rules at all times so that a minimum level of fairness is maintained in the community. Tibia is
      constantly being monitored to make sure the rules are followed. Monitoring is carried out on several different
      levels:
      <ul>
        <li>
          Tutors and counsellors are experienced players who can report violations, e.g. bad names. For details please
          see the tutor guide.
        </li>
        <li>
          Killing characters is monitored by an automatic system. Please see the section on player killing for further
          information.
        </li>
        <li>
          Gamemasters have the biggest share of rule enforcement in Tibia. They can investigate and punish violations
          against any rule. Because of their important position the following section will explain the role of
          gamemasters in detail. Please note that gamemasters are assisted by counsellors.
        </li>
      </ul>
      <b>6.2.1 Gamemasters</b>
      <br />
      <br />
      <b>a) What are Gamemasters?</b>
      <br />
      Gamemasters are players who have been entrusted by CipSoft with a supervisory role. As it has already been said,
      it is their duty to investigate and to punish rule violations in Tibia. To do so they have a number of special
      abilities. These include the right to check players' criminal records or the ability to broadcast red messages, to
      name but a few. Also, gamemasters can impose penalties on players which include notations, banishments and even
      account deletions. However, players have the right to complain about these punishments. In addition to regular
      gamemasters also the customer support enforces the rules. Decisions made by the customer support are final and
      cannot be complained about.
      <br />
      <br />
      <b>b) How to Recognise Gamemasters</b>
      <br />
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <img src="/manual/gamemaster.gif" width="32" height="32" />
            </td>
            <td>
              <img src="/manual/blank.gif" width="10" height="1" />
            </td>
            <td>
              Be wary if a player claims to be gamemaster in Tibia. You will recognise gamemasters by the abbreviation
              "GM" at the beginning of their name. For instance, "GM John" would be a typical gamemaster name. Also,
              gamemasters wear a special blue outfit which is not available to regular players. Thirdly, gamemasters can
              write red text to make sure people will pay attention to what they are saying. Finally, please note that
              since gamemasters are not real game characters they only have level 2.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>c) How Gamemasters are Recruited</b>
      <br />
      Due to their special position gamemasters are selected with extreme care. Possible candidates are chosen in
      advance by CipSoft and asked to apply. Please understand there is no use in sending applications to be a
      gamemaster unless you have been invited by CipSoft to do so first. However, if you would like to help the
      community, we would be happy to have you as a tutor. Please see the tutor guide on how to apply to be a tutor in
      Tibia.
      <br />
      <br />
      <b>d) Some General Remarks on Gamemasters</b>
      <ul>
        <li>
          As a rule, you should always cooperate with a gamemaster's investigation. If you meet a gamemaster, treat him
          with respect. Players who willingly interfere with a gamemaster's investigation violate the rules and can be
          punished accordingly!
        </li>
        <li>
          It is a good idea to answer if a gamemaster contacts you. If you don't, the gamemaster will think you are away
          from the computer. Keep in mind that this can lead to serious misunderstandings, because a gamemaster may get
          the impression that you are violating a rule!
        </li>
        <li>
          Do not contact gamemasters directly. If you have a question, please ask a tutor in the help channel (Ctrl+T)
          or have a look at the FAQ section on this website. If you would like to report a rule violation, you should
          make a rule violation report (Ctrl+R).
        </li>
        <li>
          Please note that gamemasters are not required to contact players before they impose punishments on them. The
          fact that you have not been warned is not a valid reason to complain.
        </li>
        <li>
          Finally, please remember not to give your account data to anybody - not even to gamemasters! Nobody has the
          right to ask you for your password, account number or recovery key.
        </li>
      </ul>
      <b>e) How to Report Rule Violations</b>
      <br />
      If you find out another player is violating the rules, you should make a rule violation report. To do so please
      press Ctrl+R. A dialog will open where you can enter your report. Please remember to state your report in a short
      and precise sentence. Obscure reports such as "Help plx" are not likely to be answered. Please note that it may
      take a while before a gamemaster answers your report. If you have reported a violation repeatedly without getting
      any answer, you may consider posting your report on the gamemaster board.
      <br />
      <br />
      <b>6.2.2 Punishments</b>
      <br />
      Punishments for rule violations come in different forms, ranging from simple notations in the criminal record to
      banishments and sometimes even deletions of a player's account. Several things should be kept in mind:
      <ul>
        <li>
          There is a progression of punishments, i.e. if a character repeatedly breaks the rules, the punishments will
          gradually get worse. The individual punishment progression is determined by several factors and may vary.
        </li>
        <li>
          With the exception of namelocks and the rare IP-banishments, punishments in Tibia always affect whole
          accounts. For example, if one character is banished for excessive player killing, it is impossible to log in
          with any character on that account until the banishment has expired.
        </li>
        <li>
          Gamemasters have guidelines on how to punish rule violations. However, please note that they are not strictly
          bound to these guidelines. Gamemasters have great freedom in varying punishments if they feel it is necessary
          to do so.
        </li>
        <li>
          Likewise, please note that even though there are clear-cut rules, the gamemasters enjoy a certain freedom in
          their judgement on what is a rule violation and what is not. If a gamemaster finds a player has acted against
          the spirit of the Tibia Rules, he may still punish him because destructive behaviour is not necessarily
          limited to the specific offences listed in the Tibia Rules. However, in such cases the gamemaster will explain
          his decision in the comment. Also, if a player feels he has been treated in an unfair way by the gamemaster,
          he can complain.
        </li>
      </ul>
      In the following you will find a comprehensive list of all punishments that can be given to players in Tibia:
      <ul>
        <li>
          <b>Notations</b>
          <br />A notation is the least serious form of punishment in Tibia. It is a comment in the criminal record that
          should be thought of as a general warning. Since notations are recorded in your criminal record they will
          block you from applying to be a tutor. It is possible to complain about notations provided they have been
          given by a regular gamemaster.
        </li>
        <li>
          <b>Namelocks</b>
          <br />
          If a character has a name that violates the Tibia Rules, he will be excluded from the game until the player
          has selected a new, valid name. He can do so on the account page by viewing the corresponding entry in the
          criminal record. The new name will be forwarded to CipSoft for evaluation. If the new name is valid, it will
          be accepted, and the character can log in again. Please keep in mind, however, that if a name is seriously
          offensive, a gamemaster may decide to banish a character on top of the namelock. It is not possible to
          complain about namelocks, since they are always verified by the customer support team.
        </li>
        <li>
          <b>Account Banishments</b>
          <br />
          Banishments are the most common form of punishments in Tibia. A banishment is a temporary exclusion both from
          the game as well as from the forum. Durations can be anything from a single day to a full month. A banishment
          of 30 days is the toughest punishment short of an account deletion, and it is usually accompanied by a final
          warning. Such final warnings are given to players to remind them that any further rule violation can lead to a
          deletion of the account. Players can complain about account banishments, provided the banishments have been
          imposed by regular gamemasters.
        </li>
        <li>
          <b>IP-Banishments</b>
          <br />
          IP-banishments are rare. They are an exception in that they do not only affect one account, but in fact a
          whole IP-address. As long as the banishment is active no player can log into Tibia from this IP-address.
          CipSoft reserves the right to temporarily block whole IP-addresses if they see fit to do so.
        </li>
        <li>
          <b>Account Deletions</b>
          <br />
          Account deletions are the most serious punishments in Tibia. Because of this accounts are not deleted on the
          spot. Instead, accounts set for deletion are banished for an infinite period of time to make sure the deletion
          can be reviewed carefully. Once the deletion has been confirmed, however, the account can no longer be used at
          all. As with other punishments there is a period of seven days during which a player can complain about an
          account deletion. The deletion will not be carried out as long as the complaint has not been resolved.
          However, please note that once the complaint has been turned down the account will be permanently deleted.
        </li>
      </ul>
      <br />
      <br />
      <h3>7 Forum</h3>
      With tens of thousands of registered players, Tibia has a huge active community. Of course, there is a lot to talk
      about when so many people meet, so we have created a special forum where players can communicate with each other
      even if they are not logged into the game. The Tibia forum is a part of our official website www.tibia.com which
      has been designed as a huge, interactive meeting place where players can ask questions, leave messages for each
      other, trade, or generally exchange opinions on many different topics.
      <br />
      <br />
      <br />
      <h3>7.1 How to Use the Forum</h3>
      The Tibia forum effectively works like a collection of interactive blackboards on which players can post messages.
      For this reason individual sections of the forum are usually referred to as boards. Each board subdivides into
      many individual subsections called threads. Threads owe their name to the fact that they are exchanges of posts in
      which individual messages succeed each other as if they were tied up on a piece of string.
      <br />
      Individual threads may be marked on a board's thread list. For instance, "New" indicates that a thread has only
      recently been started, while "Hot" shows that a thread has been popular with the readers - more than 16 people
      have posted on this thread. Finally, a no entry sign indicates that a thread has been closed.
      <br />
      While any visitor is free to read on the forum, only registered Tibia players are actually allowed to write posts.
      For this reason anybody who would like to post on a board must log in first. To log in click on the "My Account"
      button or on the link labelled "here" on top of the forum page, then enter your account number and password. If
      you have logged in on the account page, you don't need log in again to use the forum. Moreover, please note that
      you can only write posts with characters that have at least level 2.
      <br />
      <br />
      <b>a) Board Rights</b>
      <br />
      Forum visitors are given so-called board rights that settle what they are allowed to do on the forum. The number
      of rights visitors have depend on their status. To check your personal board rights open any board or thread and
      look at the list of rights at the bottom of the page. The rights listed here will show you what you are allowed to
      do on the active board. If you are not logged in, you will find the only right you have is to view threads. If you
      are logged in as a regular Tibia player, however, you will have the right to post new threads, to reply to posts,
      to edit your own posts, and possibly some more. In the extra line below the list of board rights the post options
      on the active board are listed. Here you can see whether or not the use of replace code, smileys, images and web
      links is enabled on the selected board.
      <br />
      <br />
      <b>b) How to Write Posts</b>
      <br />
      Provided you have the right to do so you can start a new thread anytime. To do so click on the button "New Topic"
      at the bottom or the top of a board page. If you would like to answer an existing thread, open the corresponding
      thread and click on "Post Reply" at the bottom or the top of the page. Whenever you write a post a form will open
      in which you can edit your new text. Post forms divide into the following sections:
      <ul>
        <li>
          <b>Tibia Character</b>
          <br />
          Specify the character you would like to write the post with in this field.
        </li>
        <li>
          <b>Thread/Post Subject</b>
          <br />
          If you would like to add a headline to your post, you can do so here. It is obligatory to fill in this field
          if you start a new thread, but you are not required to enter text here if you just reply to an existing post.
        </li>
        <li>
          <b>Thread/Post Icon</b>
          <br />
          It is also possible to mark your post with an icon if you want to. Simply click on the field next to the icon
          you would like to add to your post. This icon will be visible both on the board on which the thread is
          published and in the post itself. If you choose an icon and you change your mind afterwards, you can deselect
          the icon by selecting "No Icon".
        </li>
        <li>
          <b>Message</b>
          <br />
          This is where you can enter the text of your post. Posts may consist of up to 4000 characters. If you would
          like to use one or more smileys in your post, you can do so by using the code that is detailed at the left
          hand side of the reply field.
        </li>
        <li>
          <b>Options</b>
          <br />
          By using these options you can modify several typographical features of your post. You can choose to activate
          or deactivate the automatic parsing of URLs, the use of smileys and the display of signatures.
          <ul>
            <li>
              <b>Automatically Parse URLs</b>
              <br />
              If this option is enabled, internet addresses are automatically detected and enclosed with the [url]
              replace code. This will mark them as internet links in your post. However, please note that for security
              reasons no real links will be created on public boards.
            </li>
            <li>
              <b>Disable Smileys in This Post</b>
              <br />
              If you check this option, smiley code such as :) will remain unprocessed, i.e. it will not be translated
              into graphical emoticons. The default setting is enabled.
            </li>
            <li>
              <b>Show Signature</b>
              <br />
              If this option is disabled, your signature will not be displayed in your post. For further information on
              how to edit signatures please see the manual section on accounts.
            </li>
          </ul>
        </li>
      </ul>
      <b>c) Replace Code</b>
      <br />
      Text of posts can include so-called replace code that will change the layout of the text. Replace code works
      similar to common HTML you may already be familiar with. Replace codes are short commands that are written in
      brackets. To use a replace code simply put it in front of the text you would like to change. Remember that another
      replace code is needed to mark the end of text. This end code is similar to the original code and just includes an
      additional forward slash. Example: You would like to highlight a word by underlining it. To do so you must put the
      corresponding replace code [u] in front of it. Also, the corresponding end code [/u] must be placed at the end of
      the word to indicate the end of the highlighted text. Make sure no blank spaces are overlooked between the word
      and the replace codes. In the following you will find a list of all replace codes that are available on the Tibia
      forum. Note that certain replace codes may be disabled on certain boards. The list of board rights will show which
      replace codes are available for the board or thread you are currently viewing.
      <ul>
        <li>
          <b>Web Links:</b> [url]www.tibia.com[/url] or [url="http://www.tibia.com/"]Tibia Website[/url].
          <br />
          Links to internet sites can be made by using the replace code [url]. Keep in mind that if the option
          "Automatically Parse URLs" is enabled, this replace code will be placed automatically around internet
          addresses. Also, please note that links are disabled on all public boards and a security warning will be
          displayed next to the URL.
        </li>
        <li>
          <b>Thread Links:</b> [thread=XXX]thread title[/thread]
          <br />
          If you wish to link to a thread on the forum of tibia.com, you may alternatively use the replace code
          [thread=XXX]. Please insert the number of the thread for the XXX. This alternative offers you the possibility
          to finally also link to a forum thread on public boards.
        </li>
        <li>
          <b>Post Links:</b> [post=XXX]post title[/post]
          <br />
          You can also link to a specific post in a thread using the replace code [post=XXX] where XXX is the number of
          the corresponding post. Move your mouse over the "Quote" link in order to see the number of the post. It will
          be shown in the status line in the bottom left hand corner of your browser.
        </li>
        <li>
          <b>Email Links:</b>
          [email]support@tibia.com[/email]
          <br />
          If you would like to create a link to an email address, you can do so by using this replace code. Note that
          this feature is disabled on all public boards.
        </li>
        <li>
          <b>Bold Text:</b> [b]bold text[/b]
          <br />
          Text can be highlighted by using this replace code.
        </li>
        <li>
          <b>Italic Text:</b> [i]italic text[/i]
          <br />
          This replace code will cause text to be displayed in italics.
        </li>
        <li>
          <b>Underlined Text:</b> [u]underlined text[/u]
          <br />
          Text marked by this replace code will be underlined.
        </li>
        <li>
          <b>Unordered Lists:</b> [list] [*]item [*]item [/list]
          <br />
          By using this replace code you can create lists. Every item of the list must be marked individually with an
          asterisk put in brackets [*].
        </li>
        <li>
          <b>Ordered Lists:</b> [list=1] [*]item [*]item [/list]
          <br />
          This is a variation of the [list] replace code. When used it will generate an ordered list, i.e. individual
          items will be listed with numbers. It is also possible to have ordered lists with letters by replacing the
          number "1" with an "a", i.e. to use [list=a] instead of [list=1].
        </li>
        <li>
          <b>Code Text:</b> [code]text[/code]
          <br />
          Text marked with this replace code will be displayed in a special font.
        </li>
        <li>
          <b>Character Information:</b>
          [player]character name[/player]
          <br />
          It is possible to create links to the information page of characters by using this replace code.
        </li>
        <li>
          <b>Guild Information:</b> [guild]guild name[/guild]
          <br />
          It is possible to create links to the information page of a guild by using this replace code.
        </li>
        <li>
          <b>Images:</b> [img]image link[/img]
          <br />
          This replace code may be used to show images in your post. Note that this feature is disabled on all public
          boards.
        </li>
      </ul>
      <b>d) Forum Rules of Conduct</b>
      <br />
      The Tibia forum is a public space open to a large number of people, so it is important that all players behave in
      a friendly and respectful manner. To make sure all players behave adequately the rules of conduct specified in the
      Tibia Rules also apply to the Tibia forum. Consequently, any statement that may be considered as being generally
      offensive is illegal. Similarly, posts with annoying frequency, advertising content that does not relate to Tibia,
      off-topic board use or incitement to break the Tibia Rules are considered rule violations and will result in the
      usual penalties. Please see the manual section on rule enforcement for details on punishments.
      <br />
      Note that players who have written a post that has been reported to the customer support or whose accounts have
      been banished are no longer allowed to post new threads or to reply to others. A red hint will be included in the
      list of forum rights which will remind reported or banished players that they are currently not allowed to write
      posts.
      <br />
      <br />
      <br />
      <h3>7.2 The Boards</h3>
      The forum section divides into a large number of individual boards on which players can discuss many different
      topics. Whether you would like to buy or sell items, ask questions to the gamemasters, or just exchange opinions
      with players from different worlds - you will find there is a place for every topic in the Tibia forum! In the
      following you will find a list of all individual boards. Please make sure you always pick the right board to post
      on.
      <ul>
        <li>
          <b>World Boards</b>
          <br />
          These boards are general meeting places for Tibia players of individual worlds. Note that it is possible to
          post on a world board even if you do not have a character on that world.
        </li>
        <li>
          <b>Support Boards</b>
          <br />
          These boards cover a range of problems that may come up. If you have problems to report or proposals to make,
          the support boards are where you should turn to.
          <ul>
            <li>
              <b>Premium Accounts</b>
              <br />
              This board is reserved for all questions concerning Premium Accounts. Premium players who are having
              problems with their accounts may post questions here. However, anybody who has a general question
              concerning Premium Accounts is also welcome.
            </li>
            <li>
              <b>Help</b>
              <br />
              Having problems with Tibia? If you have tried everything else already, the help board is the place to turn
              to. With a bit of luck another player who has had a similar problem will be ready to answer your question.
            </li>
            <li>
              <b>Gamemaster</b>
              <br />
              This is the official place to contact gamemasters on the forums. If you have questions or comments about
              our gamemasters, this is the place to post them. You can also report rule violations here, although you
              should try reporting them in the game first.
            </li>
            <li>
              <b>Proposals</b>
              <br />
              There is always room for improvement. If you have an idea on how to make Tibia even better, why don't you
              share it with us? Simply post it here and discuss it with other players. Do not get discouraged if CipSoft
              does not reply! Although proposals are generally not commented on we regularly check out this board and
              note down all promising ideas.
            </li>
          </ul>
        </li>
        <li>
          <b>Community Boards</b>
          <br />
          The following boards have been included to provide our players with opportunities to exchange opinions on
          matters that are not directly related to the game. You can arrange meetings in real life, chat about things
          that are not related to the game or even publish your very own fantasy story here!
          <ul>
            <li>
              <b>Roleplaying</b>
              <br />
              Tibia is fantasy game after all, and quite a number of our players are die-hard fans of the genre who
              dream about writing their own stories about dragons and sword-wielding heroes. If you have been creative,
              why not sharing it with the Tibia community? All hobby authors among our community are welcome to publish
              their stories here.
            </li>
            <li>
              <b>Real Life</b>
              <br />
              Of course it is nice to talk about different things from time to time. If you would like to post your
              opinion on any topic that is not related to Tibia, this is the board to turn to.
            </li>
            <li>
              <b>Convention</b>
              <br />
              Wouldn't it be nice to meet some of those people you have met in Tibia so often in real life for a change?
              If you are trying to arrange a meeting with other players, you are free to announce it here. Note that in
              addition to this board there is also a special convention page on which conventions may be announced.
            </li>
          </ul>
        </li>
        <li>
          <b>Trade Boards</b>
          <br />
          This is a set of boards that are designed to make trading in the game even more convenient. To avoid confusion
          individual trade boards have been created for every game world.
        </li>
        <li>
          <b>Test Board</b>
          <br />
          This board is a test-only board where you can try out the different post options without annoying your fellow
          forum visitors.
        </li>
        <li>
          <b>Private Guild Boards</b>
          <br />
          If one or more of your characters is a member of a guild, you will be able to post on one or more private
          guild boards, provided the respective guild leader has created a guild board.
        </li>
      </ul>
    </div>
  );
}
