import { Link } from "react-router-dom";

export default function DownloadPage(): JSX.Element {
  return (
    <div>
      <h3>Preconfigured client</h3>
      Ready to use 7.72 client, to play unzip the file and start the game
      <br />
      <Link className="link" to="/client.zip" target="_blank" download>
        Download
      </Link>
      <hr />
      <h3>If you want to use your own 7.72 client</h3>
      <h5>Use following connection data:</h5>
      Host: <b>ots.rt-p.pl</b>
      <br />
      Port: standard (7171 and 7172)
      <br />
      <br />
      OTClient works well too
    </div>
  );
}
