export interface Configuration {
  apiUrl: string;
  bigMapUrl: string;
}

let config: Configuration | null = null;

export const getConfig = (): Configuration => config as unknown as Configuration;

export const setUpConfig = async (): Promise<void> => {
  const configResponse = await fetch("/app.config.json");
  config = (await configResponse.json()) as Configuration;
};
