import { useEffect, useState } from "react";
import {
  Button,
  Fieldset,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "react95";
import { Link } from "react-router-dom";

import { getApi } from "../helpers/getApi";
import { Account } from "../services/Api";
import { getToken } from "../services/tokenStorage";

export default function AccountPage(): JSX.Element {
  const [me, setMe] = useState<Account | null>(null);

  useEffect(() => {
    void getApi()
      .getMe(getToken())
      .then((meData) => {
        setMe(meData);
      });
  }, []);

  const vocationMap = [
    "None",
    "Sorcerer",
    "Druid",
    "Paladin",
    "Knight",
    "Master Sorcerer",
    "Elder Druid",
    "Royal Paladin",
    "Elite Knight",
  ];

  return (
    <div>
      {me?.id && (
        <div className="accountPageMain">
          Logged in as {me?.id}
          <br />
          <br />
          <Fieldset label="Your characters">
            <Table>
              <TableHead>
                <TableRow head>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Vocation</TableHeadCell>
                  <TableHeadCell>Level</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {me.players.map((player) => (
                  <TableRow key={player.name}>
                    <TableDataCell>{player.name}</TableDataCell>
                    <TableDataCell>{vocationMap[player.vocation]}</TableDataCell>
                    <TableDataCell>{player.level}</TableDataCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fieldset>
          <br />
          <Link to="/create-character">
            <Button size="sm">Create character</Button>
          </Link>
        </div>
      )}
    </div>
  );
}
